import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';


import Error from 'components/Error'
import UltimaPicada from './components/UltimaPicada';
import SelectorEventos from './components/SelectorEventos';

const useStyles = makeStyles(theme => ({
  
}));

const ControlHorario = props => {  
  const classes = useStyles();
  
  const {ultima,eventos,picar,terminar,seleccionar,updating}=props
  
  return (
    <div>
      <Grid container spacing={2} direction='column'>
        <Grid item >
          <UltimaPicada picada={ultima}/>
        </Grid>
        <Grid item>
          <SelectorEventos 
            eventos={eventos}
            picar={picar}
            terminar={terminar}
            seleccionar={seleccionar}
            updating={updating}
          />
        </Grid>
      </Grid>
      {/*<Error entidades={[eventos, nuevaPicada]} />*/}
    </div>
  );
};

export default ControlHorario;
