import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton,Typography } from '@material-ui/core';
import {
  MenuIcon,
  NotificationsIcon,
  ExitIcon,
 } from 'icons';

import Auth from 'features/Auth';
import List from 'features/List';
import config from 'settings/environment';
import {timer} from 'helpers'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',    
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { auth,notificaciones,className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
    
  useEffect(()=>
  {     
    auth.doInit();
    /*
    notificaciones.load()
    const id = timer(0, 5000,() => notificaciones.refresh(false))

    return () => {      
      timer(id)
      notificaciones.unload()
    }*/
  },[]);
  
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={`${config.rutaImagenes}/logos/logo.png`}
            height="18%"
            width="18%"
          />
        </RouterLink>
        <Typography style={{color:'white'}} variant='h3'>
          {config.aplicacion.descripcion}
        </Typography>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <RouterLink             
            to="/notificaciones" 
          >
            <Badge
              badgeContent={notificaciones.list.length}
              color="error"
              //variant="dot"
            >
              <NotificationsIcon style={{color:'White'}}/>
            </Badge>
          </RouterLink>
          <IconButton
            className={classes.signOutButton}
            onClick={()=>auth.doLogout()}
            color="inherit"
          >
            <ExitIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>        
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export const TopbarContainer = List(Auth(Topbar,'auth'),'notificaciones','notificaciones/pending')

export default TopbarContainer;
