import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    Card,
    CardHeader,
    CardContent,    
    Divider,
    Typography,
    IconButton,
    Tabs,
    Tab,    
    Tooltip,
} from '@material-ui/core';

import * as icons from 'icons';

import Eventos from './Eventos';
import TiemposTareas from './TiemposTareas';

const useStyles = makeStyles((theme) => ({
    root: {        
        marginBottom: theme.spacing(1),
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        height: '100%',
        paddingRight: theme.spacing(2),        
        '& > *': {
            marginLeft: theme.spacing(1),
        },
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    }
}));

const Adjust = (props) => {
    const { item, range, close, horario, auth } = props;
    const classes = useStyles();
    
    const [tab, setTab] = useState(0);

    return (
        <Card raised={true} className={classes.root}>
            <CardHeader
                title={<Typography variant='h4'>{item.nombre}</Typography>}
                subheader={<Typography variant='caption'>Id. {item.id}</Typography>}
                action={
                    <div className={classes.root}>
                        <Tabs
                            value={tab}
                            //variant="standar"
                            scrollButtons="auto"
                            onChange={(event, newValue) => setTab(newValue)}
                            indicatorColor="primary"
                            textColor="primary"
                        >   
                            <Tab
                                key='eventos'
                                id={0}
                                label='Eventos'
                                wrapped
                            />
                            <Tab
                                key='tareas'
                                id={1}
                                label='Tareas'
                                wrapped
                            />                            
                        </Tabs>
                    </div>
                }
                avatar={                  
                    close && (
                        <Tooltip title={'Cerrar Control de Presencia'}>
                            <IconButton 
                                size='small'
                                onClick={() => close(null)}>
                                <icons.CloseIcon/>
                            </IconButton>
                        </Tooltip>
                    )
                }
            />
            <Divider />            
            <CardContent>
                <div hidden={tab !== 0}>
                    <Eventos
                        user={item}
                        range={range}
                        horario={horario}
                        auth={auth}
                    />
                </div>
                <div hidden={tab !== 1}>
                    <TiemposTareas
                        user={item}
                        range={range}
                    />
                </div>                
            </CardContent>            
        </Card>
    );
};

export default Adjust;
