import React,{useState} from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid,Divider } from '@material-ui/core';

import * as icons from 'icons';
import { getAvatar} from 'helpers';

import List from 'features/List';
import { Button,Combo, Tag, Text } from 'components';

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const ComboEventos = List(Combo, 'options', 'eventospresencia');

const Detalle = (props) => {
    const { auth, values, setValues, save } = props;
    const classes = useStyles();

    const [changes,setChanges]=useState(!values.id ? values : {})
    
    const handleChange = (chngs,event) => {
        if (true) {
            let newValues = values;
            let newChanges = changes;

            chngs.map(change => {
                if (change.value !==null && typeof(change.value)==='object') {                                    
                    if (Array.isArray(change.value.value)){
                        newValues = {
                            ...newValues,
                            [change.field]: change.value.value,
                        };
                        newChanges = {
                            ...newChanges,
                            [change.field]: change.value.value,
                        };
                    }
                    else {                    
                        newValues = {
                            ...newValues,
                            [change.value.nameKey]: change.value.value==='' ? null : change.value.value,
                            [change.value.name]: change.value.selected,
                        };
                        newChanges = {
                            ...newChanges,
                            [change.value.nameKey]: change.value.value==='' ? null : change.value.value,
                            [change.value.name]: change.value.selected,
                        };
                    }
                } else {                
                    newValues = {
                        ...newValues,
                        [change.field]: change.value==='' ? null : change.value,
                    };
                    newChanges = {
                        ...newChanges,
                        [change.field]: change.value==='' ? null : change.value,
                    };
                }
            })
            
            setValues(newValues);            
            setChanges(newChanges);            
        }
    };

    const formOk=() =>{
        let ok = (Object.keys(changes).length !== 0)

        ok=(ok && values.fecha!==null)

        ok=(ok && values.idEvento!==null)

        return ok
    }

    return (
        <form autoComplete="on" noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <div className={classes.wrapper}>
                        <Tag
                            //variant="outlined"
                            color="secondary"
                            hidden={!values.propietario}
                            value={values.propietario && values.propietario.nombre}
                            helperText="Creador del evento"
                            avatar={
                                values.propietario &&
                                getAvatar(
                                    values.idPropietario,
                                    values.propietario.avatar
                                )
                            }
                        />
                        <Button                        
                            toolTipText="Guardar los cambios"
                            disabled={!formOk()}                        
                            onClick={event => save(values.id,changes)}
                            counter={Object.keys(changes).length}
                            //busy={updating}
                            icon={<icons.SaveIcon/>}
                            float={true}
                        />
                    </div>
                </Grid>         
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs='12'>
                    <Divider/>
                </Grid>
                <Grid item xs='12'>
                    
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Tag
                        //variant="outlined"
                        color="primary"                        
                        value={values.idUsuario && values.usuario.nombre}
                        helperText="Usuario que realiza el evento"
                        avatar={
                            values.usuario &&
                            getAvatar(
                                values.idUsuario,
                                values.usuario.avatar
                            )
                        }
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        required
                        name="fecha"
                        label="Fecha"
                        helperText="Fecha del evento"
                        value={moment(values.fecha).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                        type="datetime-local"
                        onChange={handleChange}
                        editing={true}
                    />
                </Grid>
                <Grid item xs>
                    <ComboEventos
                        required
                        api="eventospresencia"
                        name="evento"
                        nameKey="idEvento"
                        label="Evento"
                        Icon={icons.EventTimeIcon}
                        helperText="Seleccione un Evento"
                        value={values.evento}
                        onChange={handleChange}
                        editing={true}
                        propertyValue="id"
                        getOptionLabel={(option) =>
                            option.descripcion
                        }
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
