import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Avatar from './Avatar';
import Detalle from './Detalle';

import Entity from 'features/Entity';
import { Element } from 'components/Crud';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}));

const Perfil = props => {
    const { match, usuario } = props;
    const classes = useStyles();
    const item = {
        key: 'perfil',
        id: match.params.id,
        title: 'Perfil del usuario',
        element: Detalle
    };

    useEffect(() => {
        usuario.load(`usuarios/${match.params.id}`);

        return usuario.unload;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item lg={4} md={6} xl={4} xs={12}>
                    <Avatar {...props} />
                </Grid>
                <Grid item lg={4} md={6} xl={4} xs={12}></Grid>
                <Grid item lg={8} md={6} xl={8} xs={12}>
                    <Element entity={usuario} item={item} />
                </Grid>
            </Grid>
        </div>
    );
};

const PerfilContainer = Entity(Perfil, 'usuario', 'perfil');

export default PerfilContainer;
