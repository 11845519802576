import { connect } from 'react-redux';
import {
    isEntityReady,
    isEntityEditable,
    isEntityUpdating,
    isEntityRefreshing,
    hasEntityErrors,
    getEntity,
    getEntityInitial,
    getEntityApi,
    changesEntity
} from 'settings/redux/reducer';
import {
    entity,
    editEntity,
    refreshEntity,
    updateEntity,
    undoEntity,
    clearEntity,
    saveEntity,
    deleteEntity,
    unloadEntity
} from './actions';

const map2Props = entidad => state => {
    return {
        entity: getEntity(entidad, state),
        initial: getEntityInitial(entidad, state),
        api: getEntityApi(entidad, state),
        empty: Object.keys(getEntity(entidad, state)).length === 0,
        changes: changesEntity(entidad, state),
        hasChanges: Object.keys(changesEntity(entidad, state)).length !== 0,
        isReady: isEntityReady(entidad, state),
        isEditable: isEntityEditable(entidad, state),
        isUpdating: isEntityUpdating(entidad, state),
        isRefreshing: isEntityRefreshing(entidad, state),
        isNew: getEntityApi(entidad, state).toLowerCase().indexOf('/new') !== -1,
        hasErrors: !!hasEntityErrors(entidad, state),
        errors: hasEntityErrors(entidad, state)
    };
};

const map2Dispatch = entidad => dispatch => {
    return {
        load: api => {
            dispatch(entity(entidad, api));
        },
        unload: () => {
            dispatch(unloadEntity(entidad));
        },
        refresh: (api, data, onlyInitial, action) => {
            dispatch(refreshEntity(entidad, api, data, onlyInitial, action));
        },
        edit: data => {
            dispatch(editEntity(entidad, data));
        },
        update: data => {
            dispatch(updateEntity(entidad, data));
        },
        undo: () => {
            dispatch(undoEntity(entidad));
        },
        clear: () => {
            dispatch(clearEntity(entidad));
        },
        save: postActions => {
            dispatch(saveEntity(entidad, postActions));
        },
        delete: () => {
            dispatch(deleteEntity(entidad));
        }
    };
};

const Entity = (Component, property, entity) => {
    return connect(
        map2Props(entity),
        map2Dispatch(entity),
        (stateProps, dispatchProps, ownProps) => {
            const api = {
                [property]: Object.assign({}, stateProps, dispatchProps)
            };

            return Object.assign({}, ownProps, api);
        }
    )(Component);
};

export default Entity