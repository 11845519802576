import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Typography,	
    IconButton,
    Tooltip
} from '@material-ui/core';

import ItemPila from './ItemPila'
import * as icons from 'icons';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',        
        alignItems: 'flex-end',
        justifyContent: 'center',
    },    
    progress: {
        color: theme.palette.warning.main,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        '& > *': {
            margin: theme.spacing(2),
        },
    },
    aceptar: {
        color: theme.palette.white,
        backgroundColor: theme.palette.success.dark,
    },
    cancelar: {
        color: theme.palette.white,
        backgroundColor: theme.palette.error.dark,
    },
    iconoExtendido: {
        marginRight: theme.spacing(1),
    },
}));

const Items = props => {
    const { state, next, list, change, detail } = props;    
	
    return (
		list.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        /*style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}*/
                    >
                        <ItemPila
                            item={item}
                            state={state}
                            next={next}	
                            change={change}	
                            detail={detail}                            		
                        />
                    </div>
                )}
            </Draggable>			
		))        
    );
};

const Pila = (props) => {
    const { id, state, next, list, change, reorder, resumen, detail, ...rest } = props;
    const classes = useStyles();

    const [collapse, setCollapse] = useState(false);    

    return (
        <DragDropContext onDragEnd={reorder}>
            <Card style={{marginBottom: '15px'}} raised={true}>
                <CardHeader
                    title={<Typography style={{color: state.color}} variant='h4'>{state.label}</Typography>}
                    subheader={state.description}
                    avatar={
                        <Tooltip title={collapse ? 'Desplegar Tablero' : 'Contraer Tablero'}>
                            <IconButton 
                                style={{color: state.color}}
                                size='small'                                
                                onClick={() => setCollapse(!collapse)}
                            >
                                <state.icon
                                    fontSize='large'							
                                />						
                            </IconButton>
                        </Tooltip>
                    }
                    action={                        
                        <div className={classes.wrapper}>
                            {resumen && (
                                <Tooltip title={'Estimacion Total'}>
                                    <Typography variant="h6" style={{color: state.color}}>
                                        {resumen()}  <icons.TimerIcon fontSize='small'/>
                                    </Typography>
                                </Tooltip>
                                
                            )}
                            <Tooltip title={'Elementos Totales'}>
                                <Typography variant="h6" style={{color: state.color}}>
                                    {list.length} <icons.TasksIcon fontSize='small'/>
                                </Typography>
                            </Tooltip>
                            
                        </div>                        
                    }
                />
                {!collapse && <Divider />}
                {!collapse && 
                    <CardContent>
                        <Droppable droppableId={id}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    //style={getListStyle(snapshot.isDraggingOver)} 
                                >
                                    <Items                                    
                                        list={list}
                                        state={state}
                                        next={next}
                                        change={change}
                                        detail={detail}
                                    />
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </CardContent>
                }
                <Divider />
                <CardActions></CardActions>
            </Card>
        </DragDropContext>
    );
};

export default Pila;
