import React,{useState} from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import * as icons from 'icons';
import * as hlprs from 'helpers';
import * as enums from 'enums';

import moment from 'moment'

import { Select, DataGrid, Text, Switch } from 'components';

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Estado = (props) => {
    const { values, editing, adding, handleChange,refresh } = props;
    const classes = useStyles();

    const columns=[        
        {
            column: 'estado',        
            title: 'Estado',
            align:'left',
            width: '18%',        
            component:{
                Container: Select,
                props:{
                    name:"estado",                    
                    editing:editing,
                    options:enums.tipoSocio,
                }, 
                propValue:'value',
                eventChange:'onChange'                
            }
        },
        {            
            column: 'motivo',
            title: 'Motivo',
            align:'left',
            width: '50%',            
            component:{
                Container: Text,
                props:{                    
                    name:"motivo",                                                            
                    type:"text",                    
                    editing:editing
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
        {            
            column: 'fechaInicio',
            title: 'Desde',
            align:'left',
            width: '15%',            
            component:{
                Container: Text,
                props:{                    
                    name:"fechaInicio",                                                            
                    type:"date",                    
                    editing:editing
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
        {            
            column: 'fechaFin',
            title: 'Hasta',
            align:'left',
            width: '15%',            
            component:{
                Container: Text,
                props:{                    
                    name:"fechaFin",                                                            
                    type:"date",                    
                    editing:editing
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
        {            
            column: 'activo',
            title: 'Activo',
            align:'center',            
            width: '2%',
            component:{
                Container: Switch,
                props:{
                    name:"activo",                    
                    editing: false,
                    size:"small"                    
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
    ]

    return (        
        <Grid container spacing={1}>                
            <Grid item xs>
                <DataGrid
                    //rowHeight='30px'
                    //headHeight='300px'                    
                    name='contratos'                        
                    list={values.contratos ? values.contratos : []}
                    columns={columns}
                    editing={editing}
                    onChange= {handleChange}                    
                    dfault={() =>{                
                        return {
                            fechaInicio:hlprs.toDay(),            
                            //activo: false
                        }
                    }}
                    maxHeight={456}
                />            
            </Grid>            
        </Grid>
    );
};

export default Estado;










