import config from 'settings/environment';
import jwt from 'jsonwebtoken';
import moment from 'moment';

import {
    AUTH_KEY,
    AUTH_INIT,
    AUTH_SUCCESS,
    AUTH_FAILED,
    AUTH_LOGOUT,
    AUTH_SETLINK,
    AUTH_CLEAR,
} from 'constants.js';

import { getToken, getAuthPermisos, isAuthInit, getAuthUser } from 'settings/redux/reducer';

const loadAuth = () => {
    try {
        const serializedAuth = localStorage.getItem(AUTH_KEY);
        if (serializedAuth === null) {
            return undefined;
        }

        return serializedAuth//JSON.parse(serializedAuth);
    }
    catch (error) {
        return undefined;
    }
};

const saveAuth = (token) => {
    if (!!token) {
        try {
            const serializedAuth = token; //JSON.stringify({token});
            localStorage.setItem(AUTH_KEY, serializedAuth);
        }
        catch (error) {
            console.log('Error saving Auth to local storage', error);
        }
    }
};

export const delAuth = () => {    
    try {
        localStorage.removeItem(AUTH_KEY);
    }
    catch (error) {
        console.log('Error removing Auth from local storage', error);
    }
};

const expiredAuth = (exp) => {
    return exp < moment().unix()
}

const calculaPermisos=(usuario) => {
    let permisos=usuario.permisos    
    const roles=usuario.roles

    // Saco los permisos de los roles
    roles.map (rol => 
        permisos=permisos.concat(rol.permisos)
    )
    
    return permisos    
}

export const init = () => (dispatch, getState) => {
    const emptyToken=getToken(getState())===undefined;
    
    if (emptyToken && !isAuthInit(getState())) {
        // accion para el reducer        
        dispatch({ type: AUTH_INIT });

        // Saco el token del LocalStorage
        const token = loadAuth();

        if (token !== undefined) {
            const decToken = jwt.decode(token);

            if (!expiredAuth(decToken.exp)){
                const permisos=calculaPermisos(decToken.sub);               

                dispatch({ type: AUTH_SUCCESS, payload: token, sub: decToken, permisos: permisos })
            }
            else {
                delAuth();
                dispatch({ type: AUTH_LOGOUT })
            }
        }
        else
            dispatch({ type: AUTH_LOGOUT })
    }

};

export const login = (username, password, pin) => (dispatch, getState) => {
    let credenciales = {}

    // accion para el reducer
    dispatch({ type: AUTH_INIT });

    // Compruebo las credenciales
    if (!username || !password) {
        if (!pin) {
            dispatch({ type: AUTH_FAILED, payload: { code: 422, message: 'Credenciales no validas' } })
            return;
        }

        // Busco un pin
        credenciales = { pin: pin }
    }
    else {
        // Busco un login y un password
        credenciales = { login: username, password: password }
    }

    // accion para el servidor
    fetch(
        `${config.servidor}:${config.puerto}/api/auth/login`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credenciales)
        }
    ).then(
        (response) => {
            return response.json();
        }
    ).then(
        (data) => {
            if (data.code) {
                dispatch({ type: AUTH_FAILED, payload: data })
            }
            else {
                saveAuth(data.token);

                const decToken = jwt.decode(data.token);
                const permisos=calculaPermisos(decToken.sub);
                
                dispatch({ type: AUTH_SUCCESS, payload: data.token, sub: decToken, permisos: permisos })
            }
        },
        (error) => {
            dispatch({ type: AUTH_FAILED, payload: {code: 503, message: 'El servicio no esta disponible' } })
        }
    );
};

export const logout = () => (dispatch, getState) => {    
    delAuth();    
    dispatch({ type: AUTH_LOGOUT});
}; 

export const redirectTo = (link) => (dispatch, getState) => {
    dispatch({ type: AUTH_SETLINK, link: link })
}

export const clearAuth = () => ({ type: AUTH_CLEAR });

export const getAccesos = (state,entidades) => {
    const newEntidades=[]
    
    entidades.map(item => {
        if (getPermiso(state,'Acceso',item.entidad,null))
            newEntidades.push(item)
    })
    
    return newEntidades
}

export const getPermiso = (state,permiso,entidad,instancia) => {
    return ! getAuthPermisos(state).some(item => 
        item.idEntidad===entidad && 
        item.idPermiso===permiso && 
        item.instancia===instancia
    )     
}

export const getEquipos = (state) => {
    const user=getAuthUser(state)
    const list=[]
    
    user.roles.map(
        rol => rol.idEquipo !==null && list.push(rol.idEquipo)
    )
    
    return list         
}




