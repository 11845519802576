import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    socio: 'Francisco Perez',
    tipo:'Socio',
    name: 'Quema de rastrojo inadecuada',
    description:
      'La rambla se encuentra en mal estado, llena de escombros y sin limpiar desde hace años.',
    imageUrl: '/recursos/documents/tarea01.jpg',
    totalDownloads: '10',
    createdAt: '2020-03-05',
    poligono:'180',
    parcela:'141'
  }
];
