import * as hlp from 'helpers'

const getApi = (report,params) => {
    let api=''

    Object.keys(params).forEach((p,index) =>{        
        if (p!=='head') api = api + (api==='' ? '?' :'&') + p+'='+params[p]
    })
    
    return report.source + api
}

const patchReport=(report,patch) =>{    
    // parcheamos Report    
    patch.report && Object.keys(patch.report).forEach(e => report[e]=patch.report[e])    

    // Parcheamos Fields
    patch.fields && patch.fields.forEach(p =>{
        const index=report.fields.findIndex(f => f.key===p.key)

        report.fields[index]={
            ...report.fields[index],
            ...p
        } 
    })

    // Parcheamos Groups
    patch.groups && patch.groups.forEach(g =>{        
        const index=report.groups.findIndex(f => f.key===g.key)
        
        if (index!==-1)
            g.show.forEach(e => {
                const i=report.groups[index].show.findIndex(s => s.key===e.key)
                report.groups[index].show[i]={
                    ...report.groups[index].show[i],
                    ...e
                }
            })

        // Quito show de g puesto que ya la he modificado
        delete g.show
        
        report.groups[index]={
            ...report.groups[index],
            ...g
        } 
    })
}

const reportRows = (source,level,report,rows) =>{		    
    const fields=hlp.clone(report.fields)    
    
	source.forEach((element,index) =>{		
		const group=report.groups ? report.groups[level] : undefined

		if (group){
			if (group.show){			                
                group.show.forEach (e =>{
                    const head=fields.find(f => f.key===e.key)
                    const format=e.format ? e.format : head.format                    
                    const value=hlp.getValueFormatted(format,hlp.getValue(element,e.property))
                    
                    const label= e.label ? e.label : ''
                    head.value=`${label} ${value}` 
                    
                    if (e.cols) {
                        head.cols=e.cols
                        const key=fields.findIndex(f => f.key===e.key)
                        for (var i = 1; i < head.cols; i++) {
                            fields[key+i].field=""                            
                        } 
                    }
                })

                // Calculo los agregados si no he agrupado previamente
                !report.groupSource &&                    
                    group.aggregates && 
                        Object.assign(element,hlp.sumaLista(element[group.details],group.aggregates))

                // Añado los agregados						
                group.aggregates && group.aggregates.forEach((aggregate) =>{
					const head=fields.find(f => f.key===aggregate)
					const value=hlp.getValueFormatted(head.format,element[aggregate])
					head.value=value;					
				})

				// Calculo el identation
				let identation=0

				for (var i = 0; i < report.groups.length; i++) {
					if (level===i) break;
					report.groups[i].show && identation++
				}

				// Escribo el grupo CASO DE ESTUDIO LA NECESIDAD DEL CLONE                
				rows.push({fields:hlp.clone(fields),style:'group',identation:identation,newPage:group.newPage})
			}

			// RECURSIVIDAD: Llamo al siguiente grupo si lo hay
			const newLevel=level+1			
            
			if (element[group.details]) {
                
				reportRows(element[group.details],newLevel,report,rows)
                
                if (!report.groups[newLevel] && report.resume) {                    
                    // Vuelvo del detalle de este grupo imprimo el resumen del grupo si lo hay
                    const fieldResumen=hlp.clone(report.fields)
                    fieldResumen[0].value=report.resume[0].title
                    fieldResumen[0].cols=8
                    fieldResumen[1].field=''
                    fieldResumen[2].field=''
                    fieldResumen[3].field=''
                    fieldResumen[4].field=''
                    fieldResumen[5].field=''
                    fieldResumen[6].field=''
                    fieldResumen[7].field=''
                    rows.push({fields:fieldResumen,style:'group',identation:0,newPage:report.resume[0].newPage})

                    // Recorro el resumen
                    element.empleado.vacaciones.forEach(e =>{
                        const fld=hlp.clone(report.fields)
                        fld[0].value=hlp.getValueFormatted("date",e.fecha)
                        fld[0].format="date"
                        fld[0].cols=8
                        fld[2].field=''
                        fld[1].field=''
                        fld[3].field=''
                        fld[4].field=''
                        fld[5].field=''
                        fld[6].field=''
                        fld[7].field=''
                        rows.push({fields:fld,style:'group',identation:0,newPage:false})
                    })
                }

            }
            
            
		}
		else{            
			// Linea de detalle
			if (report.showDetails){				
				Object.keys(element).forEach(p =>{
					const head=fields.find(f => {
                        if (Array.isArray(f.field))
                            return f.field[0]===p
                        else
                            return f.field===p
                    })
                    if (head){
                        const value=hlp.getValueFormatted(head.format,hlp.getValue(element,head.field))
                        head.value=value;                        
                    }
				})

				// Escribo el detalle                
				rows.push({fields:hlp.clone(fields),style:'detail',identation:0,newPage:report.newPage})
			}
		}
	})    
    
	return rows
}

export const print =(report,optReport) =>{        
    sessionStorage.setItem(report, JSON.stringify(optReport));
    window.open(`report/${report}`);
}

export const listReports = (group,print) => {
    const reports=require('reports/indexReports.json').filter(r => r.group===group)    
    
    if (reports.length===0) return
    
    return {
        title: 'Informes disponibles',
        list: reports,
        action: print,
        columns:[
            {
                column: 'title',
                title: 'Titulo',
                align:'left',
                type: 'text'
            },
            {
                column: 'description',
                title: 'Resumen',
                align:'left',
                type: 'text'
            }
        ]
    }    
}

export const initReport = (report,data) =>{
    const optReport = JSON.parse(sessionStorage.getItem(report.key))

    // Ajusto el report con las opciones
    optReport.patch && patchReport(report,optReport.patch)

    // Añado la cabecera dinamica
    optReport.params.head && 
        (report.head=report.head.concat(optReport.params.head))

    if (report.source){        
        // Hay que cagar los datos desde el servidor
        data.load(getApi(report,optReport.params))
        return 
    }
    
    // Los datos estan diposnibles en el sessionStorage    
    return optReport.payload
}

export const getRows = (report,rws) =>{        
    const source=report.groupSource ? hlp.groupBy(rws,report.groups) : rws    
    return reportRows(source,0,report,[])
}

