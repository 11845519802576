import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    socio: 'Francisco Perez',
    tipo:'Socio',
    title: 'Rambla sucia y con escombros',
    description:
      'La rambla se encuentra en mal estado, llena de escombros y sin limpiar desde hace años.',
    imageUrl: '/images/products/p1.jpg',
    totalDownloads: '10',
    createdAt: '27/03/2019',
    poligono:'180',
    parcela:'141'
  },
  {
    id: uuid(),
    socio: 'Luis Gomez',
    tipo:'Socio',
    title: 'Riesgos de las malas labores culturales',    
    description:
      'El abuso del cultivo innecesario provoca la perdida de suelo aumentando la erosion y desertificacion.',
    imageUrl: '/images/products/p2.jpeg',
    totalDownloads: '5',
    createdAt: '31/03/2019',
    poligono:'101',
    parcela:'125'
  },
  {
    id: uuid(),
    socio: 'Antonio Ureña',
    tipo:'Socio',
    title: 'Estragos de la sequía',
    description:
      'La sequía prolongada en la zona ha provocado que los viñedos no muevan correctamente para las fechas en las que nos encontramos.',
    imageUrl: '/images/products/p3.jpeg',
    totalDownloads: '7',
    createdAt: '03/04/2019',
    poligono:'210',
    parcela:'222'
  },
  {
    id: uuid(),
    socio: 'Pedro Orenes',
    tipo:'Técnico',
    title: 'Ayudar a las abejas',
    description:
      'La disminución y correcto uso de fitosanitarios da sus frutos en la conservación de las abejas.',
    imageUrl: '/images/products/p4.jpeg',
    totalDownloads: '16',
    createdAt: '04/04/2019',
    poligono:'15',
    parcela:'42'
  },
  {
    id: uuid(),
    socio: 'Maria Fernandez',
    tipo:'Socio',
    title: 'Resultados de cultivo ecologico',
    description:
      'Como se puede apreciar mis almendros en ecologico ademas de cuidar el medio natural se autoprotegen de las plagas gracias a la fauna auxiliar.',
    imageUrl: '/images/products/p5.jpeg',
    totalDownloads: '35',
    createdAt: '04/04/2019',
    poligono:'85',
    parcela:'192'
  },
  {
    id: uuid(),
    socio: 'Paz Gutierrez',
    tipo:'Naturalista',
    title: 'Avistamiento especie invasora',
    description:
      'Ha sido avistado una especie de roedor invasora, que se come los cultivos y provoca daños irreversibles',
    imageUrl: '/images/products/p6.jpeg',
    totalDownloads: '8',
    createdAt: '04/04/2019',
    poligono:'110',
    parcela:'41'
  }
];
