import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button} from '@material-ui/core';

import { AccountProfile, AccountDetails, Password } from './components';
import Entity from 'features/Entity';
import { isLoading } from '../../features/List/reducer';
import { hasErrors } from '../../features/Entity/reducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = (props) => { 
  const { user,...rest } = props;
  const userId = rest.match.params.id;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AccountProfile />
          
        </Grid>

        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >          
          <Password/>
        </Grid>
        
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <Entity entidad={'usuario/new'}>
          {
            (props) =>
            {
              if (props.hasErrors) return(
                <div>
                  {userId}
                  Error en la entidad .............
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => 
                      {
                        props.clear();
                      }
                    }
                  >
                  Aceptar
                  </Button>
                </div>
              );
              
              if (!props.isReady) return(<div>Cargando entidad .............</div>)               
              return(<AccountDetails user={props}/>)
            }
          }
        </Entity>  
          
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
