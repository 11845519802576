import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import moment from 'moment';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Typography,
    IconButton,
    Tooltip,
    Grid
} from '@material-ui/core';

import {Alert, Tag, Documents, Modal} from 'components'

import * as icons from 'icons';
import * as enums from 'enums';
import { getAvatar,contadorTemporal } from 'helpers';

import DetailExpediente from 'views/Expedientes/Detalle'
import DetailUsuario from 'views/Usuarios/Detalle'
import DetailSocio from 'views/Socios/Detalle'
import DetailParcela from 'views/Parcelas/Detalle'
import DetailTarea from 'views/Tareas/Detalle'
import Comentarios from 'views/Comentarios'

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100%',
        marginBottom: theme.spacing(1),
    },
    verticalWrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',        
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
    },
    usuarios: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
        marginBottom: theme.spacing(1),
    },

}));

const Prioridad = (props) =>{
    const {value , urgencia} = props;

    const prioridad=enums.prioridades.find(element => element.value===value)
    
    if (prioridad===undefined) 
        return(null)

    return(
        <div>
            {urgencia && (
                <Tooltip title={'Urgente'}>
                    <icons.UrgentIcon fontSize='small' style={{ color: 'DarkRed'}}/>
                </Tooltip>
            )}
            <Tooltip title={'Prioridad ' + prioridad.label}>
                <prioridad.icon fontSize='small' style={{ color: prioridad.color, marginRight:'10px' }}/>
            </Tooltip>            
        </div>
    )
} 

const ItemPila = (props) => {
    const { item, state, next, change, detail, ...rest } = props;
    const classes = useStyles();

    const [collapse, setCollapse] = useState(true);
    const [openDialog, setOpenDialog] = useState([false,false]);
    const [openComments, setOpenComments] = useState(false);


    return ([
        <Card raised={true} className={classes.root}>
            <CardHeader
                title={<Typography variant='h5'>{item.resumen}</Typography>}
                subheader={<Typography variant='body2'>{item.flujo && item.flujo.resumen}</Typography>}
                action={
                    <div className={classes.verticalWrapper}>                    
                        <div className={classes.wrapper}>
                            <Prioridad 
                                value={item.prioridad} 
                                urgencia={item.urgente}
                            />
                            {next && next.map((i,index) => (
                                <div>
                                    <Tooltip title={'Cambiar a ' + i.label}>
                                        <IconButton
                                            style={{ color: i.color }}
                                            size='small'
                                            onClick={
                                                change &&
                                                (() => setOpenDialog([index===0,index===1]))
                                            }>
                                            <i.icon />
                                        </IconButton>                                    
                                    </Tooltip>
                                    <Alert
                                        open={openDialog[index]}
                                        title={`¿Está seguro de pasar la Tarea ${item.codigo} de ${state.label} a ${i.label}?`}
                                        text= {`Si "Acepta" no podra deshacer esta acción.`}
                                        cancel={() => setOpenDialog([false,false])}
                                        accept={() => {
                                            setOpenDialog([false,false])
                                            change(item.id, i.value)
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div>
                            <Tooltip title={'Comentarios recibidos'}>
                                <div className={classes.wrapper}>
                                    <Typography variant="h6">
                                        {Array.isArray(item.comentarios) && item.comentarios.length}                                 
                                    </Typography>
                            
                                    <IconButton 
                                        size='small'
                                        onClick={() => setOpenComments(true)}
                                    >                        
                                        <icons.CommentIcon/>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                    
                                                 
                    </div>                    
                }
                avatar={                  
                    <Tag
                        size="small"
                        color="secondary"                    
                        hidden={false}                        
                        value={item.codigo}
                        id={item.id}                                                        
                        icon={<icons.TaskIcon/>}
                        helperText={'[' + moment(item.fecha).format('DD/MM/YYYY')+']'}
                        relacionado={{
                            Detail: DetailTarea,                            
                            resume:['codigo'],
                            api: 'tareas',
                            readOnly:true
                        }}
                    />
                }
            />
            {!collapse && <Divider />}
            {!collapse && (
                <CardContent>                    
                    <Grid container spacing={1} xs>
                        <Grid item xs>
                            <Tag
                                size="small"
                                color="secondary"                    
                                hidden={!item.socio}                        
                                value={item.socio && item.socio.nombre}
                                id={item.socio && item.socio.id}                                                        
                                icon={<icons.PartnerIcon/>}
                                helperText={'Socio'}
                                relacionado={{
                                    Detail: DetailSocio,                            
                                    resume:['nombre'],
                                    api: 'socios',
                                    readOnly:true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Tag
                                size="small"
                                color="secondary"                    
                                hidden={!item.expediente}                        
                                value={item.expediente && item.expediente.codigo}
                                id={item.expediente && item.expediente.id}                                                        
                                icon={<icons.ProceedingsIcon/>}
                                helperText={'Expediente'}
                                relacionado={{
                                    Detail: DetailExpediente,                            
                                    resume:['codigo'],
                                    api: 'expedientes',
                                    readOnly:true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} xs>
                        <Grid item xs>
                            <Divider />
                            <Typography variant='caption'>
                                Detalle
                            </Typography>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} xs>
                        <Grid item>                            
                            <Typography variant='body1'>{item.detalles}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} xs>
                        <Grid item xs>
                            <Grid container direction='column' spacing={1}>
                                <Grid item>
                                    <Divider />
                                    <Typography variant='caption'>
                                        Tecnicos
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs>
                                    <div className={classes.usuarios}>
                                        {item.usuarios && item.usuarios.map((u) => (
                                            <Tag                                                
                                                color="secondary"                            
                                                value={u.usuario.nombre}
                                                id={u.usuario.id}                            
                                                size="small"
                                                avatar={                                
                                                    getAvatar(
                                                        u.idUsuario,
                                                        u.usuario.avatar
                                                    )                                
                                                }
                                                relacionado={{
                                                    Detail: DetailUsuario,                            
                                                    resume:['nombre'],
                                                    api: 'usuarios',
                                                    readOnly:true
                                                }}                                                
                                            />                                            
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction='column' spacing={1}>
                                <Grid item>
                                    <Divider />
                                    <Typography variant='caption'>
                                        Parcelas
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs>
                                    <div className={classes.usuarios}>
                                        {item.parcelas && item.parcelas.map((p) => (
                                            <Tag                                                
                                                color="secondary"                            
                                                value={`${p.poligono}-${p.parcela}-${p.recinto}`}
                                                id={p.id}                            
                                                size="small" 
                                                icon={<icons.ParcelIcon/>}                                               
                                                relacionado={{
                                                    Detail: DetailParcela,                            
                                                    resume:['poligono','parcela','recinto'],
                                                    api: 'parcelas',
                                                    readOnly:true
                                                }}                                                
                                            />                                            
                                        ))}
                                    </div>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>

                    {item.documentacion &&
                        [
                            <Grid container spacing={1} xs>
                                <Grid item xs>
                                    <Divider />
                                    <Typography variant='caption'>
                                        Documentos
                                    </Typography>
                                    <Divider />
                                </Grid>
                            </Grid>,
                            <Grid container spacing={1} xs>
                                <Grid item xs>
                                    <Documents
                                        value={item.documentacion}
                                        editing={false}
                                    />
                                </Grid>
                            </Grid>
                        ]
                    }
                </CardContent>
            )}
            <Divider />
            <CardActions>
                <div className={classes.actions}>                    
                    <div>
                        <IconButton 
                            size='small'
                            onClick={() => setCollapse(!collapse)}
                        >                        
                            {collapse ? <icons.ExpandMoreIcon/> : <icons.ExpandLessIcon/>}
                        </IconButton> 
                        <Typography variant='caption'>Id. [{item.id}]</Typography>                                  
                    </div>
                    
                    <Tooltip title={'Estimacion'}>
                        <Typography variant='h6' >
                                {contadorTemporal(item.estimacion)} <icons.TimerIcon fontSize='small'/>
                        </Typography>
                    </Tooltip>
                </div>
            </CardActions>            
        </Card>,
        <Modal
            maxWidth={'lg'}
            fullWidth={true}
            title={`Comentarios para la tarea "${item.resumen}""`}
            open={openComments}
            close={() => setOpenComments(false)}
        >   
            <Comentarios                
                api={`tareas/${item.id}`}
            />            
        </Modal>
    ]);
};



export default ItemPila;
