import React from 'react';
import moment from 'moment'

import { Grid } from '@material-ui/core';

import * as icons from 'icons';

import { Calendar,ListBox} from 'components';

const getSelectedDays = (dias) =>{
    const newDias=[];    

    dias.forEach(dia => {
       newDias.push(new Date(dia.fecha)) 
    });

    return newDias;
}

const Vacaciones = (props) => {
    const { values, editing, adding, handleChange} = props;    
    
    return (        
        <Grid container spacing={1}> 
            <Grid item xs={2}>
                <ListBox
                    label={`${values.vacaciones ? values.vacaciones.length : 0} Dias seleccionados`}
                    options={values.vacaciones ? values.vacaciones : []}
                    primary='fecha'
                    Icon= {icons.DateIcon}                    
                    format = {(value) => moment(value).format('DD/MM/yyyy') }
                />
            </Grid>    
            <Grid item xs>
                <Calendar
                    disabled={!editing && !adding}
                    selectedDays={getSelectedDays(values.vacaciones ? values.vacaciones : [])}
                    onSelectDay = {
                        (days) =>{
                            const chng=[{
                                field:'vacaciones',
                                value:{
                                    name:'vacaciones',
                                    value: days.map(d => {
                                        return {fecha: moment(d).format('yyyy-MM-DD')}
                                    })
                                }
                            }]
                            handleChange(chng)                            
                        }
                    }
                />
            </Grid>                      
                                
        </Grid>
    );
};

export default Vacaciones;
