import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Divider, Grid, Typography } from '@material-ui/core';

import * as icons from 'icons';
import * as enums from 'enums';
import * as hlprs from 'helpers';

import List from 'features/List';

import { Avatar, Combo, Select, Text, Switch } from 'components';

import DetailEmpleado from 'views/Empleados/Detalle'
import DetailSocio from 'views/Socios/Detalle'

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ComboSocios = List(Combo, 'options', 'lstSocios');
const ComboEmpleados = List(Combo, 'options', 'lstEmpleados');
const ComboRoles = List(Combo, 'options', 'lstRoles');

const Detalle = (props) => {
    const { auth, values, editing, adding, handleChange,refresh } = props;
    const classes = useStyles();

    return (
        <form autoComplete="on" noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Avatar
                        size="large"
                        //variant="square"
                        //color="secondary"
                        //hidden={!values.propietario}
                        value={values.nombre}                        
                        image={hlprs.getAvatar(values.id, values.avatar)}
                    />
                </Grid>                
                <Grid item xs>
                    <Switch
                        value={values.activo}
                        onChange={handleChange}
                        name="activo"
                        label="Activo"
                        editing={editing}
                        color="DarkRed"
                    />
                </Grid>
            </Grid>            
            <Grid container spacing={1}>                
                <Grid item xs>
                    <Text
                        name="nombre"
                        label="Nombre"
                        helperText="Nombre del usuario"
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        name="email"
                        label="eMail"
                        helperText="Correo electronico"
                        value={values.email}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>                
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboEmpleados
                        api="empleados"
                        name="empleado"
                        nameKey="idEmpleado"
                        label="Empleado"
                        Icon={icons.EmployeeIcon}
                        helperText="Seleccione un Empleado"
                        value={values.empleado}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) => option.nombre}                        
                        relacionado={{
                            Detail: DetailEmpleado,
                            resume:['nombre'],                            
                            dfault:{
                                nombre:values.nombre
                            }                       
                        }}
                        refresh={refresh}                        
                    />
                </Grid>
                <Grid item xs>
                    <ComboSocios
                        api="socios"
                        name="socios"
                        nameKey="idSocio"
                        label="Socio"
                        Icon={icons.PartnerIcon}
                        helperText="Seleccione un Socio"
                        value={values.socio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) => option.nombre}                        
                        relacionado={{
                            Detail: DetailSocio,
                            resume:['nombre'],                            
                            dfault:{
                                nombre:values.nombre
                            }                       
                        }}
                        refresh={refresh}                        
                    />
                </Grid>                                
            </Grid>            
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboRoles
                        api="roles"
                        multiple
                        name="roles"                                
                        label="Roles"
                        Icon={icons.RolIcon}
                        helperText="Roles a los que pertenece el usuario"
                        value={values.roles}
                        onChange={handleChange}
                        editing={editing}                        
                        propertyValue="id"
                        getOptionLabel={(option) => option.idEquipo===null ? `* ${option.id}` :`${option.idEquipo} - ${option.id}`}                        
                        refresh={refresh}
                    />
                </Grid>
            </Grid>            
            <Grid container spacing={1}>                                                
                <Grid item xs>
                    <Typography variant='caption'>
                        Datos de inicio de sesión
                    </Typography>
                    <Divider />
                </Grid> 
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        name="login"
                        label="Login"
                        helperText="Login de usuario"
                        value={values.login}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        type="password"
                        name="password"
                        label="Password"
                        helperText="Password del usuario"
                        value={values.password}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        name="identificador"                        
                        label="Identificador"
                        helperText="Pin del identificador unico de usuario"
                        value={values.identificador}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>            
        </form>
    );
};

export default Detalle;
