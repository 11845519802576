import React,{useEffect,useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';

import  config from 'settings/environment';

import { Profile, SidebarNav } from './components';
import Auth from 'features/Auth';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { auth, open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  
  const [pages, setPages] = useState([])
  const [pagesSystem, setPagesSystem] = useState([])  

  useEffect(()=>
  {     
    auth.doInit();
    
    setPages(auth.accesos(config.entidades));

    setPagesSystem(auth.accesos(config.entSistema));

  },[]);  

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile auth = {auth}/>
        <Divider className={classes.divider} />
        <SidebarNav
          auth={auth}
          className={classes.nav}
          pages={pages}
        />
        <Divider className={classes.divider} />
        <SidebarNav
          auth={auth}
          className={classes.nav}
          pages={pagesSystem}
        />
      </div>          
    </Drawer >
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export const SidebarContainer = Auth(Sidebar,'auth')

export default SidebarContainer;
