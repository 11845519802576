// Auth
export const AUTH_KEY = 'AUTH_KEY';
export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SETLINK = 'AUTH_SETLINK';
export const AUTH_CLEAR = 'AUTH_CLEAR';

// List
export const LIST_INIT = 'LIST_INIT';
export const LIST_SUCCESS = 'LIST_SUCCESS';
export const LIST_FAILED = 'LIST_FAILED';
export const LIST_REFRESH = 'LIST_REFRESH';
export const LIST_REFRESHING = 'LIST_REFRESHING';
export const LIST_UNLOAD = 'LIST_UNLOAD';
export const LIST_UPDATING = 'LIST_UPDATING';
export const LIST_SAVED = 'LIST_SAVED';
export const LIST_DELETED = 'LIST_DELETED';
export const LIST_CLEAR = 'LIST_CLEAR';
export const LIST_CLEAR_FILTER = 'LIST_CLEAR_FILTER';
export const LIST_FILTERED = 'LIST_FILTERED';
export const LIST_REORDER = 'LIST_REORDER';
export const LIST_SELECT_ONE='LIST_SELECT_ONE';
export const LIST_SELECT_SOME='LIST_SELECT_SOME';
export const LIST_SELECT_ALL='LIST_SELECT_ALL';
export const LIST_SETVISIBLES='LIST_SETVISIBLES';
export const LIST_SETPAGE='LIST_SETPAGE';
export const LIST_FIRST_PAGE='LIST_FIRST_PAGE';
export const LIST_PREVIOUS_PAGE='LIST_PREVIOUS_PAGE';
export const LIST_NEXT_PAGE='LIST_NEXT_PAGE';
export const LIST_LAST_PAGE='LIST_LAST_PAGE';

// Entity
export const ENTITY_INIT = 'ENTITY_INIT';
export const ENTITY_SUCCESS = 'ENTITY_SUCCESS';
export const ENTITY_FAILED = 'ENTITY_FAILED';
export const ENTITY_EDIT = 'ENTITY_EDIT';
export const ENTITY_REFRESH = 'ENTITY_REFRESH';
export const ENTITY_UPDATE = 'ENTITY_UPDATE';
export const ENTITY_UNDO = 'ENTITY_UNDO';
export const ENTITY_CLEAR = 'ENTITY_CLEAR';
export const ENTITY_UNLOAD = 'ENTITY_UNLOAD';
export const ENTITY_UPDATING = 'ENTITY_UPDATING';
export const ENTITY_REFRESHING = 'ENTITY_REFRESHING';
export const ENTITY_SAVED = 'ENTITY_SAVED';
export const ENTITY_ADDED = 'ENTITY_ADDED';
export const ENTITY_DELETED = 'ENTITY_DELETED';


