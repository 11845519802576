import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment'

import * as hlprs from 'helpers';

import ControlHorario from './components/ControlHorario';
import RegistroDiario from './components/RegistroDiario';
import SelectorTareas from './components/SelectorTareas';

import { Error } from 'components';

import Auth from 'features/Auth'
import List from 'features/List'
import Entity from 'features/Entity'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const RegistroHorario = props => {  
  const classes = useStyles();
  const { auth, eventos, diario, nueva, ultima, tareas } = props;
  
  const ultimaJson=JSON.stringify(ultima);
  const diarioJson=JSON.stringify(diario);

  useEffect(() => {
    eventos.load('eventospresencia')
    diario.load('trabajoregistrado/usuario/diary')
    nueva.load('controlpresencia/new') 
    ultima.load('controlpresencia/usuario/last')   
    auth.doInit()
    tareas.load('tareas/usuario','activa')

    return () =>{
      eventos.unload()
      diario.unload()      
      nueva.unload()
      ultima.unload()
      tareas.unload()
    }
  }, []);

  useEffect(() =>{             
    // Aplico el filtro
    (ultima.isReady && !ultima.isRefreshing) && 
      diario.isReady && 
        eventos.isReady &&
          filtraEventos(ultima,eventos,diario)
    
  },[ultimaJson,diarioJson])
  
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs>
          <ControlHorario
            ultima={ultima}
            eventos={eventos}
            picar={tareas.itemsSelected !== 0 && (() => picar(auth,nueva,ultima, diario))}
            terminar={auth.inicio==='/registrohorario' && (() => terminar(auth))} 
            seleccionar={(id,location,fecha) => seleccionarEventos(eventos,nueva,auth,id,location,fecha,ultima.entity)}
            updating={nueva.isUpdating}
          />
        </Grid>
        <Grid item xs>
          <SelectorTareas 
            auth={auth}
            tareas={tareas}
          />
        </Grid>                                                                              
        <Grid item xs>                                        
          <RegistroDiario 
            diario={diario}
          /> 
        </Grid>                                      
      </Grid>
      <Error
          entities={[eventos,diario,nueva,tareas]}                
      />
    </div>    
  );
};

const filtraEventos = (ultima, eventos, diario) => {  
  if (ultima.hasErrors || cambiaJornada(ultima.entity.fecha)){    
    // Filtro por el primer evento porque no he picado antes o ha cmabiado la jornada
    eventos.filter(['orden'], 1, '=', 'number',true);    
  }
  else {
    if (ultima.entity.evento.siguiente === '-' || ultima.entity.evento.siguiente === '*'){
      // Filtro por todos los demas
      eventos.filter(['siguiente'], '-', '<>', 'text',true);
  
      const picados=diario.list.filter(
          (evento) => evento.eventoInicial.unoxdia).map(
          (evento) => evento.idEventoInicial)
  
      // Filtro por los que ya he picado y es uno al dia
      eventos.filter(['id'],picados.length===0 ? []: picados,'!in','text');
    }
    else{
      // Filtro por elemento siguiente al que tengo      
      eventos.filter(['id'], ultima.entity.evento.siguiente, '=', 'text',true);  
    }
  }
}

const seleccionarEventos = (eventos,nueva,auth,id,location,fecha,ultima) =>{
  eventos.select(id, false,
    () => nueva.update({
      'fecha': fecha,
      'idUsuario': auth.user.id,
      'idPropietario': auth.user.id,
      'login': auth.user.login,
      'idEvento': id,
      'longitud':location.coords ? location.coords.longitude : 0,
      'latitud':location.coords ? location.coords.latitude : 0,
    })
  )
}

const picar = (auth, nueva, ultima, diario) => {  
  const postActions=(auth.inicio==='/registrohorario') 
    ? [auth.doLogout] 
    : [
        () => nueva.unload(),
        () => nueva.load('controlpresencia/new'),
        () => ultima.refresh(),
        () => diario.refresh(),
      ]   
  nueva.save(postActions);  
}

const terminar = (auth) => {
  auth.doLogout('/');
}

const cambiaJornada= (fechaAnterior) =>{
  const anterior=moment(fechaAnterior).format('YYYY-MM-DD')
  const hoy=hlprs.toDay()
  
  return (moment(hoy).diff(moment(anterior),'days'))>=1  
}

export const RegistroHorarioContainer = 
  Entity(Entity(List(List(List(
    Auth(RegistroHorario,'auth')
  ,'tareas','tareas/usuario')
  ,'eventos','eventospresencia')
  ,'diario','trabajoregistrado/usuario/diary')
  ,'nueva','nuevaPicada')
  ,'ultima','ultimaPicada')

export default RegistroHorarioContainer;
