import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Grid } from '@material-ui/core';

import { Actions, Index, Toolbar, Element, Elements } from 'components/Crud';
import { Error } from 'components';

import Entity from 'features/Entity';
import Auth from 'features/Auth';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}));

const Crud = props => {
    const {
        auth,
        entities,
        title,
        columns,
        resume,
        id,
        api,
        Detalle,
        ...rest
    } = props;

    const classes = useStyles();

    const [editing, setEditing] = useState([]);
    const [elements, setElements] = useState([]);
    const [selectedElement, selectElement] = useState(0);

    const addElement = element => {
        const index = elements.findIndex(item => item.id === element.id);

        if (index === -1) {
            const key = Date.now();

            setElements(
                elements.concat({
                    key: key,
                    id: element[id],
                    title: title,
                    resume: resume,
                    api: api + element[id],
                    container: Entity(Auth(Element,'auth'), 'entity', key),
                    element: Detalle
                })
            );

            selectElement(elements.length);
        } else {
            selectElement(index);
        }
    };

    const closeElement = key => {
        if (key === undefined) {
            setElements([]);
            setEditing([]);
        } else {
            setElements(elements.filter(item => item.key !== key));
            editing.includes(key) &&
                setEditing(editing.filter(item => item !== key));
        }
    };

    const newElement = (toCopy) => {                
        const api=(toCopy==='' ? 'new' : 'new/' + toCopy)

        addElement({
            id: api
        });
    };

    const editElement = (key, cancel, id, isEditable) => {
        if (!isEditable) {
            setEditing(editing.filter(item => item !== key));

            if (!cancel) {
                entities.refresh();
                checkElement(key, id);
            }
        } else {
            setEditing(editing.concat(key));
        }
    };

    const checkElement = (key, id) => {
        const index = elements.findIndex(item => item.key === key);

        if (index !== -1) {
            if (elements[index].id !== id) {
                const newElements = elements.map(item =>
                    item.key === key
                        ? { ...item, ...{ id: id, api: api + id } }
                        : item
                );

                setElements(newElements);
            }
        }
    };

    useEffect(() => {
        entities.load(api);

        return entities.unload;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Toolbar
                                {...rest}
                                title={title}
                                disabled={editing.length !== 0}
                                filters={columns(entities)}
                                menu={
                                    <Actions
                                        add={newElement}
                                        entities={entities}
                                        float={true}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Index
                                view={addElement}
                                disabled={editing.length !== 0}
                                entities={entities}
                                columns={columns(entities)}
                                {...rest}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Elements
                        title={'Detalles ' + title}
                        items={elements}
                        selectedItem={selectedElement}
                        selectItem={selectElement}
                        close={closeElement}
                        edit={editElement}
                        editing={editing.length !== 0}
                        refresh={entities.refresh}
                    />
                </Grid>
            </Grid>
            <Error
                entities={[entities]}                
            />
        </div>
    );
};

export default Crud;
