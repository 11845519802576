import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { UsersToolbar, UsersTable } from './components';
import List from 'features/List';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = (props) => {
  const { usuarios , ...rest } = props;
  const classes = useStyles();   

  useEffect(()=>
  { 
    usuarios.load();
            
    return usuarios.unload;
  },[]);  

  return (        
    <div className={classes.root}>      
      <div className={classes.content}>       
        
          <UsersToolbar users={usuarios}/>
          <UsersTable users={usuarios}/>
        
                
      </div>
    </div>
  );
};

export const UserListContainer = 
  List(UserList,'usuarios','usuarios')

  export default UserListContainer;
