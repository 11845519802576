import React,{useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    Grid,
    IconButton,
    Tooltip,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';

import { CloseIcon } from 'icons';

import { Alert } from 'components';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 550,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 250
        }
    }
}));

const TabPanel = props => {
    const { value, index, item, close, edit, refresh } = props;
    const Container = item.container;

    return (
        <Grid item hidden={value !== index}>
            <Container item={item} close={close} edit={edit} refreshIndex={refresh}/>
        </Grid>
    );
};

const Elements = props => {
    const { title, items, selectedItem, selectItem, close, edit, editing, refresh } = props;
    const classes = useStyles();

    const[openDialog,setOpenDialog]=useState(false);

    const getPanels = items => {
        selectedItem === items.length &&
            selectedItem !== 0 &&
            selectItem(selectedItem - 1);

        return (
            <div className={classes.root}>
                <Tabs
                    value={selectedItem}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(event, newValue) => selectItem(newValue)}
                    indicatorColor="primary"
                    textColor="primary">
                    {items.map((item, index) => (
                        <Tab
                            key={item.key}
                            id={index}
                            label={`${item.title} ${index + 1}`}
                            wrapped
                        />
                    ))}
                </Tabs>
            </div>
        );
    };

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <Card raised={true}>
                    <CardHeader
                        title={<Typography variant="h4">{title}</Typography>}
                        subheader="Elementos abiertos"
                        action={getPanels(items)}
                        avatar={
                            <Tooltip title="Cerrar todos">
                                <IconButton onClick={
                                    () => editing ? setOpenDialog(true) : close()
                                }>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                </Card>
                <Alert
                    open={openDialog}
                    title='¿Hay cambios pendientes de aceptar. Esta seguro de cerrar?'
                    text= {`Si "Acepta" perdera todos los cambios realizados desde la ultima vez que los guardo. Tenga en cuenta que esta acción es irreversible.`}
                    cancel={() => setOpenDialog(false)}
                    accept={() => {
                        setOpenDialog(false)
                        close()
                    }}
                />
            </Grid>
            {items.map((item, index) => (
                <TabPanel
                    key={item.key}
                    value={selectedItem}
                    index={index}
                    item={item}
                    close={close}
                    edit={edit}
                    refresh={refresh}
                />
            ))}
        </Grid>
    );
};

export default Elements;
