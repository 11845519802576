import React,{useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import { TextField,Tooltip,IconButton } from '@material-ui/core';

import { Detail, Element } from 'components/Crud';

import Auth from 'features/Auth'
import Entity from 'features/Entity'

import * as hlprs from 'helpers'


const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

const customTypes ={
    contadortemporal:{
        base:'Text',
        pattern: "^[0-9]{3}[:.]{1}[0-5]{1}[0-9]{1}[:.]{1}[0-5]{1}[0-9]{1}$",
        chars: "^[.:0-9]$",
        messageError: "Valor incorrecto",        
        apply: (value) => Number.isInteger(value) ? hlprs.contadorTemporal(value) : value,
        getValue: function (value) { return value.match(this.pattern) ? hlprs.segundosContador(value.replace(/[.]/g,':')) : value },
        isCharValid: function (char) { return char.match(this.chars) },
        isValueOk: function (value) { return !Number.isInteger(value) ? value.match(this.pattern) : true }
    }
}

const Text = props => {
    const classes = useStyles();

    const {
        helperText,
        error,        
        type,        
        editing,
        onChange,
        value,
        Icon,
        relacionado,        
        refresh,
        ...rest
    } = props;

    const [detail, setDetail] = useState(null);
    const [format] = useState(customTypes[type])    
    

    const handleChange = (name,value) => {        
        if (!onChange) return
        
        onChange([{
            field: name,
            value: value
        }]) 
    };

    const lockRelacionado=() =>{
        const alta= (value === null || value === undefined ? true : false)         
        
        if (alta)
            return true        
        
        return false
    }

    const openDetail=(id) =>{                
        const key = Date.now()
        const newId = (value === null || value === undefined ? 'new' : value[relacionado.propertyId])

        setDetail({
            key: key,
            id: newId,
            title: relacionado.title ? relacionado.title : rest.label,
            resume: relacionado.resume,
            api: relacionado.api +'/' + newId,
            container: Entity(Auth(Element,'auth'), 'entity', key),
            element: relacionado.Detail,
            dfault: (newId==='new' ? relacionado.dfault : undefined),                        
        })        
    }

    const editDetail = (key, cancel, id, isEditable, entity) => {        
        if (!isEditable) {
            if (!cancel) {                
                if (editing) {
                    if (value!==null && value!==undefined){                                                    
                        // Modificacion: refresco solo al padre
                        refresh && refresh(true)
                    }

                    const newValue={
                        selected: {
                            [relacionado.propertyId]:entity[relacionado.propertyId],
                            [relacionado.propertyValue]:entity[relacionado.propertyValue],
                            forzado:true
                        },
                        name: rest.name,                        
                    }
                    
                    // Meto los cambios en el control
                    handleChange(rest.name,newValue)
                }
                else
                    // Refresco el elemento al que pertenezco y su padre
                    refresh && refresh(false)

                // Cierro el dialogo
                setDetail(null)
            }
        }
    }; 
    
    const applyType= value =>{
        return format ? format.apply(value) : value
    }

    const getValue= value =>{        
        let newValue=value

        if (format)                        
            newValue=format.getValue(value)

        return newValue
    }    

    const correctValue = value => {        
        if (value !== null & value !==undefined & value !=='')
            if (format)
                return format.isValueOk(value)

        return true
    }

    return ([
        <TextField            
            {...rest}
            type={format ? format.base : type}            
            value={value===null ? '' : applyType(relacionado ? value[relacionado.propertyValue] : value)}
            error = {error ? error : !correctValue(relacionado ? value[relacionado.propertyValue] : value)}
            helperText={(format && helperText)
                ? (!correctValue(relacionado ? value[relacionado.propertyValue] : value) ? format.messageError : helperText)
                : helperText
            }
            fullWidth
            onChange={event => handleChange(event.target.name,getValue(event.target.value))}
            onKeyPress={format && ((event)=> !format.isCharValid(event.key) && event.preventDefault())}
            margin="dense"
            size='small'            
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
                readOnly: relacionado ? true : !editing,
                startAdornment: !relacionado ? undefined :(
                    <React.Fragment>
                        <Tooltip title={(value===null || value===undefined) || `Abrir ${rest.label}`}>
                            <IconButton
                                disabled={lockRelacionado()}                                        
                                style={{ padding: 0, marginRight: 4 }}
                                size="small"
                                onClick={(event) => openDetail()}
                            >
                                {Icon && <Icon color="primary" />}
                            </IconButton>
                        </Tooltip>                        
                    </React.Fragment>
                )
            }}
        />,       
        <Detail 
            item={detail}
            open={detail !== null}
            close={() => setDetail(null)}
            edit={editDetail}
            readOnly={relacionado && relacionado.readOnly}            
        />
    ]);
};

export default Text;


