import React, { useState, useEffect } from 'react';

//import clsx from 'clsx';
import moment from 'moment'
import es from 'moment/locale/es'
import { makeStyles } from '@material-ui/styles';

import { reloj } from 'helpers'

import {
  Fab,  
  Card,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  CircularProgress,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import AceptarIcon from '@material-ui/icons/Done';
import CancelarIcon from '@material-ui/icons/ExitToApp';
import * as icons from 'icons' 

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.info.main,
    height: 35,
    width: 35,
    fontSize: 10,
    marginRight: theme.spacing(1)
  },
  progress: {
    color: theme.palette.warning.main,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  aceptar: {
    color: theme.palette.white,
    backgroundColor: theme.palette.success.dark
  },
  cancelar: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.dark
  },
  iconoExtendido:{
    marginRight: theme.spacing(1),
  }

}));

const SelectorEventos = props => {
  const classes = useStyles(); 

  const { eventos, picar, terminar, seleccionar, disabled, updating, ...rest } = props;  
    
  const [fecha, setFecha] = useState(new Date())
  const [location, setLocation] = useState({})
 
  /* useEffect(() => {      
    // Selecciono los eventos si solo es uno      
    eventos.isReady && eventos.isFiltered 
      && eventos.list.length === 1 && eventos.itemsSelected === 0
        && eventos.selectAll(true)

    }, [eventos]
  ); */
  
  useEffect(() => {    
    // Activo el reloj
    const id = reloj(0, setFecha);

    // Activo la ubicacion
    const idLoc=navigator.geolocation.watchPosition(
      posicion => {setLocation(posicion)},
      error => {console.log(error);setLocation(error)},
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
    
    return () =>{
      reloj(id);
      navigator.geolocation.clearWatch(idLoc)
    }
  }, []);
  
  return (
    <Card {...rest} className={classes.root}>
      <CardContent>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                {!eventos.isReady 
                  ? 
                    <CircularProgress className={classes.progress} disableShrink={true}/> 
                  : location.coords 
                      ?
                        <icons.LocationIcon color='secondary'/>
                      :
                        <icons.LocationOffIcon color="error"/>
                }
              </TableCell>
              <TableCell>
                <Typography variant="h6">
                  {!eventos.isReady ? 'Cargando ...' : moment(fecha).format('dddd DD/MM/YYYY HH:mm:ss')}
                </Typography>                
              </TableCell>
            </TableRow>
          </TableHead>          
          <TableBody>
            {eventos.isFiltered && eventos.list.map(evento => (
              <TableRow
                className={classes.tableRow}
                hover
                key={evento.id}
                selected={eventos.selected.indexOf(evento.id) !== -1}
                onClick={() => seleccionar(evento.id,location,fecha)}
                  
              >
                <TableCell padding="checkbox" align='center'>
                  <Checkbox
                    checked={eventos.selected.indexOf(evento.id) !== -1}
                    color="primary"                    
                  />
                </TableCell>
                <TableCell>
                  <div className={classes.nameContainer}>
                    <Avatar className={classes.avatar}>
                      {evento.id}
                    </Avatar>
                    <Typography color="textSecondary" variant="h6">{evento.descripcion}</Typography>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>          
        </Table>        
      </CardContent>
      <CardActions>        
        <div className={classes.actions}>
          {false //location.message
            ?
              [                
                <Typography color="error" variant="h6">
                  {location.code===1 
                    ? 'Es necesario activar la ubicación'
                    : 'No fue posible obtener una ubicación'
                  }
                </Typography>
              ]
            :
              <Fab className={classes.aceptar}
                size='medium'
                variant='extended'            
                disabled={!picar || eventos.itemsSelected === 0 || updating } //|| !location.coords}
                onClick={picar}
              >
                <AceptarIcon className={classes.iconoExtendido}/>
                {!updating
                  ?
                    'Aceptar'                      
                  :
                  <div className={classes.actions}>                        
                    Guardando
                    <CircularProgress className={classes.progreso} size={22}/>
                  </div>
                }
              </Fab>
          }
          {terminar &&
            <Fab className={classes.cancelar}
              size='medium'
              variant='extended'
              onClick={terminar}
              disabled={updating}
            >
              <CancelarIcon className={classes.iconoExtendido}/>
              Terminar
            </Fab>            
          }
        </div>
      </CardActions>          
    </Card>
  ); 
};

export default SelectorEventos;









