import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
//import { DragDropContext } from 'react-beautiful-dnd'
import { Pila } from 'components'
import { Actions, Toolbar, Detail,Element } from 'components/Crud';

import List from 'features/List';
import Auth from 'features/Auth'
import Entity from 'features/Entity'
import DetalleTarea from 'views/Tareas/Detalle'

import * as enums from 'enums';

import { sumaLista,contadorTemporal } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),                       
    },
}));

const changeState = (id, state , list, next) =>{
    list.select(id,true);
    list.update(undefined,{estado:state},undefined,
        () => {
            next.map(l => l.refresh(false))
        }
    )    
}

const changeValidation = (id, validation , list, next) =>{
    list.select(id,true);
    list.update(undefined,{validacion:validation},undefined,
        () => {
            next.map(l => l.refresh(false))
        }
    )    
}

const changeArchive = (id, list, next) =>{    
    list.select(id,true);
    list.update(undefined,{archivada:true},undefined,
        () => {
            next.map(l => l.refresh(false))
        }
    )    
}

const reorder=(list,result) =>{
    if (!result.destination) {
        return;
    }        

    list.reorder(result.draggableId,result.source.index, result.destination.index);        
}

const Tablero = (props) => {
    const { 
        pending,
        todo,
        doit,
        done,
        rejected,
        validated,
        tovalidated,         
        ...rest
    } = props;

    const classes = useStyles();

    const aplicarFiltros=(filtro,valor,operador,tipo) =>{
        pending.filter(filtro,valor,operador,tipo)
        todo.filter(filtro,valor,operador,tipo)
        doit.filter(filtro,valor,operador,tipo)
        done.filter(filtro,valor,operador,tipo)
        rejected.filter(filtro,valor,operador,tipo)
        tovalidated.filter(filtro,valor,operador,tipo)
    }

    const filters = [
        {
            column: '',
            title: '',
            type: 'text',
            titleFilter: 'Buscar',
            filter: valor =>{                 
                aplicarFiltros(
                    [                        
                        'id',
                        'codigo',                        
                        'resumen', 
                        'detalles',
                        'prioridad',
                        ['equipo','nombre'],                        
                    ],valor,'','text'
                )
            }
        },
        {
            column: '',
            title: '',
            type: 'text',
            titleFilter: 'Expediente/Socio',
            filter: (valor) =>{
                aplicarFiltros(
                    [   
                        ['expediente','codigo'],
                        ['expediente','resumen'],
                        ['socio','nombre'],                        
                    ],valor,'','text'
                )
            }    
        },
        {
            column: '',
            title: '',
            type: 'text',
            titleFilter: 'Asignado a',
            filter: (valor) =>{
                aplicarFiltros(
                    [   
                        ['usuarios',['usuario','nombre']]
                    ],valor,'','text'
                )
            }    
        },
        {
            column: 'fecha',
            title: 'Fecha',
            type: 'date',
            titleFilter: 'Fecha (desde)',
            filter: (valor) =>{
                aplicarFiltros(['fecha'], valor, '>=', 'date')                    
            }
        },
        {
            column: '',
            title: '',
            type: 'Date',
            titleFilter: 'Fecha (hasta)',
            filter: (valor) => {
                aplicarFiltros(['fecha'], valor, '<=', 'date')
            }
        },
    
    ];

    useEffect(() => {
        pending.load('tareas/pending')
        todo.load('tareas/todo')
        doit.load('tareas/do')
        done.load('tareas/done')
        rejected.load('tareas/rejected')        
        tovalidated.load('tareas/tovalidated')

        return () =>{
            pending.unload()
            todo.unload()
            doit.unload()
            done.unload()
            rejected.unload()            
            tovalidated.unload()
        }

    }, []);

    const [detail, setDetail] = useState(null);

    const openDetail=(entidad,id,list) =>{        
        const key=Date.now()

        setDetail({
            key: key,
            id: id,
            title: 'Tarea',
            resume: ['codigo'],
            api: entidad + '/' + id,
            container: Entity(Auth(Element,'auth'), 'entity', key),
            element: entidad==='tareas' ? DetalleTarea : null,
            list:list
        })        
    }

    const editDetail = (key, cancel, id, isEditable) => {        
        if (!isEditable) {
            if (!cancel) {
                detail.list.refresh()
            }
        }
    };

    return (
        <div className={classes.root}>            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Toolbar
                        {...rest}
                        title={'Controlar Incidencias'}
                        disabled={false}
                        filters={filters} 
                        menu={
                            <Actions                                
                                entities={[
                                    pending,
                                    tovalidated,
                                    todo,
                                    doit,
                                    done,
                                    rejected,
                                ]}
                                float={true}
                                readOnly={true}
                            />
                        }                               
                    />
                </Grid> 
                <Grid item xs={3}>                                                                        
                    <Pila
                        id='pending'
                        list={pending.list}
                        state={enums.estados[1]}
                        next={[enums.estados[2]]}
                        change={(id,state) => changeState(id,state,pending,[todo])}   
                        reorder={(result) => reorder(pending,result)}
                        resumen={() => contadorTemporal(sumaLista(pending.list,'estimacion'))}
                        detail={(entidad,id) => openDetail(entidad,id,pending)}                     
                    />
                    <Pila
                        id='tovalidated'
                        list={tovalidated.list}
                        state={enums.validaciones[1]}
                        next={[enums.validaciones[2],enums.validaciones[3]]}
                        change={(id,validation) => changeValidation(id,validation,tovalidated,[done,rejected])}
                        detail={(entidad,id) => openDetail(entidad,id,tovalidated)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Pila
                        id='todo'
                        list={todo.list}
                        state={enums.estados[2]}
                        next={[enums.estados[3]]}
                        change={(id,state) => changeState(id,state,todo,[doit])}
                        reorder={(result) => reorder(todo,result)}
                        resumen={() => contadorTemporal(sumaLista(todo.list,'estimacion'))}                        
                        detail={(entidad,id) => openDetail(entidad,id,todo)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Pila
                        id='doit'
                        list={doit.list}
                        state={enums.estados[3]}
                        next={[enums.estados[4]]}
                        change={(id,state) => changeState(id,state,doit,[done,tovalidated])}
                        reorder={(result) => reorder(doit,result)}
                        resumen={() => contadorTemporal(sumaLista(doit.list,'estimacion'))}                         
                        detail={(entidad,id) => openDetail(entidad,id,doit)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Pila
                        id='done'
                        list={done.list}
                        state={enums.estados[4]}
                        next={[enums.archivo[0]]}
                        change={(id,state) => changeArchive(id,done,[rejected,tovalidated])} 
                        resumen={() => contadorTemporal(sumaLista(done.list,'estimacion'))}                                               
                        detail={(entidad,id) => openDetail(entidad,id,done)}
                    />
                    <Pila
                        id='rejected'
                        list={rejected.list}
                        next={[enums.archivo[0]]} 
                        state={enums.validaciones[3]} 
                        change={(id,state) => changeArchive(id,rejected,[])}                        
                        detail={(entidad,id) => openDetail(entidad,id,rejected)}
                    />
                    {/* <Pila
                        id='validated'
                        list={validated.list}
                        state={enums.validaciones[2]}                        
                    /> */}
                </Grid>
            </Grid>
            <Detail 
                item={detail}
                open={detail !== null}
                close={() => setDetail(null)}
                edit={editDetail}
                readOnly={false}
            />
        </div>
    );
};

export const TableroContainer = 
    List(List(List(List(List(List(Tablero
        ,'pending','tareas/pending')
        ,'todo','tareas/todo')
        ,'doit','tareas/do')
        ,'done','tareas/done')
        ,'rejected','tareas/rejected')        
        ,'tovalidated','tareas/tovalidated')

export default TableroContainer;
