import {
    ENTITY_INIT,
    ENTITY_SUCCESS,
    ENTITY_FAILED,
    ENTITY_UPDATE,
    ENTITY_UNDO,
    ENTITY_CLEAR,
    ENTITY_EDIT,
    ENTITY_REFRESH,
    ENTITY_UNLOAD,
    ENTITY_UPDATING,
    ENTITY_REFRESHING,
    ENTITY_ADDED,
    ENTITY_SAVED,
    ENTITY_DELETED,
    //AUTH_LOGOUT
} from 'constants.js';

import config from 'settings/environment';
import { isEntityInit, getEntity, getEntityApi, changesEntity, getToken } from 'settings/redux/reducer';
import {pausa} from 'helpers'

export const entity = (entidad, api) => (dispatch, getState) => {    
    const empty = Object.keys(getEntity(entidad, getState())).length === 0    

    if (empty && !isEntityInit(entidad,getState())) {        
        // accion para el reducer
        dispatch({ type: ENTITY_INIT, payload: { entidad, api } });

        const token = getToken(getState());

        // accion para el servidor
        fetch(
            `${config.servidor}:${config.puerto}/api/${api}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }
        ).then(
            (response) => {
                return response.json();
            }
        ).then(
            (data) => {
                if (data.code !== undefined) {
                    dispatch({ type: ENTITY_FAILED, payload: { error: data, entidad } })
                }
                else {
                    dispatch({ type: ENTITY_SUCCESS, payload: { data, entidad } })
                }
            },
            (error) => dispatch({ type: ENTITY_FAILED, payload: { error: {code: 503, message: 'El servicio no esta disponible'}, entidad } })
        );
    }
};

export const refreshEntity = (entidad, newApi, data, onlyInitial, action) => (dispatch, getState) => {    
    if (data===undefined) {
        dispatch({ type: ENTITY_REFRESHING, payload: { entidad } });   
        
        const api = newApi===undefined ? getEntityApi(entidad,getState()) : newApi
        const token = getToken(getState());
        
        // accion para el servidor
        fetch(
            `${config.servidor}:${config.puerto}/api/${api}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }
        ).then(
            (response) => {
                return response.json();
            }
        ).then(
            (data) => {
                if (data.code !== undefined) {
                    dispatch({ type: ENTITY_FAILED, payload: { error: data, entidad } })
                }
                else {                    
                    dispatch({ type: ENTITY_REFRESH, payload: { data, entidad, api, onlyInitial } })                    
                    action && action();
                }
            },
            (error) => dispatch({ type: ENTITY_FAILED, payload: { error: {code: 503, message: 'El servicio no esta disponible'}, entidad } })
        );
    }
    else{
        // Ya tengo los datos cambio el estado
        dispatch({ type: ENTITY_REFRESH, payload: { data, entidad, onlyInitial } });
        action && action();
    }
};

export const updateEntity = (entidad, data) => ({ type: ENTITY_UPDATE, payload: { data, entidad } });

export const undoEntity = (entidad) => ({ type: ENTITY_UNDO, payload: { entidad } });

export const clearEntity = (entidad) => ({ type: ENTITY_CLEAR, payload: { entidad } });

export const editEntity = (entidad, data) => ({ type: ENTITY_EDIT, payload: { data, entidad } });

export const unloadEntity = (entidad) => (dispatch, getState) => {
    // Aqui segun las politicas de descarga del componente hago el dispatch o no
    dispatch({ type: ENTITY_UNLOAD, payload: { entidad } });
}

export const saveEntity = (entidad,postActions) => (dispatch, getState) => {
    let api  = getEntityApi(entidad,getState())    
    const cambios = changesEntity(entidad, getState());
    const alta = (api.toLowerCase().indexOf('/new') !== -1);
    let metodo = 'PUT';

    // Inicio la actualizacion
    dispatch({ type: ENTITY_UPDATING, payload: { entidad } });

    // Obtengo el token
    const token = getToken(getState());
        
    // Compruebo si se trata de un alta y por tanto tengo que hacer un post
    if (alta) {
        metodo = 'POST';
        const index=api.toLowerCase().indexOf("/new");        
        api = api.slice(0, index);
    }
    
    // accion para el servidor
    fetch(
        `${config.servidor}:${config.puerto}/api/${api}`,
        {
            method: metodo,
            body: JSON.stringify(cambios),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(
        (response) => {
            return response.json();
        }
    ).then(
        (data) => {            
            if (data.code !== undefined) {                
                dispatch({ type: ENTITY_FAILED, payload: { error: data, entidad } })
            }
            else {                
                //pausa(5000)                
                if (alta)
                    dispatch({ type: ENTITY_ADDED, payload: { data, entidad } });                                   
                else
                    dispatch({ type: ENTITY_SAVED, payload: { data, entidad } });
                
                // Ejecuto las acciones posteriores
                if (postActions!==undefined) {
                    postActions.map(accion => accion())
                    //dispatch(logout())
                }
            }
        },
        (error) => {            
            dispatch({ type: ENTITY_FAILED, payload: { error: {code: 503, message: 'El servicio no esta disponible'}, entidad } })
        }
    );

};

export const deleteEntity = (entidad) => (dispatch, getState) => {
    // accion para el reducer
    dispatch({ type: ENTITY_UPDATING, payload: { entidad } });

    // Obtengo el token
    const token = getToken(getState());
    const api  = getEntityApi(entidad,getState())

    // accion para el servidor
    fetch(
        `${config.servidor}:${config.puerto}/api/${api}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(
        (response) => {
            return response;
        }
    ).then(
        (data) => {
            if (data.code !== undefined) {
                dispatch({ type: ENTITY_FAILED, payload: { error: data, entidad } })
            }
            else {
                dispatch({ type: ENTITY_DELETED, payload: { entidad } })
            }
        },
        (error) => {
            dispatch({ type: ENTITY_FAILED, payload: { error: {code: 503, message: 'El servicio no esta disponible'}, entidad } })
        }
    );
};