import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { 
    IconButton,
    Tooltip,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    ListSubheader
} from '@material-ui/core';

import config from 'settings/environment';
import * as icons from 'icons';
import * as hlprs from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',        
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

const Documents = (props) => {  
    const classes = useStyles();
    
    const { name,nameKey,value, editing, onChange, dfault } =props;

    const [menuVisible,setMenuVisible] = useState(-1)

    const borrar = (index) =>{
        const newValue=hlprs.clone(value)

        newValue.splice(index,1)

        onChange([
            {
                field:name,
                value: {
                    selected: null,
                    name: name,
                    nameKey: nameKey,
                    value: newValue,
                }
            }
        ])
    }

    const añadir = () =>{                
        const newValue=!Array.isArray(value) ? [dfault] :(hlprs.clone(value)).concat(dfault)        

        onChange([
            {
                field:name,
                value: {
                    selected: null,
                    name: name,
                    nameKey: nameKey,
                    value: newValue,
                }
            }
        ])
    }

    return (
        <div >
            <ImageList className={classes.imageList} cols={3.5} rowHeight='100'>                
                {(value !==undefined && value !==null) && value.map((item,index) => (
                    <Tooltip title={item.resumen}>
                    <ImageListItem 
                        key={item.link}
                        onMouseOver={() => setMenuVisible(index)}
                        onMouseLeave={() => setMenuVisible(-1)}
                    >                        
                        {item.tipo==='image'
                            ? <img src={item.link} alt={item.resumen} />
                            : item.tipo !== null
                                ? <embed  src={item.link} alt={item.resumen} />
                                : <img src={`${config.rutaImagenes}/imagenes/documento.png`} alt={item.resumen} />                            
                        }

                        <ImageListItemBar                            
                            title={item.resumen}
                            subtitle={item.tipo}
                            classes={{
                                root: classes.titleBar,                                
                            }}
                            actionIcon={ menuVisible===index &&
                                <div className={classes.wrapper}>
                                    <Tooltip title='Visualizar/Descargar documento'>
                                        <IconButton                                            
                                            onClick={() =>window.open(item.link)}
                                        >
                                            {item.tipo!==null
                                                ? 
                                                    <icons.ViewIcon className={classes.title} />
                                                :
                                                    <icons.DownloadIcon className={classes.title} />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                    {(item.latitud || item.longitud) && (item.latitud !== null || item.longitud !==null) &&
                                        <Tooltip title='Ver geolocalización'>
                                            <IconButton                                             
                                                onClick={() => hlprs.getPosition(item.latitud,item.longitud)}
                                            >
                                                <icons.LocationIcon className={classes.title} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {editing &&                                                                        
                                        <Tooltip title='Quitar documento'>
                                            <IconButton                                             
                                                onClick={() => borrar(index)}
                                            >
                                                <icons.DeleteIcon className={classes.title} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            }
                        />
                    </ImageListItem>
                    </Tooltip>
                ))}
                {editing && 
                    <ImageListItem key="add">
                        <div className={classes.wrapper}>
                            <Tooltip title='Añadir documento'>
                                <IconButton
                                    style={{
                                        backgroundColor: 'blue',                                            
                                        color:'white'
                                    }}
                                    size='medium'                                             
                                    onClick={() => añadir()}
                                >
                                    <icons.AddIcon className={classes.title} />
                                </IconButton>
                            </Tooltip>
                        </div>                        
                    </ImageListItem>
                }
            </ImageList>
        </div>
    );
};

export default Documents;
