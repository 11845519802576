import React,{useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import {
    EditIcon,
    SaveIcon,
    CancelIcon,
} from 'icons';

import {Alert,Button} from 'components';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const Edition = props => {
    const { editing, updating, edit, save, cancel, changes } = props;
    const classes = useStyles();

    const[openDialog,setOpenDialog]=useState(false);

    return (
        <div className={classes.root}>
            <Button
                hidden={editing}
                toolTipText="Editar los datos de la entidad"
                disabled={false}
                //onClick={event => entity.edit(!entity.isEditable)}
                onClick={event => edit()}
                counter={0}
                busy={false}
                icon={<EditIcon />}
                float={true}
            />

            <Button
                hidden={!editing}
                toolTipText="Guardar los cambios registrados"
                disabled={updating || changes===0}
                //onClick={event => entity.save()}
                onClick={event => save()}
                counter={changes}
                busy={updating}
                icon={<SaveIcon />}
                float={true}
            />

            <Button
                hidden={!editing}
                toolTipText="Cancelar los cambios registrados"
                disabled={updating}
                //onClick={event => entity.undo()}
                onClick={() => setOpenDialog(true)}
                counter={0}
                busy={false}
                icon={<CancelIcon />}
                float={true}
            />
            <Alert
                open={openDialog}
                title='¿Esta seguro de cancelar los cambios realizados?'
                text= {`Si "Acepta" perdera todos los cambios realizados desde la ultima vez que los guardo. Tenga en cuenta que esta acción es irreversible.`}
                cancel={() => setOpenDialog(false)}
                accept={() => {
                    setOpenDialog(false)
                    cancel()                    
                }}
            />
        </div>
    );
};

export default Edition;
