import React,{useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    DeleteIcon,
    ViewIcon,    
} from 'icons';

import {Alert, Button} from 'components';

import { Checkbox, TableCell, TableRow, List,ListItem,ListItemText,ListItemIcon } from '@material-ui/core';
import { getValueFormatted, getValue } from 'helpers/index';

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        visibility: 'collapse'
    },
    row: {
        '&:hover $wrapper': {
            visibility: 'visible'
        }
    },
    listIcon:{
        padding:'0px',
        margin:'0px',        
        marginRight:'-30px',
        boder:'0px'
    },
    listItem:{
        padding:'2px',
        margin:'0px',        
        boder:'0px'
    }
}));

const Row = props => {
    const { view, disabled, columns, entities, item } = props;
    const classes = useStyles();

    const [openDialog,setOpenDialog]=useState(false);

    return (
        <TableRow
            className={classes.row}
            hover
            key={item.id}
            selected={entities.selected.indexOf(item.id) !== -1}>
            <TableCell padding="none" size='small'>
                <div className={classes.wrapper}>
                    <Button                                               
                        toolTipText="Visualiza el elemento actual"
                        float={false}
                        counter={0}
                        busy={false}
                        disabled={
                            entities.isUpdating ||
                            entities.isRefreshing ||
                            disabled
                        }
                        icon={<ViewIcon htmlColor='darkBlue' fontSize='small'/>}
                        onClick={event => view(item)}
                    />
                    <Button                        
                        toolTipText="Borra el elemento actual"
                        float={false}
                        counter={0}
                        busy={item.id && entities.isUpdating}
                        disabled={
                            entities.isUpdating ||
                            entities.isRefreshing ||
                            disabled
                        }
                        icon={<DeleteIcon htmlColor='darkRed' fontSize='small'/>}
                        onClick={() => setOpenDialog(true)}
                    />
                    <Alert
                        open={openDialog}
                        title='¿Esta seguro de eliminar el elemento?'
                        text= {`Si "Acepta" eliminara el elemento de la lista actual. Tenga en cuenta que esta acción es irreversible.`}
                        cancel={() => setOpenDialog(false)}
                        accept={() => {
                            setOpenDialog(false)
                            entities.delete(item.id)
                        }}
                    />
                </div>
            </TableCell>

            <TableCell padding="checkbox" size='small'>
                <Checkbox
                    checked={entities.selected.indexOf(item.id) !== -1}
                    color="primary"
                    onChange={event => entities.select(item.id, true)}
                />
            </TableCell>

            {columns.map(
                (column, index) => {
                    if (column.column !== '') {
                        const value = getValue(item, column.column)
                        if (Array.isArray(value))
                            return(
                                <TableCell key={index} size='small'>
                                    <List dense disablePadding className={classes.listItem} >
                                        {value.map(
                                            (v, index) => 
                                            <ListItem
                                                className={classes.listItem} 
                                                disableGutters
                                            >   
                                                { column.icon &&                                            
                                                    <ListItemIcon className={classes.listIcon} >
                                                        <column.icon 
                                                            style={{ fontSize: 20 }}                                                            
                                                        />
                                                    </ListItemIcon>
                                                }
                                                <ListItemText
                                                    className={classes.listItem}
                                                    //disableTypography={true}                                                    
                                                    primary={getValueFormatted(
                                                        column.type,
                                                        v
                                                    )}
                                                />
                                            </ListItem>        
                                        )}
                                    </List>
                                    </TableCell>
                            ) 
                        else
                            return(
                                <TableCell key={index} size='small'>
                                    {getValueFormatted(
                                        column.type,
                                        value
                                    )}
                                </TableCell>
                            )
                    } else return null
                })                
            }
        </TableRow>
    );
};

export default Row;
