import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { FormControlLabel,FormGroup,Switch as SwitchBase, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        switchBase:{
            color: "red"
        }
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',        
    }

}));

const OnOff = props =>{
    const classes = useStyles();
    const {                
        value,        
        editing,
        color,
        onChange,        
        className,
        ...rest
    } = props;    

    return(
        <div className={className}>
            <SwitchBase             
                {...rest}                                                       
                checked={value}                        
                onChange={(onChange && editing) && ((event) => onChange([{
                    field:event.target.name,
                    value: event.target.checked,event
                }]))}                                                
            />
        </div>
    )
}

const Switch = props => {
    const classes = useStyles();
    const {hidden,label,labelPlacement,className,...rest} = props;

    if (hidden)
        return null
    
    if (label)    
        return (        
            <FormControlLabel
                className={className} 
                control={<OnOff {...rest}/>}
                label={label}
                labelPlacement={labelPlacement ? labelPlacement : 'start'}
            />        
        );
    
    return (<OnOff className={className} {...rest}/>)
};

export default Switch;
