import React from 'react';

import { Crud } from 'components/Crud';
import Detalle from './Detalle';
import List from 'features/List';
import Auth from 'features/Auth';
import * as icon from 'icons';

const columns = empleados => [    
    {
        column: '',
        title: '',
        type: 'text',
        titleFilter: 'Buscar',
        filter: (valor) =>
            empleados.filter(
                [
                    'id',
                    'nombre',
                    'codigo',
                    'puesto',
                    'idHorario'
                ],
                valor,
                '',
                'text'
            )
    },
    {
        column: 'nombre',
        title: 'Nombre',
        type: 'text'
    },
    {
        column: 'codigo',
        title: 'Codigo',
        type: 'text'
    },
    {
        column: 'puesto',
        title: 'Puesto',
        type: 'text'
    },
    {
        column: 'idHorario',
        title: 'Horario',
        type: 'text'
    }
];

const CrudContainer = List(Auth(Crud,'auth'), 'entities', 'empleados');

const Empleados = props => {
    const { ...rest } = props;

    return (
        <CrudContainer
            title="Empleados"
            columns={columns}
            id="id"
            resume={['nombre']}
            api="empleados/"
            Detalle={Detalle}
            {...rest}
        />
    );
};

export default Empleados;
