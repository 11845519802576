import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import List from 'features/List';

import moment from 'moment';
import 'moment/locale/es';
//import * as hlp from 'helpers'
import * as reports from 'reports'

const useStyles = makeStyles(theme => ({
    root:{        
        width: '100%', 
        tableLayout:'auto', 
        backgroundColor:'white',
        pageBreakAfter:'auto',
        '& thead': { 
            display:'table-header-group'
        },
        '& tfoot': { 
            display:'table-footer-group'
        },
        '& td': { 
            pageBreakInside: 'avoid',
            pageBreakAfter: 'auto' 
        },
        '& tr': { 
            pageBreakInside:'avoid',
            pageBreakAfter: 'auto'
        },
        '& tbody tr:nth-child(even)':{
            backgroundColor:'lightgrey',
        }
    },    
    wrapper: {           
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'    
    },    
    special: {         
        fontFamily: "Lucida Console, Courier, monospace",
        fontSize: '12px',        
        fontWeight:'bold',
        textAlign: 'right',
        border:0    
    },    
    title: {       
        fontFamily: 'Lucida Console, Courier, monospace',    
        fontSize: '28px',
        fontWeight:'bold'            
    },    
    head: {        
        height: '35px',        
        textAlign: 'left',
        fontFamily: 'Lucida Console, Courier, monospace',
        fontSize: '12px', 
        fontWeight:'bold'      
    },    
    header: {    
        backgroundColor: 'gray',         
        fontFamily: 'Lucida Console, Courier, monospace',
        fontSize: '12px', 
        fontWeight:'bold'      
    },    
    aggregate: {    
        fontFamily: 'Lucida Console, Courier, monospace',
        fontSize: '12px', 
        fontWeight:'bold'    
    },    
    group: {        
        fontFamily: 'Lucida Console, Courier, monospace',
        fontSize: '12px', 
        fontWeight:'bold'    
    },    
    detail: {    
        fontFamily: 'Lucida Console, Courier, monospace',
        fontSize: '12px', 
        fontWeight:'normal',
        ':nth-child(even)': {
            backgroundColor: 'lightgrey'
        }    
    },    
    resume: {
        display: 'none',
        fontFamily: 'Lucida Console, Courier, monospace',
        fontSize: '12px',
        fontWeight:'normal' 
    },    
    footer: {        
        height: '35px',     
        backgroundColor: 'gray',
        fontFamily: 'Lucida Console, Courier, monospace',
        fontSize: '12px', 
        fontWeight:'bold'          
    }    
}));

const PageBreak = () =>{		
    return [
        <tr style={{display:'block'}}></tr>,
        <tr style={{display:'block', pageBreakBefore:'always'}}/>
    ]    
}

const Head = props => {
    const { head, colspan } = props;
    const classes = useStyles();
    
    return (
        <tr className={classes.head}>
            <th 
                colspan={ colspan}                
            >
                <div className={classes.wrapper}>
                    {head.map(h =>                
                        <div>
                            {`${h.label}: ${h.value}`}
                        </div>                        
                    )}
                </div>
            </th>
        </tr>
    )
}

const Headers = props => {
	const { fields } = props;
    const classes = useStyles(); 

    return (
        <tr className={classes.header}>
            {fields.map(h =>  {
                if (h.field)          
                    return (
                        <th align={h.align}
                            style={{
                                color: h.color,
                                backgroundColor: h.backgroundColor,
                                fontWeight: h.fontWeight, 
                                height: h.height,
                                width: h.width,
                            }}
                        >
                            {h.label}				
                        </th>
                    )
            })}
        </tr>
    )
}

const Row = (props) => {
    const { row,index } = props;
    const classes = useStyles();
    const p=
        <tr className={classes[row.style]}>
            {row.fields.map(f => {                
                if (f.field)            
                    return (
                        <td align={f.align}
                            colspan={f.cols ? f.cols : 1}
                            style={{
                                color: f.color,
                                backgroundColor: f.backgroundColor,
                                fontWeight: f.fontWeight, 
                                height: f.height,
                                paddingLeft: `${f.ident ? row.identation*40 : 0}px`
                            }}
                        >                            
                            {
                                ! f.value 
                                    ? ''
                                    : !Array.isArray(f.value) 
                                        ? 
                                            f.value 
                                        :   f.value.map(fi =>{
                                                return ([                                                    
                                                    fi,
                                                    <br/>,<br/>                                                                                                        
                                                ])
                                        })
                            }

                        </td>
                    )
            })}
        </tr>
    

    if (index!==0 && row.newPage)
        return [
            <PageBreak/>,
            p
        ]

    return p
}

const Report = props => {    
    const { match, data } = props;   
    const classes = useStyles(); 
    const report=require(`reports/${match.params.name}.json`)        
    const [rows,setRows]=useState(undefined);

    useEffect(() => {
        // Inicializo el report
        const rws = reports.initReport(report,data)
        
        if (rws) setRows(reports.getRows(report,rws))

        return data.unload;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data.isReady) setRows(reports.getRows(report,data.list))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.isReady]);
    
    if (!rows) return (
        <div>
            Cargando el Informe {report.key} .........
        </div>    
    )

    return (
        <table className={classes.root}>
            <thead>
                <tr>
                    <th colspan={report.fields.length}>
                        <div className={classes.wrapper}>
                            <div className={classes.title}>
                                {report.title}	
                            </div>
                            <div className={classes.special}>
                                Fecha de Impresión
                                <br></br>
                                <input
                                    className={classes.special}
                                    align="right"
                                    defaultValue={moment().format('DD/MM/YYYY HH:mm:ss')}								
                                >
                                
                                </input>
                            </div>
                        </div>
                    </th>							
                </tr>
                {report.head.length !==0 &&
                    <Head head={report.head} colspan={report.fields.length}/>
                }
                <Headers fields={report.fields}/>                
                <tr className={classes.aggregate}>
                </tr>												
            </thead>					
            <tbody>
                {rows.map((r,index) =>                    
                    <Row row={r} index={index}/>,                        
                )}
            </tbody>
            <tbody>						
                <tr className={classes.resume}>                    
                </tr>
            </tbody>
            <tfoot>
                <tr className={classes.aggregate}></tr>						
                <tr className={classes.footer}></tr>
            </tfoot>
            <tfoot style={{display: 'table', position:'fixed', width:'100%', bottom:'0px',left:'0px'}}>
                <tr className={classes.aggregate}>
                </tr>						
                <tr className={classes.footer}>
                    <td colspan={report.fields.length}>
                        <div className={classes.wrapper}>
                            <div>                                
                                {report.description}	
                            </div>                            
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

const ReportContainer = List(Report, 'data', 'reportSource');

export default ReportContainer;
