import moment from 'moment';
import es from 'moment/locale/es';
import config from 'settings/environment';

let timeBoxId=0;

export { default as chartjs } from './chartjs';

export const getInitials = (name = '') => {
    return name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map(v => v && v[0].toUpperCase())
        .join('');
};

export const getAvatar = (id, avatar) => {    
    return `${config.rutaPerfiles}/${id}/${avatar}`;
};

// Compara un item con un valor segun un operador y un tipo
export const compare = (item, valor, op, type) => {
    let resultado = false;
    
    const value = (valor, tipo) => {
        let resultado = false;

        switch (tipo.toLowerCase()) {
            case 'text':
                resultado = valor.toLowerCase();
                break;

            case 'number':
                resultado = parseFloat(valor);
                break;

            case 'date':
            case 'datetime-local':
            case 'month':
                resultado = Date.parse(valor);
                break;

            default:
                resultado = valor;
        }

        return resultado;
    };

    const compr = (item, valor, op, type) =>{
        let resultado = false;

        switch (op) {
            case '=':
                resultado = value(item, type) === value(valor, type);
                break;

            case '>=':
                resultado = value(item, type) >= value(valor, type);
                break;

            case '<=':
                resultado = value(item, type) <= value(valor, type);
                break;

            case '>':
                resultado = value(item, type) > value(valor, type);
                break;

            case '<':
                resultado = value(item, type) < value(valor, type);
                break;

            case '<>':
                if (type === 'text')
                    resultado = !value(item, type).match(value(valor, type));
                else resultado = value(item, type) !== value(valor, type);
                break;

            case 'in':
                resultado = valor.indexOf(item) !== -1;

                break;

            case '!in':
                resultado = valor.indexOf(item) === -1;

                break;

            default:
                if (type === 'text')
                    resultado = value(item, type).match(value(valor, type));
                else resultado = value(item, type) === value(valor, type);
        }

        return resultado;
    };

    if (item === null) {
        if (op === '<>' || op === '<=' || op === '<') resultado = true;

        if (op === '=' && type.toLowerCase() === 'checkbox' && !valor)
            resultado = true;
    } else{
        if (Array.isArray(item)){             
            for (var k = 0; k < item.length; k++) {
                resultado=compr(item[k],valor,op,type)                
                if (resultado) break;                
            }
        }
        else resultado=compr(item,valor,op,type)
    }

    return resultado;
};

export const pausa = intervalo => {
    console.log(intervalo);
    var d = new Date();
    var begin = d.getTime();

    var a = 0;
    while (a === 0) {
        d = new Date();
        if (d.getTime() - begin > intervalo) {
            a = 1;
        }
    }
};

export const reloj = (id, setDate) => {
    if (id !== 0) return () => clearInterval(id);

    return setInterval(() => {
        setDate(new Date());
        //console.log('Tic')
    }, 1000);
};

export const timer = (id, intervalo, accion) => {
    if (id !== 0) return clearInterval(id);

    return setInterval(() => {
        accion();        
    }, intervalo);
};

export const contadorTemporal = tiempo => {
    if (tiempo===undefined || tiempo === null || tiempo==='') return null;

    // Obtengo las horas
    const horas = Math.trunc(tiempo / 3600);

    // obtengo los minutos
    const minutos = Math.trunc((tiempo - horas * 3600) / 60);

    // obtengo los segundos
    const segundos = tiempo - horas * 3600 - minutos * 60;

    return (
        horas.toString().padStart(3, '0') +
        ':' +
        minutos.toString().padStart(2, '0') +
        ':' +
        segundos.toString().padStart(2, '0')
    );
};

export const segundosContador = contador => {    
    if (contador===undefined || contador === null || contador==='') return 0;    

    const elementos=contador.split(':')
    
    let horas=0;
    let minutos=0;
    let segundos=0;

    // Obtengo las horas
    (elementos.length>0) && (horas = parseInt(elementos[0]==='' ? 0 :elementos[0]));

    // obtengo los minutos
    (elementos.length>1) && (minutos = parseInt(elementos[1]==='' ? 0 :elementos[1]));

    // obtengo los segundos
    (elementos.length>2) && (segundos = parseInt(elementos[2]==='' ? 0 :elementos[2]));

    return (horas*3600)+(minutos*60)+segundos;
};

export const getValue = (obj, property) => {    
    const prop = Array.isArray(property) ? property[0] : property
    const newProp=Array.isArray(property) ? property[1] : null
    let newObj=null    

    if(Array.isArray(obj)){
        newObj=[]
        obj.forEach(itemObj => {
            const value=getValueProperty(itemObj,prop)
            if (value !==null && !newObj.includes(value)) newObj.push(value)
        })
    }
    else {        
        newObj=getValueProperty(obj,prop)
    }
    
    if (newProp !==null && newObj !==null){           
        return getValue(newObj,newProp)
    }
    
    return newObj
};

const getValueProperty = (obj,property) => {
    let value='';    
    
    property.split('+').forEach(e => {
        if (e.startsWith('['))
            // Es un literal lo concateno                        
            value=value + e.substring(1,e.length-1)
        else
            value=!isObject(obj[e]) && obj[e]!==null ? value+obj[e] : obj[e]
    })
    
    return value
}

export const getValueFormatted = (type, value) => {    
    if (typeof(type)==='function')
        return type(value);
    
    let newValue = '';
    const tp = type === undefined ? 'text' : type.toLowerCase();
    
    switch (tp) {
        case 'text':
            newValue = value;
            break;

        case 'number':
            newValue = value;
            break;

        case 'date':
            newValue = value === null ? '' : moment(value).format('DD/MM/YYYY');
            break;
        
        case 'time':
            newValue = value === null ? '' : moment(value).format('HH:mm:ss');
            break;
        
        case 'datetime':            
            newValue = value === null ? '' : moment(value).format('DD/MM/YYYY HH:mm:ss');
            break;

        case 'datetime-local':            
            newValue = value === null ? '' : moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL);
            break;
        
        case 'day':
            newValue = value === null ? '' : moment(value).format('dddd DD MMMM YYYY');
            break;

        case 'week':
            newValue = value === null ? '' 
                : `${moment(value).format('WW')} [${moment(value).startOf('week').format('dddd DD')} - ${moment(value).endOf('week').format('dddd DD')}] ${moment(value).format('MMMM')}`
            break;
        
        case 'month':
            newValue = value === null ? '' : moment(value).format('MMMM YYYY');
            break;
        
        case 'timecounter':
            newValue=contadorTemporal(value)
            break;

        default:
            newValue = value;
    }

    return newValue;
};

export const replacer = (key, value) => {
    // Compruebo las fechas para devolver la hora local y no la UTC
    if (typeof value === 'date') {
        return undefined;
    }

    // Cambio los nulos por
    if (value === null) return '';

    return value;
};

export const now=() => {
    return moment().format()
}

export const toDay=() => {
    return moment().format('YYYY-MM-DD')
}

export const sumaLista=(lista,campos,formato) =>{
    const obj=Array.isArray(campos);
    let total=obj ? {} : 0
    
    lista.map(
        registro => { 
            if (obj)            
                campos.map(campo => {
                    const acumulado=total[campo] ? total[campo] : 0
                    const value=registro[campo]
                    
                    total={
                        ...total,
                        [campo]:acumulado+value
                    }
                })            
            else
                total=total + registro[campos];
        }
    );
  
    if (!formato) return total

    if (obj){
        Object.keys(total).map(resume =>
            total={
                ...total,
                [resume]:getValueFormatted(formato,total[resume])
            }
        )
        return total
    }        
    else
        return getValueFormatted(formato,total)
}

export const singular=(word) =>{    
    return word.slice(0,word.length-1)
}

export const dateFormat=(period) => {
    switch(period){
        case 'days':
            return('yyyy-MM-DD')
        
        case 'weeks':
            return('GGGG-[W]WW')
        
        case 'months':
            return('YYYY-MM')
        
        case 'years':
            return('YYYY')
        
        default:
            return('')
    }

}

export const horasExtra=(item) =>{
    const total=0;

    return contadorTemporal(total);
}

export const groupBy=(list,groups) =>{
	if (groups.length===0)
		return list

	const reducer = (acc,curr) =>{				
		const valueKey=curr[groups[0].key]
		const valueData=groups[0].data ? curr[groups[0].data] : {}
		const index=acc.findIndex(item => item[groups[0].key].toLowerCase()===valueKey.toLowerCase())

		Reflect.deleteProperty(curr,groups[0].key)
		groups[0].data && Reflect.deleteProperty(curr,groups[0].data)

		if (index===-1){
			acc.push(groups[0].data
				? 
				{
					[groups[0].key]:valueKey,
					[groups[0].data]:valueData,
					[groups[0].details]:[curr]
				}
				:
				{
					[groups[0].key]:valueKey,
					[groups[0].details]:[curr]
				} 
			
			)
		}
		else		
			acc[index][groups[0].details].push(curr)
		
		return acc;
	}
    
	const newList= list.reduce(reducer,[])	

	newList.map(item =>{
		groups[0].aggregates && Object.assign(item,sumaLista(item[groups[0].details],groups[0].aggregates))		
		item[groups[0].details]=groupBy(item[groups[0].details],groups.slice(1))

		return item
	})
	
	return newList
}

export const identText=(text,identation) =>{
    const ident=('  ').repeat(identation)

    console.log(ident+text)
    return ident+text
}

export const timeBox = (action,time) =>{    
    timeBoxId>0 && clearTimeout(timeBoxId)
        
    timeBoxId=setTimeout(action,time)        
}

export const clone= (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

export const isObject= (obj) =>{
    return ( (typeof obj === "object" || typeof obj === 'function') && (obj !== null) )
}

export const empty= (obj) =>{
    if (isObject(obj))
        return Object.keys(obj).length===0

    return true
}

export const getPosition=(latitud,longitud) =>{
    alert(`Ir a Latitud: ${latitud}, Longitud: ${longitud}`)
}