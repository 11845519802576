import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
    Card,
    CardContent,
    Grid,
    Typography,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';

import 'react-perfect-scrollbar/dist/css/styles.css';

import { Actions, Toolbar } from 'components/Crud';
import { Selector } from 'components';

import List from 'features/List';
import Auth from 'features/Auth';

import Item from './Item'
import Adjust from './Adjust'

import * as icons from 'icons';
import * as helpers from 'helpers';
import { print,listReports} from 'reports';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),                       
    },
    container: {       
        padding: theme.spacing(2),        
    },
}));

const ControlPresencia = (props) => {
    const {horario,auth, ...rest } = props;

    const classes = useStyles();    

    const filters = (option) =>{         
        const newFilters=[
            {
                column: '',
                title: '',
                type: 'text',
                titleFilter: 'Empresa',
                filter: valor =>{                 
                    horario.filter(
                        [                        
                            ['empleado',['contratos','idEmpresa']],
                            ['empleado',['contratos',['empresa','nombre']]]
                        ],valor,'','text'
                    )
                }
                
            },
            {
                column: '',
                title: '',
                type: 'text',
                titleFilter: 'Trabajador',
                filter: valor =>{                 
                    horario.filter(
                        [                        
                            'id',
                            'nombre',
                        ],valor,'','text'
                    )
                }
                
            }
        ]
        
        if (option===0){            
            newFilters.push(
                {
                    column: '',
                    title: '',
                    type: 'text',
                    titleFilter: 'Evento',
                    filter: valor =>{                 
                        horario.filter(
                            [
                                ['trabajado','idEventoInicial'],
                                ['trabajado',['eventoInicial','descripcion']],                        
                            ],valor,'','text',false,true
                        )
                    }
                }
            )            
        }       
        
        return newFilters
    }

    const setCheck =(check,value) =>{          
        const newChecks = selector.checks.map(c => {
            if (c.name===check)
                return {...c , value:value}
            else
                return c
        })
        
        const opt=selector.options[selector.option]
        const since=moment(selector.range[0]).startOf(helpers.singular(opt.period)).format('yyyy-MM-DD')
        const until=moment(selector.range[1]).endOf(helpers.singular(opt.period)).format('yyyy-MM-DD')        
        
        let params=`since=${since}&until=${until}`
        newChecks.forEach(chk => {
                if (chk.value) 
                    params=params.concat(`&${chk.name}=true`)
        })
        
        const newApi=`type=${opt.value}&${params}`                
        

        helpers.timeBox(() => {            
            horario.refresh(false,`usuarios/workedup?${newApi}`,undefined,
                () => { 
                    setType(selector.options[selector.option].value)
                }
            )
            setParams(params)
        },350) 
        
        setSelector(
            {...selector,
                checks:newChecks,                
            }
        )      
         
    }

    const setOption = (option,range) =>{         
        const opt=selector.options[option]
        
        const since=moment(range[0]).startOf(helpers.singular(opt.period)).format('yyyy-MM-DD')
        const until=moment(range[1]).endOf(helpers.singular(opt.period)).format('yyyy-MM-DD')        
        
        let params=`since=${since}&until=${until}`        
        selector.checks.forEach(chk => {
            if (chk.value)
                params = params.concat(`&${chk.name}=true`)
        })
        
        const newApi=`type=${opt.value}&${params}`        
        
        helpers.timeBox(() => {            
            horario.refresh(false,`usuarios/workedup?${newApi}`,undefined,
                () => { 
                    setType(selector.options[option].value)
                }
            )
            setParams(params)
        },350)
        
        setSelector(
            {...selector,
                option:option,
                range:range,
            }
        )

        //Borro el filtro por si acaso existe
        option!==0 && horario.clearFilter('trabajado,idEventoInicial,trabajado,eventoInicial,descripcion')
    }

    const select = (value) => {        
        const range=[value,value]
        const option=selector.option - 1
        
        setOption (option,range)
    }
    
    const [selector,setSelector]=useState({
        type:'date',
        option:0,
        range:[
            moment().format('yyyy-MM-DD'),
            moment().format('yyyy-MM-DD')
        ],        
        options:[            
            {label:"Diario", value:"diary", icon: icons.DiaryIcon, period: 'days',contentLabel:'Inicio',contentFormat:'datetime-local',helperText:'Eventos por día'},
            {label:"Semanal", value:"weekly", icon: icons.WeeklyIcon, period: 'weeks',contentLabel:'Dia',contentFormat:'day',helperText:'Eventos por día de la semana'},
            {label:"Mensual", value:"monthly", icon: icons.MonthlyIcon, period: 'months',contentLabel:'Semana',contentFormat:'week',helperText:'Eventos por semanas del mes'},
            {label:"Anual", value:"yearly", icon:icons.YearlyIcon, period: 'years',contentLabel:'Mes',contentFormat:'month',helperText:'Eventos por meses del año'},        
        ],
        checks:[            
            {label:"Laborables", name:'workable', value:false, helperText:'Muestra solo los dias laborables'}
        ]
    })

    const [item,setItem]=useState(null)
    const [type,setType]=useState(selector.options[selector.option].value)
    const [params,setParams]=useState(`since=${moment().format('yyyy-MM-DD')}&until=${moment().format('yyyy-MM-DD')}`)
    
    const printReport=(report)=>{        
        const option=selector.options[selector.option]

        const optReport={        
            payload:horario.list,            
            params: {
                head:[
                    {
                        value:option.label,                
                        label: 'Selección'
                    },
                    {
                        value:horario.empty ? 'No hay datos en la seleccion' : horario.list[0].periodo,
                        label: 'Periodo'
                    },                    
                ]
            },
            patch:{},
        }

        if (option.value !== 'diary') {            
            optReport.patch.fields=[
                {
                    key:'desde',
                    label:option.contentLabel,
                    format:option.contentFormat
                },
                {
                    key:'hasta',
                    field:''
                },
                {
                    key:'eventoInicial',
                    field:''
                },
                {
                    key:'eventoFinal',
                    field:''
                }
            ]
        }
        else {
            optReport.patch.groups=[
                {
                    key:'id',
                    show:[{   
                        key: "desde",                     
                        cols: 4
                    }]
                }
            ]
        }

        print(report,optReport)    
    }
    
    useEffect(() => {        
        horario.load(`usuarios/workedup?type=${type}&${params}`)        

        return () =>{
            horario.unload()            
        }

    }, []);    
    
    return (
        <div className={classes.root}>            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Toolbar
                        {...rest}
                        title={'Control Presencia'}
                        disabled={false}
                        filters={filters(selector.option)}
                        selector={
                            <Selector
                                options={selector.options}
                                checks={selector.checks}
                                type={selector.type}
                                setOption={setOption}
                                setCheck={setCheck}
                                option={selector.option}
                                range={selector.range}
                            />
                        } 
                        menu={
                            <Actions                                
                                entities={horario}
                                float={true}
                                readOnly={true}                                
                                print={listReports('registrohorario',printReport)}                                
                            />
                        }                               
                    />
                </Grid>
                <Grid item xs>
                    <Card raised={true} className={classes.root}>
                        <CardContent>
                            <TableContainer style={{maxHeight:500}}>
                                <Table stickyHeader size='small'>
                                    <TableHead>            
                                    </TableHead>          
                                    <TableBody>
                                        {horario.list.map((hor,index) =>{
                                            return (
                                                <TableRow
                                                    className={classes.tableRow}
                                                    hover
                                                    key={hor.id}                                                    
                                                >                   
                                                    <TableCell>
                                                        <Item
                                                            type={type}
                                                            item={hor}                                                            
                                                            adjust={setItem}
                                                            select={select}
                                                            active={item && (item.id===hor.id)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )})}
                                    </TableBody>          
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                
                {item && (
                    <Grid item xs> 
                        <Adjust
                            horario={horario}
                            auth={auth}
                            item={item}
                            range={params}
                            close={setItem}
                        />
                    </Grid> 
                )}                               
            </Grid>     
        </div>
    );
};

export const ControlPresenciaContainer = 
    List(Auth(ControlPresencia,'auth'),'horario','horario')

export default ControlPresenciaContainer;
