import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,        
        boxShadow: theme.shadows[20],
        padding: theme.spacing(2, 4, 3),
    },
}));

const PaperComponent = (props) => {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }

const Detail = (props) => {    
    const { open, item, close, edit, readOnly}=props
    const classes = useStyles(); 
    
    if (!open) 
        return(null)    
    
    const Container = item.container;
    
    return ( 
        <Dialog                        
            open={open}            
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullWidth={true}
            maxWidth='md'
        > 
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Typography variant='h4'>
                    {item.title}
                </Typography>
            </DialogTitle>           
            <DialogContent className={classes.paper}>
                <Container item={item} close={close} edit={edit} readOnly={readOnly}/>
            </DialogContent>        
      </Dialog>
    );
};

export default Detail;

