import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';

import {
    Grid,    
} from '@material-ui/core';

import {
    Document,
    Text,
    Tag,
} from 'components'

import DetailEmpleado from 'views/Empleados/Detalle'

import * as icons from 'icons'
import { print,listReports } from 'reports'


const printReport=(report,values)=>{    
    const optReport={        
        params:{
            proyecto: values.id
        }        
    }

    print(report,optReport)
}

const Detalle = props => {
    const { values, editing, handleChange,refresh } = props;
    //const classes = useStyles();
    
    return (
        <form autoComplete="off" noValidate>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Text
                        name="fecha"
                        label="Fecha"
                        helperText="Fecha de alta"                        
                        value={values.fecha}
                        type="date"
                        onChange={handleChange}  
                        editing={editing}                      
                    />
                </Grid>
                <Grid item xs>
                </Grid>
                <Grid item xs={6}>
                    <Tag
                        //variant="outlined"
                        color="secondary"
                        hidden={!values.empleado}
                        value={values.empleado && values.empleado.nombre}
                        id={values.idEmpleado}
                        helperText="Creador del Infome"
                        relacionado={{
                            Detail: DetailEmpleado,                            
                            resume:['nombre'],
                            api: 'empleados',
                            readOnly:true
                        }}
                        refresh={refresh}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        name="resumen"
                        label="Resumen"
                        helperText="Resumen o titulo"
                        value={values.resumen}
                        onChange={handleChange} 
                        editing={editing}                       
                    />
                </Grid>
                <Grid item xs={2}>                    
                    {/* <Document
                        toolTipText='Ver Inforem'
                        disabled={false}                                                
                        icon={<icons.PrintIcon/>}
                        selector={listReports('tiemposProyectos',(report) => printReport(report,values))}
                    /> */}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Text
                        name="detalles"
                        label="Detalles"
                        helperText="Descripción detallada"
                        value={values.detalles}
                        onChange={handleChange}
                        editing={editing}
                        multiline
                        rows={18}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
