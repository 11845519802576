import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import * as icons from 'icons';
//import * as hlprs from 'helpers';

import List from 'features/List';

import { Combo, Text, Tabs } from 'components';

import Contratos from './Tabs/Contratos'
import Vacaciones from './Tabs/Vacaciones'
import Incidencias from './Tabs/Incidencias'

import DetailUsuario from 'views/Usuarios/Detalle'
const ComboHorarios = List(Combo, 'options', 'lstHorarios');

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Detalle = (props) => {
    const { auth, values, editing, adding, handleChange,refresh } = props;
    const classes = useStyles();

    return (
        <form autoComplete="on" noValidate> 
            <Grid container spacing={1}>                
                <Grid item xs={2}>
                    <Text
                        name="codigo"
                        label="Código"
                        helperText="Identificador del empleado"
                        value={values.codigo}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text
                        name="nombre"
                        label="Nombre"
                        helperText="Nombre del empleado"
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>                                                  
            </Grid>           
            <Grid container spacing={1}>                                
                <Grid item xs={3}>
                    <Text
                        name="puesto"
                        label="Puesto"
                        helperText="Puesto de trabajo"
                        value={values.puesto}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>                
                <Grid item xs={3}>
                    <ComboHorarios
                        api="horarios"
                        name="horario"
                        nameKey="idHorario"
                        label="Horario"                        
                        refresh={refresh}
                        Icon={icons.ScheduleIcon}
                        helperText="Seleccione un Horario"
                        value={values.horario}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) =>
                            `${option.id}`
                        }
                    />                    
                </Grid>
                <Grid item xs>
                    <Text
                        name="usuario"
                        label="Usuario"
                        helperText="Usuario del empleado"
                        value={values.usuario}
                        onChange={handleChange}                        
                        editing={editing}
                        Icon={icons.UserIcon}
                        refresh={refresh}
                        relacionado={{
                            api: 'usuarios',                            
                            Detail: DetailUsuario,
                            propertyId:'id',
                            propertyValue:'nombre',
                            resume:['nombre'],                                                                              
                        }}
                    />
                </Grid>                
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Contratos',
                        icon:icons.LaboralIcon,
                        component: <Contratos {...props}/>
                    },
                    {
                        label: 'Vacaciones',
                        icon: icons.HolidaysIcon,
                        component: <Vacaciones {...props}/>,
                        disabled:false
                    },
                    {
                        label: 'Incidencias',
                        icon: icons.AbsenteeismIcon,
                        component: <Incidencias {...props}/>,
                        disabled:false
                    },                
                ]}
            />     
            
        </form>
    );
};

export default Detalle;
