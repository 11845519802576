import React,{useState} from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid,Typography,Divider } from '@material-ui/core';

import * as icons from 'icons';
import * as hlprs from 'helpers';
import moment from 'moment'


import List from 'features/List';

import { Combo, DataGrid, Text, Switch } from 'components';

import DetailUsuario from 'views/Usuarios/Detalle'

const ComboUsuarios = List(Combo, 'options', 'usuarios');
const ComboValidadores = List(Combo, 'options', 'validadores');
const ComboInformados = List(Combo, 'options', 'informados');

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));



const Detalles = (props) => {
    const { values, editing, adding, initial, handleChange,refresh, estableceFiltro } = props;
    const classes = useStyles();

    const asignaTarea = item => {
        // Tengo que sacar el valor original de la entidad
        const temp=initial.usuarios.find(element=> element.idUsuario===item.id)
    
        if (temp===undefined)        
            return {
                idUsuario:item.id,
                fechaAsignacion:hlprs.now(),
                usuario:item
            }
        
        return {
            idUsuario:item.id,
            fechaAsignacion:temp.fechaAsignacion,
            fechaActivacion:temp.fechaActivacion,
            fechaDesactivacion:temp.fechaDesactivacion,
            activa:temp.activa,
            usuario:item
        }
    }

    return (        
        <Grid container spacing={1}>                
            <Grid item xs>
                <Text
                    name="detalles"
                    label="Descripción"
                    helperText="Descripción detallada"
                    value={values.detalles}
                    onChange={handleChange}
                    editing={editing}
                    multiline
                    rows={18}
                />
            </Grid>
            <Grid item  xs>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <Typography variant='caption'>
                            Asignado a
                        </Typography>
                        <Divider />
                    </Grid>                        
                    <Grid item>
                        <ComboUsuarios
                            api="usuarios"
                            multiple
                            name="usuarios"                                
                            label="Trabajadores"
                            Icon={icons.UserIcon}
                            helperText="Trabajadores que realizaran la tarea. En verde aparece si esta activa actualmente"
                            value={values.usuarios ? values.usuarios.map(item=>{return {...item.usuario,activa:item.activa}}):[]}
                            onChange={handleChange}
                            editing={editing}
                            filtro={estableceFiltro("asignados")}                                
                            propertyValue="id"
                            getOptionLabel={(option) => option.nombre}
                            lockOption={(option) => option.activa===null ? false : option.activa}
                            colorOptionLocked='green'
                            getItem={asignaTarea}
                            relacionado={{
                                Detail: DetailUsuario,                                    
                                resume:['nombre'],
                                readOnly:true                                    
                            }}
                            refresh={refresh}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>
                            Validado por
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <ComboValidadores
                            api="usuarios"
                            multiple
                            name="validadores"                                
                            label="Validadores"
                            Icon={icons.UserIcon}
                            helperText="Validadores de la tarea"
                            value={values.validadores ? values.validadores.map(item=>item):[]}
                            onChange={handleChange}
                            editing={editing}
                            filtro={estableceFiltro("validados")}
                            propertyValue="id"
                            getOptionLabel={(option) => option.nombre}
                            getItem={item => ({
                                id:item.id,                                    
                                nombre:item.nombre
                            })}
                            relacionado={{
                                Detail: DetailUsuario,                                    
                                resume:['nombre'],
                                readOnly:true                                    
                            }}
                            refresh={refresh}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>
                            Informado a
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <ComboInformados
                            api="usuarios"
                            multiple
                            name="informados"                                
                            label="Informados"
                            Icon={icons.UserIcon}
                            helperText="Usuarios notificados por la tarea"
                            value={values.informados ? values.informados.map(item=>item):[]}
                            onChange={handleChange}
                            editing={editing}
                            filtro={estableceFiltro("informados")}
                            propertyValue="id"
                            getOptionLabel={(option) => option.nombre}
                            getItem={item => ({
                                id:item.id,                                    
                                nombre:item.nombre
                            })}
                            relacionado={{
                                Detail: DetailUsuario,                                    
                                resume:['nombre'],
                                readOnly:true                                    
                            }}
                            refresh={refresh}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>            
    );
};

export default Detalles;










