import React from 'react';
import moment from 'moment'

import { makeStyles } from '@material-ui/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import InputMask from 'react-input-mask';

import * as icons from 'icons';
import * as enums from 'enums';
import * as hlprs from 'helpers';

import List from 'features/List';

import { Combo, Tag, Text, Switch } from 'components';

import DetailEmpleado from 'views/Empleados/Detalle'

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom:'15px',        
    },
}));

const ComboTipos = List(Combo, 'options', 'tiposincidenciaempleado');

const Detalle = (props) => {
    const { auth, values, editing, adding, handleChange,refresh } = props;
    const classes = useStyles();

    return (
        <form autoComplete="on" noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Tag
                        //variant="outlined"
                        color="secondary"
                        hidden={!values.empleado}
                        value={values.empleado && values.empleado.nombre}
                        helperText="Empleado afectado"
                        id={values.idEmpleado}
                        relacionado={{                    
                            Detail: DetailEmpleado,
                            title: 'Empleado',                                                
                            resume:['nombre'],
                            api: 'empleados',
                            readOnly:true
                        }}                            
                    />   
                </Grid>
                <Grid item xs={2}>
                    <Switch
                        name='justificado'
                        label='Verificado'
                        value={values.justificado}
                        editing={editing}
                        onChange={handleChange}
                    />
                </Grid>                
            </Grid>            
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>            
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Text                        
                        name="desde"
                        label="Desde"
                        helperText="Inicio del evento"
                        value={moment(values.desde).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                        type="datetime-local"
                        onChange={handleChange}
                        editing={editing && (values.idPresencia===null)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Text                        
                        name="hasta"
                        label="Hasta"
                        helperText="Fin del evento"
                        value={moment(values.hasta).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                        type="datetime-local"
                        onChange={handleChange}
                        editing={editing && (values.idPresencia===null)}
                    />
                </Grid>
                <Grid item xs>
                    <Typography
                        className={classes.wrapper}
                        variant='h6'
                    >
                        {'Aqui pongo lo que dura'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>            
                <Grid item xs>
                    <ComboTipos
                        refresh= {refresh}
                        api="tiposincidenciaempleado"
                        name="tipo"
                        nameKey="idTipo"
                        label="Tipo"
                        Icon={icons.TypesIcon}
                        helperText="Seleccione un tipo de incidencia"
                        value={values.tipo}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"                        
                        getOptionLabel={(option) => option.descripcion}
                    />
                </Grid>                
                <Grid item xs={3}>                    
                    <Text                        
                        type="contadortemporal"
                        name="tiempo"                        
                        label="Tiempo Efectivo"
                        helperText="Horas imputadas a la incidencia"                        
                        value={values.tiempo}                        
                        onChange={handleChange}
                        editing={editing}
                    />                    
                </Grid>
                <Grid item xs={2}>                    
                    <Switch
                        className={classes.wrapper}
                        name='remunerado'
                        label='Remunerado'
                        value={values.remunerado}
                        editing={editing}
                        onChange={handleChange}                            
                    />                    
                </Grid>    
            </Grid>            
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>  
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text                        
                        name="resumen"
                        label="Resumen"
                        helperText="Breve descripcion del evento"
                        value={values.resumen}                        
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>            
            <Grid container spacing={1}>                                                
                <Grid item xs>
                    <Text                        
                        name="detalles"
                        label="Detalles"
                        helperText="Información detallada del evento"
                        value={values.detalles}                        
                        onChange={handleChange}
                        editing={editing}
                        multiline
                        rows={18}
                    />
                </Grid>                
            </Grid>                        
        </form>
    );
};

export default Detalle;










        
            