import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Typography,
    IconButton,
    Tooltip,
} from '@material-ui/core';

import {
    Avatar,
    Documents
} from 'components'

import List from 'features/List';

import DetailSocio from 'views/Socios/Detalle'
import DetailParcela from 'views/Parcelas/Detalle'

import * as icons from 'icons'
import * as hlprs from 'helpers';

const useStyles = makeStyles(theme => ({
    root: {
        //height: '100%',
        marginBottom: theme.spacing(1),
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
    },
}));

//const ComboSocios = List(Combo, 'options', 'socios');
//const ComboParcelas = List(Combo, 'options', 'parcelas');

const Detalle = props => {
    const { comentario } = props;
    const classes = useStyles();
    
    return (
        <Card raised={true} className={classes.root}>
            <CardHeader                
                title={<Typography variant='h3'>{comentario.resumen}</Typography>}
                subheader={<Typography>{comentario.usuario.nombre}</Typography>}                
                avatar={                  
                    <Avatar
                        size="large"
                        //variant="square"
                        //color="secondary"
                        //hidden={!values.propietario}
                        value={comentario.usuario.nombre}                        
                        image={hlprs.getAvatar(comentario.usuario.id, comentario.usuario.avatar)}
                    />
                }
                action={
                    <Typography variant='h5'>{moment(comentario.fecha).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                }
            />
            <Divider />            
            <CardContent>
                {comentario.idOrigen !== null && [
                    <div style={{borderRadius: '5px',backgroundColor:'#F9E79F' ,marginLeft:'50px',marginBottom:'12px',paddingLeft:'10px'}}>
                        <Grid container spacing={1} xs>                            
                            <Grid item xs>                            
                                <Typography variant='caption'>{`En Respuesta a Paco Bravo Lozano que dijo:`} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} xs>                            
                            <Grid item xs>                            
                                <Typography>{`Hay que tener precaución`} </Typography>
                            </Grid>                         
                        </Grid>
                        
                    </div>,                    
                    <Grid container spacing={1} xs>
                        <Grid item xs>
                            <Divider />
                        </Grid>
                    </Grid>                
                ]}                                                    
                <Grid container spacing={1} xs>
                    <Grid item xs>
                        <Typography variant='h6'>{comentario.comentario}</Typography>
                    </Grid>                    
                </Grid>                
            </CardContent>                        
            <CardActions>                
                <div className={classes.actions}>                   
                    <Grid container spacing={1} xs>
                        <Grid item xs>
                        <Divider />
                        </Grid>
                    </Grid>
                    {comentario.documentacion &&
                        <Grid container spacing={1} xs>
                            <Grid item xs>
                                <Documents
                                    value={comentario.documentacion}
                                    editing={false}
                                />
                            </Grid>
                        </Grid>                        
                    }                    
                </div>
            </CardActions>            
        </Card>
    );
};

export default Detalle;
