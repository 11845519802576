import { connect } from 'react-redux';
import {
    isListInit,
    isListReady,
    isListUpdating,
    isListRefreshing,
    hasListErrors,
    getList,
    getListApi,
    getListParameters,
    getPagedList,
    selectedList,
    getFiltersList,
    pageList,
    visiblesList
} from 'settings/redux/reducer';
import {
    listEntity,
    refreshList,
    clearList,
    deleteSelectedList,
    updateList,
    filterList,
    clearFilter,
    unloadList,
    setPageList,
    setVisiblesList,
    firstPageList,
    previousPageList,
    nextPageList,
    lastPageList,
    selectAllList,
    selectOneList,
    selectSomeList,
    reorderList,
} from './actions';

const map2Props = entidad => state => {
    const lista = getList(entidad, state);
    const seleccionados = selectedList(entidad, state);
    const filtros = getFiltersList(entidad, state);
    const pagina = pageList(entidad, state);
    const filasVisibles = visiblesList(entidad, state);

    return {
        list: lista,        
        api: getListApi(entidad, state),
        parameters: getListParameters(entidad, state),
        listPaged: getPagedList(entidad, state),
        selected: seleccionados,
        allSelected: lista.length === seleccionados.length,
        itemsSelected: seleccionados.length,
        page: pagina,
        visibles: filasVisibles,
        empty: lista.length === 0,
        isFiltered: Object.keys(filtros).length !== 0,
        filters: filtros,
        isInit: isListInit(entidad, state),
        isReady: isListReady(entidad, state),
        isUpdating: isListUpdating(entidad, state),
        isRefreshing: isListRefreshing(entidad, state),
        hasErrors: !!hasListErrors(entidad, state),
        errors: hasListErrors(entidad, state)
    };
};

const map2Dispatch = entidad => dispatch => {
    return {
        load: (api,selector,action) => {
            dispatch(listEntity(entidad, api, selector, action));
        },
        unload: () => {
            dispatch(unloadList(entidad));
        },
        refresh: (inicializa,api,data,action) => {
            dispatch(refreshList(entidad, inicializa, api, data,action));
        },
        clear: () => {
            dispatch(clearList(entidad));
        },
        delete: (id,action) => {
            dispatch(deleteSelectedList(entidad, id, action));
        },
        update: (news, updSel, updUnSel, action) => {
            dispatch(updateList(entidad, news, updSel, updUnSel, action));
        },
        filter: (campos, data, op, type, reset, nested) => {            
            dispatch(filterList(entidad, campos, data, op, type, reset, nested));
        },
        clearFilter: (filter) => {
            dispatch(clearFilter(entidad,filter));
        },
        setVisibles: data => {
            dispatch(setVisiblesList(entidad, data));
        },
        setPage: data => {
            dispatch(setPageList(entidad, data));
        },
        firstPage: () => {
            dispatch(firstPageList(entidad));
        },
        previousPage: () => {
            dispatch(previousPageList(entidad));
        },
        nextPage: () => {
            dispatch(nextPageList(entidad));
        },
        lastPage: () => {
            dispatch(lastPageList(entidad));
        },
        selectAll: (data,accion) => {
            dispatch(selectAllList(entidad, data, accion));
        },
        select: (data, multiple, action) => {
            Array.isArray(data)
                ? dispatch(selectSomeList(entidad, data, action))
                : dispatch(selectOneList(entidad, data, multiple, action));
        },
        reorder: (id, start, end) => {
            dispatch(reorderList(entidad, id, start, end));
        }
    };
};

export const List = (Component, property, entity) => {    
    return connect(
        map2Props(entity),
        map2Dispatch(entity),
        (stateProps, dispatchProps, ownProps) => {
            const api = {
                [property]: Object.assign({}, stateProps, dispatchProps)
            };

            return Object.assign({}, ownProps, api);
        }
    )(Component);
};

export default List;
