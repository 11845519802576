import React from 'react';

import { Crud } from 'components/Crud';
import Detalle from './Detalle';
import List from 'features/List';
import Auth from 'features/Auth';
import * as icon from 'icons';

const columns = parcelas => [    
    {
        column: '',
        title: '',
        type: 'text',
        titleFilter: 'Buscar',
        filter: (valor) =>
            parcelas.filter(
                [
                    'id',
                    'provincia',
                    'municipio',
                    'agregado',
                    'recinto',
                    'zona',
                    'resumen',
                    'detalles',
                    'catastro'
                ],
                valor,
                '',
                'text'
            )
    },
    {
        column: 'poligono',
        title: 'Polígono',
        type: 'number',
        titleFilter: 'Polígono',
        filter: (valor) =>
            parcelas.filter(['poligono'],
                valor,
                '',
                'number'
            )
    },
    {
        column: 'parcela',
        title: 'Parcela',
        type: 'number',
        titleFilter: 'Parcela',
        filter: (valor) =>
            parcelas.filter(['parcela'],
                valor,
                '',
                'number'
            )
    },
    {
        column: 'recinto',
        title: 'Recinto',
        type: 'number',
        titleFilter: 'Recinto',
        filter: (valor) =>
            parcelas.filter(['recinto'],
                valor,
                '',
                'number'
            )
    },
    {
        column: ['socio','nombre'],
        title: 'Socio',
        type: 'text',
        titleFilter: 'Socio',
        filter: (valor) =>
            parcelas.filter([['socio','nombre']],
                valor,
                '',
                'text'
            )
    },
    {
        column: 'resumen',
        title: 'Resumen',
        type: 'text'
    },
    
];

const CrudContainer = List(Auth(Crud,'auth'), 'entities', 'parcelas');

const Parcelas = props => {
    const { ...rest } = props;

    return (
        <CrudContainer
            title="Explotaciones"
            columns={columns}
            id="id"
            resume={['poligono','parcela','recinto']}
            api="parcelas/"
            Detalle={Detalle}
            {...rest}
        />
    );
};

export default Parcelas;
