import React, { useState, useEffect } from 'react';

import List from 'features/List';
import Entity from 'features/Entity'

import {Avatar,IconButton} from '@material-ui/core';

import { DataGrid, Modal, Error, Tag } from 'components'

import * as helpers from 'helpers';
import * as icons from 'icons';

import Detalle from './Detalle'
import DetailIncidencia from 'views/Empleados/Incidencias'

const getPosition=(latitud,longitud) =>{
    alert(`Ir a Latitud: ${latitud}, Longitud: ${longitud}`)
}

const Eventos = (props) => {
    const { nueva,controlPresencia, user, range, horario, auth } = props;    

    const columns=[
        {
            column: 'fecha',
            title: 'Fecha',
            align:'left',
            type: 'datetime'
        },
        {
            column: 'idEvento',        
            title: 'Evento',
            align:'left',        
            component:{
                Container: Avatar,
                props:{
                    style:{
                        backgroundColor: 'darkorange',
                        color:'white',
                        height: 35,
                        width: 35,
                        fontSize: 10,
                        marginRight: '10px'
                    },
                },
                detail: ['evento','descripcion'],
            }
        },
        {
            column: 'latitud+[,]+longitud',        
            title: 'Ubicación',
            align: 'center',
            component:{
                Container:IconButton,
                props:{
                    color:'primary',
                    children: <icons.LocationIcon/>,                    
                    size:'small'                    
                },
                parameters:{
                    style: row => {
                        if (row.latitud===0 && row.longitud===0) 
                            return {display:'none'}
                    },
                    onClick: row => () => getPosition(row.latitud,row.longitud)                    
                }                
            },            
        },
        {
            column: ['incidencia','justificado'],        
            title: 'Estado',
            align:'center',
            type:value => value==='true' ? 'Verificado' : 'Pendiente',
            component:{
                Container:Tag,
                props:{                
                    size:'small',                
                    relacionado:{                    
                        Detail: DetailIncidencia,
                        title: 'Incidencia',                                                
                        //resume:['id'],
                        api: 'incidenciasempleados',
                        readOnly:false
                    },
                    refresh:() => {
                        controlPresencia.refresh(false)
                        horario.refresh(false)
                    }
                },
                parameters:{
                    hidden: row => row.incidencia !== null ? false : true,
                    id: row => (row.incidencia!== null) && row.incidencia.id,
                    value: row => (row.incidencia!== null) && (row.incidencia.justificado ? 'Verificado' : 'Pendiente'),
                    style: row => {
                        if (row.incidencia){
                            if (row.incidencia.justificado)
                                return ({backgroundColor: 'green',color:'white',})
        
                            return ({backgroundColor: 'red',color:'white',})
                        }
                    }
                },                
            }
        }
    ]    
    const [openModal, setOpenModal] = useState(false);    
    const [row, setRow] = useState({});    
    
    useEffect(() => {                
        controlPresencia.load(`controlpresencia/usuario?user=${user.id}&${range}`)
        nueva.load('controlpresencia/new')         

        return () =>{
            controlPresencia.unload()
            nueva.unload()            
        }

    }, []);

    useEffect(() => {                
        controlPresencia.refresh(false,`controlpresencia/usuario?user=${user.id}&${range}`)         

    }, [user.id,range]);
    
    const editRow=(fila) =>{                
        setRow(fila)
        setOpenModal(true)
    }

    const addRow=() =>{                
        const fila={            
            fecha:helpers.now(),
            idPropietario:auth.user.id,
            propietario:{
                nombre:auth.user.nombre
            },
            idUsuario: user.id,
            usuario:{
                nombre: user.nombre
            },
            idEvento:null,
            evento:null
        }
        
        editRow(fila)        
    }

    const updateRow=(id,changes) =>{           
        if (id){
            /* controlPresencia.select(id,false,
                () => controlPresencia.update(undefined,changes,undefined,() => horario.refresh(false))
            ) */             
            const newChanges={
                ...row,
                ...changes
            }

            Reflect.deleteProperty(newChanges,'id');            

            controlPresencia.delete(id,
                () => {                    
                    nueva.update(newChanges)
                    nueva.save([
                        () => nueva.unload(),
                        () => nueva.load('controlpresencia/new'),
                        () => controlPresencia.refresh(false),
                        () => horario.refresh(false)
                    ])
                }
            )
            
            /* controlPresencia.delete(id,
                () => controlPresencia.update(
                    newChanges,undefined,undefined,
                    () => horario.refresh(false)
                )
            ) */
        }
        else{
            //controlPresencia.update(changes,undefined,undefined,() => horario.refresh(false))
            nueva.update(changes)
            nueva.save([
                () => nueva.unload(),
                () => nueva.load('controlpresencia/new'),
                () => controlPresencia.refresh(false),
                () => horario.refresh(false)
            ])
        }
        
        // Cierro el modal
        setOpenModal(false)
    }

    const deleteRow=(fila) =>{        
        controlPresencia.delete(fila,
            ()=>{
                horario.refresh(false)
                controlPresencia.refresh(false)
            }
        )
    }

    return ([
        <DataGrid                        
            list={controlPresencia.list}
            columns={columns}
            edit={editRow}
            add={addRow}                        
            erase={deleteRow}
            maxHeight={456}
            lockRow={row => {                
                if(row.incidencia!== null && row.incidencia !== undefined)
                    return row.incidencia.justificado
                return false
            }}
        />,        
        <Modal
            title='Editar'
            open={openModal}
            close={() => setOpenModal(false)}
        >   
            <Detalle 
                auth={auth}
                values={row}
                setValues={setRow}
                save={updateRow}
            />            
        </Modal>,
        <Error
            entities={[nueva,controlPresencia]}                
        />        
    ]);
};

export const EventosContainer = 
    Entity(List(Eventos,'controlPresencia','controlPresencia')
    ,'nueva','nuevaPicada')

export default EventosContainer;
