import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Alert = props => {
    const { title, text, open, accept, cancel } = props;

    const handleClose = () => {        
        cancel && cancel();
    };

    const handleResume = () => {
        accept && accept();
    };

    return (
        <div>
            <Dialog
                open={(typeof open === "boolean") ? open : open !==null}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {accept ? 'No' : 'Cerrar'}
                    </Button>

                    {accept && (
                        <Button
                            onClick={handleResume}
                            color="primary"
                            autoFocus>
                            Si
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Alert;
