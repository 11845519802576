import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes,{ PublicRoutes } from './Routes';
import Auth from 'features/Auth';
import { Redirect } from 'react-router-dom';
import { Error } from 'components';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = (props) => {
  const { auth } = props

  useEffect(()=>{     
    auth.doInit();
  },[]);

  return (
    <ThemeProvider theme={theme}>        
      <Router history={browserHistory}>
        <Error
          entities={[auth]}
          cancel={() => auth.clear()}          
        />        
        
        {!auth.errors && devuelveRutas(auth)}
      </Router>
    </ThemeProvider>
  );  
}

const devuelveRutas =(auth) => {    
  if (auth.isReady) {
    if (auth.isLogged) {      
      return ( 
        <span>
          {/* Cargo las rutas privadas*/}
          <Routes auth={auth}/>                        
        </span>                      
      )
    }

    return (
      <span>
        {/* Cargo las rutas publicas*/}                    
        <PublicRoutes />
        <Redirect to='/sign-in'/>                    
      </span>
    )
  }

  return (
    <div style={{
      display:'flex',                    
      backgroundColor:theme.palette.background.default,
      justifyContent: 'center',
      alignItems:'center',
      height:'100%',
      width:'100%'
    }}>                  
      <CircularProgress disableShrink={true} size={200}/>
    </div>                  
  )
}

export const AppContainer = Auth(App,'auth')

export default AppContainer;





