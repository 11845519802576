import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { CircularProgress, IconButton, TextField, Tooltip,Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Detail, Element } from 'components/Crud';

import Auth from 'features/Auth'
import Entity from 'features/Entity'

import * as hlprs from 'helpers'

const useStyles = makeStyles((theme) => ({
    root: {},
}));

const cargaOpciones = (lista, api, filtro) => {        
    if (filtro) 
        if (filtro[1].length===0) return;
    
    lista.load(api, undefined,() =>
        filtro && lista.filter(filtro[0],filtro[1],'in','text')
    )
}

const Combo = (props) => {
    const classes = useStyles();
    const {
        api,
        options,
        name,
        nameKey,
        //nameItem,
        label,
        helperText,
        value,
        onChange,
        editing,
        multiple,
        propertyValue,
        getOptionLabel,        
        getItem,
        Icon,
        filtro,
        lockOption,
        colorOptionLocked,
        relacionado,
        refresh
    } = props;

    const filtroJson=JSON.stringify(filtro);    
    //const fixedOptions = (multiple && lockProperty) ? value.filter(e => e[lockProperty]):[]

    useEffect(() => {        
        return options.unload;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>{             
        // Aplico el filtro
        if (!options.isInit && options.isReady)            
            if(filtro) {                
                options.filter(filtro[0],filtro[1],'in','text')
            }
    },[filtroJson])
    
    const handleChange = (event,value) => {        
        if (!onChange) return
        
        let newValue;

        if (multiple){
            if (!editing) return

            newValue = [];
            value.forEach(item => 
                newValue.push(
                    getItem ? getItem(item) : item
                )
            );
        }
        else {
            newValue= value !== null ? value[propertyValue] : null
        }
        
        onChange([
            {
                field:name,
                value: {
                    selected: multiple ? null : value,
                    name: name,
                    nameKey: nameKey,
                    value: newValue,
                }
            }
        ])        
    };

    const reviewOptions = (event,value) => {        
        if (fixedOptions.length===0)
            return handleChange(event,value)

        return handleChange(event,[
            ...fixedOptions,
            ...value.filter((option,index) => !lockOption(option,index))
        ])
    };
    
    const [fixedOptions] = useState((multiple && lockOption) ? value.filter((e,index) => lockOption(e,index)):[])
    const [detail, setDetail] = useState(null);

    const lockRelacionado=() =>{
        const alta= !multiple ? (value === null || value === undefined ? true : false) : true
        
        if (!relacionado) 
            return true
        
        if (alta && !editing) 
            return true
        
        if (alta && relacionado.readOnly)
            return true
        
        return false
    }

    const openDetail=(id) =>{                
        const key=Date.now()
        const newId= !multiple 
                        ? (value === null || value === undefined ? 'new' : value[propertyValue])
                        : (id === null || id === undefined ? 'new' : id)
        console.log(id)

        setDetail({
            key: key,
            id: newId,
            title: relacionado.title ? relacionado.title : label,
            resume: relacionado.resume,
            api: (relacionado.api ? relacionado.api : api) +'/' + newId,
            container: Entity(Auth(Element,'auth'), 'entity', key),
            element: relacionado.Detail,
            dfault: (newId==='new' ? relacionado.dfault : undefined),
            list:options
        })        
    }

    const editDetail = (key, cancel, id, isEditable, entity) => {        
        if (!isEditable) {
            if (!cancel) {
                // Refresco la lista o la cargo si estoy editando
                if (editing) {                    
                    // Refresco la lista de opciones
                    if (options.isInit === undefined) 
                        cargaOpciones(options,api,filtro)
                    else
                        detail.list.refresh()
                                        
                    if (!multiple){
                        if (value!==null && value!==undefined){                                                    
                            // Modificacion: refresco solo al padre
                            refresh && refresh(true)
                        }
                        
                        // Meto los cambios en el control
                        handleChange(undefined,entity)
                    }
                    else{
                        let newArray=Array.isArray(value) ? hlprs.clone(value) : []
                        if (!newArray.some(item => item[propertyValue]===id)) {
                            // Es un alta añado
                            newArray.push(entity)

                            // Y si estoy filtrado???????????????
                        }
                        else{
                            // Es una modificacion actualizo
                            newArray = newArray.map(listItem => listItem[propertyValue] === id ? {...listItem, ...entity} : listItem);

                            // Modificacion: refresco solo al padre
                            refresh && refresh(true)
                        }

                        handleChange(undefined,newArray)
                    }
                }
                else
                    // Refresco el elemento al que pertenezco y su padre
                    refresh && refresh(false)

                // Cierro el dialogo
                setDetail(null)
            }
        }
    };

    return ([
        <Autocomplete            
            multiple={multiple}
            value={value===undefined ? (multiple ? [] : null) : value}
            onChange={reviewOptions}
            onOpen={(event) =>
                editing && options.isInit === undefined && cargaOpciones(options,api,filtro)
            } 
            options={options.list}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option, value) =>
                option[propertyValue] === value[propertyValue]
            }
            forcePopupIcon={editing}
            disableClearable={!editing}
            clearOnEscape={editing}
            clearText="Borrar"
            closeText="Cerrar"
            openText="Abrir"
            loadingText="Cargando"
            noOptionsText={editing ? 'No encontrado' : ''}
            loading={options.isInit}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    style={colorOptionLocked && lockOption && 
                        (lockOption(option,index) ? {backgroundColor:colorOptionLocked} : {})
                    }                    
                    //variant='outlined'
                    color='primary'
                    size="small"                    
                    onClick={relacionado && 
                        ((event) => openDetail(
                            value[event.currentTarget.getAttribute("data-tag-index")][propertyValue]
                        ))}
                    label={getOptionLabel(option)}                    
                    {...getTagProps({ index })}   
                    disabled={(lockOption && editing) ? lockOption(option,index) : false}
                  />
                ))
            }            
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={helperText}
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        ...params.InputProps,
                        readOnly: !editing,
                        startAdornment: (
                            <React.Fragment>
                                <Tooltip title={(value===null || value===undefined) || multiple ? `Nuevo ${label}` : `Abrir ${label}`}>
                                    <IconButton
                                        disabled={lockRelacionado()}                                        
                                        style={{ padding: 0, marginRight: 2 }}
                                        size="small"
                                        onClick={(event) => openDetail()}>
                                        {Icon && <Icon color="primary" />}
                                    </IconButton>
                                </Tooltip>
                                {params.InputProps.startAdornment}
                            </React.Fragment>
                        ),
                        endAdornment: (
                            <React.Fragment>
                                {options.isInit ? (
                                    <CircularProgress
                                        disableShrink={true}
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
                        
        />,
        <Detail 
            item={detail}
            open={detail !== null}
            close={() => setDetail(null)}
            edit={editDetail}
            readOnly={relacionado && relacionado.readOnly}            
        />
    ]);
};

export default Combo;
