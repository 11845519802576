import React,{useState} from 'react';

import { makeStyles } from '@material-ui/styles';
import { Divider, Grid, Typography } from '@material-ui/core';

import * as icons from 'icons';
import * as enums from 'enums';
import * as hplrs from 'helpers';

import List from 'features/List';
import { Combo, Select, Tag, Text, Switch,Tabs } from 'components';

import DetailExpediente from 'views/Expedientes/Detalle'
import DetailSocio from 'views/Socios/Detalle'
import DetailParcela from 'views/Parcelas/Detalle'
import DetailUsuario from 'views/Usuarios/Detalle'

import { pausa } from 'helpers';

import Detalles from './Tabs/Detalles'
import Documentos from './Tabs/Documentos'

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom:'15px'
    },
}));

const ComboExpedientes = List(Combo, 'options', 'expedientes');
const ComboSocios = List(Combo, 'options', 'socios');
const ComboParcelas = List(Combo, 'options', 'parcelas');
const ComboEquipos = List(Combo, 'options', 'equipos');
const ComboFlujos = List(Combo, 'options', 'flujos');




const Detalle = (props) => {
    const { auth, values, editing, adding, handleChange, refresh } = props;
    const classes = useStyles();

    /*
    const [contador, setContador] = useState(contadorTemporal(values.estimacion));    

    const cambiaContador=(changes,event) =>{        
        setContador(changes[0].value)
        
        handleChange([{field:'estimacion',value:segundosContador(changes[0].value)}]);                
    } */

    const estableceFiltro=(name) => {
        const lst=[];

        if (values.flujo) {        
            const list=values.flujo[name] ? values.flujo[name] :[]        

            list.map(rol =>{
                rol.idRol!==null 
                    ? 
                        lst.push(rol.idRol)
                    :
                        lst.push(rol.idEquipo)
            })
        }
        
        return [
            [
                ['roles','id'],
                ['roles','idEquipo']
            ]
            ,lst
        ]        
    }    
    
    const validateEquipo = (changes,event) =>{
        // Borro Flujo
        changes.push({
            field:'flujo',
            value:{
                selected: null,
                name: 'flujo',
                nameKey: 'idFlujo',
                value: null,
            }
        })
        
        // Borro usuarios
        changes.push({
            field:'usuarios',
            value:{                
                name: 'usuarios',                
                value: [],
            }
        })

        // Borro validadores
        changes.push({
            field:'validadores',
            value:{                
                name: 'validadores',                
                value: [],
            }
        })

        // Borro informados
        changes.push({
            field:'informados',
            value:{                
                name: 'informados',                
                value: [],
            }
        })

        
        handleChange(changes,event);
    }

    const validateFlujo = (changes,event) =>{
        // Borro usuarios
        changes.push({
            field:'usuarios',
            value:{                
                name: 'usuarios',                
                value: [],
            }
        })

        // Borro validadores
        changes.push({
            field:'validadores',
            value:{                
                name: 'validadores',                
                value: [],
            }
        })

        // Borro informados
        changes.push({
            field:'informados',
            value:{                
                name: 'informados',                
                value: [],
            }
        })

        
        handleChange(changes,event);
    }

    const validateEstado = (changes,event) =>{
        // Compruebo como esta la validacion
        if (values.validacion.toLowerCase() ==='rechazada' || values.validacion.toLowerCase() ==='validada')
            return
        
        handleChange(changes,event);
    }

    const validateValidacion = (changes,event) =>{
        // Compruebo como esta el estado
        if (values.estado.toLowerCase() !=='finalizada')
            return
        
        handleChange(changes,event);
    }

    

    return (
        <form autoComplete="on" noValidate>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Tag
                        //variant="outlined"
                        color="secondary"
                        hidden={!values.propietario}
                        value={values.propietario && values.propietario.nombre}
                        id={values.idPropietario}
                        helperText="Creador de la Tarea"
                        avatar={
                            values.propietario &&
                            hplrs.getAvatar(
                                values.idPropietario,
                                values.propietario.avatar
                            )
                        }
                        relacionado={{
                            Detail: DetailUsuario,                            
                            resume:['nombre'],
                            api: 'usuarios',
                            readOnly:true
                        }}
                        refresh={refresh}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text
                        name="prefijo"
                        label="Prefijo"
                        helperText="Codigo incial para catalogar"
                        value={values.prefijo}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                </Grid>
                <Grid item xs={2}>
                    <Select
                        name="estado"
                        label="Estado"
                        helperText="Estado de la Tarea"
                        value={values.estado}
                        onChange={validateEstado}
                        editing={editing}
                        options={enums.estados}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Select
                        name="validacion"
                        label="Validación"
                        helperText="Estado de validación de la Tarea"
                        value={values.validacion}
                        onChange={validateValidacion}
                        editing={editing}
                        options={enums.validaciones}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        name="fecha"
                        label="Fecha"
                        helperText="Fecha de alta en la Organización"
                        value={values.fecha}
                        type="date"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        name="prioridad"
                        label="Prioridad"
                        helperText="Prioridad de la Tarea"
                        value={values.prioridad}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.prioridades}
                    />
                </Grid>
                <Grid item xs>
                    <div className={classes.wrapper}>
                        <Switch
                            value={values.urgente}
                            onChange={handleChange}
                            name="urgente"
                            label="Urgente"
                            editing={editing}
                            color="DarkRed"
                        />
                        <Switch
                            value={values.archivada}
                            onChange={handleChange}
                            name="archivada"
                            label="Archivada"
                            editing={editing}
                            color="DarkBlue"
                        />
                    </div>
                </Grid>                
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboSocios
                        api="socios"
                        name="socio"
                        nameKey="idSocio"
                        label="Socio"
                        relacionado={{
                            Detail: DetailSocio,
                            resume:['nombre'],
                            readOnly:false                            
                        }}
                        refresh={refresh}
                        Icon={icons.PartnerIcon}
                        helperText="Seleccione un Socio"
                        value={values.socio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) =>
                            `${option.nombre}`
                        }
                    />
                </Grid>
                <Grid item xs>
                    <ComboExpedientes
                        api="expedientes"
                        name="expediente"
                        nameKey="idExpediente"
                        label="Expediente"
                        relacionado={{
                            Detail: DetailExpediente,
                            resume:['codigo'],
                            readOnly:false                            
                        }}
                        refresh={refresh}
                        Icon={icons.ProceedingsIcon}
                        helperText="Seleccione un Expediente"
                        value={values.expediente}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) =>
                            `[${option.codigo}]-${option.resumen}`
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <ComboParcelas
                        api="parcelas"
                        multiple
                        name="parcelas"                                
                        label="Parcelas"
                        Icon={icons.ParcelIcon}
                        helperText="Parcelas afectadas"
                        value={values.parcelas ? values.parcelas.map(item=>item):[]}
                        onChange={handleChange}
                        editing={editing}
                        //filtro={estableceFiltro("parcelas")}
                        propertyValue="id"
                        getOptionLabel={(option) => `${option.poligono}-${option.parcela}-${option.recinto}`}
                        getItem={item => ({
                            id:item.id,                                    
                            poligono:item.poligono,
                            parcela:item.parcela,
                            recinto:item.recinto,
                        })}
                        relacionado={{
                            Detail: DetailParcela,                                    
                            resume:['resumen'],
                            readOnly:true                                    
                        }}
                        refresh={refresh}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboEquipos
                        api="equipos"
                        name="equipo"
                        nameKey="idEquipo"
                        label="Equipo"
                        Icon={icons.TeamIcon}
                        helperText="Seleccione un Equipo"
                        value={values.equipo}
                        onChange={validateEquipo}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) => option.nombre}
                        filtro={[['id'],auth.equipos()]}
                    />
                </Grid>
                <Grid item xs>
                    <ComboFlujos
                        api="flujos"
                        name="flujo"
                        nameKey="idFlujo"
                        label="Tipo"
                        Icon={icons.FlowIcon}
                        helperText="Seleccione un tipo de Tarea"
                        value={values.flujo}
                        onChange={validateFlujo}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) => option.resumen}
                        filtro={[['idEquipo'],[values.idEquipo]]}                        
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
            <Grid container spacing={1}>                
                <Grid item xs>
                    <Text
                        name="resumen"
                        label="Resumen"
                        helperText="Resumen o titulo"
                        value={values.resumen}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Text
                        type="contadortemporal"                        
                        name="estimacion"
                        label="Estimación"
                        helperText="Tiempo estimado para la tarea"
                        value={values.estimacion}
                        onChange={handleChange}                        
                        editing={editing}
                    />
                </Grid>
                
            </Grid>
            <Tabs
                tabs={[
                    {
                        label: 'Detalles',
                        icon:icons.DetailIcon,
                        component: <Detalles estableceFiltro={estableceFiltro} {...props}/>
                    },
                    {
                        label: 'Documentos',
                        icon: icons.DocumentIcon,
                        component: <Documentos {...props}/>,
                        disabled:false
                    }                
                ]}
            /> 
        </form>
    );
};

export default Detalle;
