import {
    LIST_INIT,
    LIST_SUCCESS,
    LIST_FAILED,
    LIST_REFRESH,
    LIST_REFRESHING,
    LIST_UNLOAD,
    LIST_CLEAR,
    LIST_CLEAR_FILTER,
    LIST_UPDATING,
    LIST_SAVED,
    LIST_DELETED,
    LIST_FILTERED,
    LIST_SETVISIBLES,
    LIST_SETPAGE,
    LIST_FIRST_PAGE,
    LIST_PREVIOUS_PAGE,
    LIST_NEXT_PAGE,
    LIST_LAST_PAGE,
    LIST_SELECT_ONE,
    LIST_SELECT_ALL,
    LIST_SELECT_SOME,
    LIST_REORDER,
} from 'constants.js';

import config from 'settings/environment';

import {
    getList,
    getListApi,
    getFiltersList,
    getListParameters,
    isListInit,
    selectedList,
    getToken,
} from 'settings/redux/reducer';

import { pausa } from 'helpers/index';

export const listEntity = (entidad, api, selector, action) => (dispatch, getState) => {
    const empty = getList(entidad, getState()).length === 0;

    if (empty && !isListInit(entidad, getState())) {
        // accion para el reducer
        dispatch({ type: LIST_INIT, payload: { entidad, api } });

        // Obtengo el token
        const token = getToken(getState());

        // accion para el servidor
        fetch(`${config.servidor}:${config.puerto}/api/${api}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then(
                (data) => {
                    if (data.code !== undefined) {
                        dispatch({
                            type: LIST_FAILED,
                            payload: { error: data, entidad },
                        });
                    } else {
                        //pausa(5000)
                        dispatch({
                            type: LIST_SUCCESS,
                            payload: { data, entidad },
                        });

                        if (selector !== undefined) {
                            const lista = data
                                .filter((tarea) => tarea[selector])
                                .map((tarea) => tarea.id);
                            dispatch({
                                type: LIST_SELECT_SOME,
                                payload: { entidad, data: lista },
                            });
                        }

                        // Ejecuto la accion posterior si existe
                        action && action();
                    }
                },
                (error) =>
                    dispatch({ type: LIST_FAILED, payload: { error:{code: 503, message: 'El servicio no esta disponible'} , entidad } })
            );
    }
};

export const refreshList = (entidad, inicializa, newApi, data, action ) => (
    dispatch,
    getState
) => {    
    if (data === undefined) {
        dispatch({ type: LIST_REFRESHING, payload: { entidad } });

        const api = newApi===undefined ? getListApi(entidad,getState()) : newApi        

        // Obtengo el token
        const token = getToken(getState());

        // accion para el servidor
        fetch(`${config.servidor}:${config.puerto}/api/${api}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then(
                (data) => {
                    if (data.code !== undefined) {
                        dispatch({
                            type: LIST_FAILED,
                            payload: { error: data, entidad },
                        });
                    } else {
                        dispatch({
                            type: LIST_REFRESH,
                            payload: { data, entidad, api, inicializa },
                        });
                        action && action();
                    }
                },
                (error) =>
                    dispatch({ type: LIST_FAILED, payload: { error:{code: 503, message: 'El servicio no esta disponible'}, entidad } })
            );
    } else {
        // Ya tengo los datos cambio el estado
        dispatch({
            type: LIST_REFRESH,
            payload: { data, entidad, inicializa },
        });
        action && action();
    }
};

export const deleteSelectedList = (entidad, id, action) => (dispatch, getState) => {
    const seleccionados =
        id === undefined ? selectedList(entidad, getState()) : [id];

    // accion para el reducer
    dispatch({ type: LIST_UPDATING, payload: { entidad } });

    // Obtengo el token
    const token = getToken(getState());

    const api = getListApi(entidad,getState())

    // accion para el servidor
    fetch(`${config.servidor}:${config.puerto}/api/${api}`, {
        method: 'DELETE',
        body: JSON.stringify(seleccionados),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((response) => {            
            return response.json();
        })
        .then(
            (data) => {                
                if ( data.code !== undefined) {
                    dispatch({
                        type: LIST_FAILED,
                        payload: { error: data, entidad },
                    });
                } else {
                    dispatch({
                        type: LIST_DELETED,
                        payload: { entidad, seleccionados },
                    });
                    action && action();
                }                
            },
            (error) => {                
                dispatch({ type: LIST_FAILED, payload: { error:{code: 503, message: 'El servicio no esta disponible'}, entidad } });
            }
        );
};

export const updateList = (entidad, news, updSel, updUnSel, action) => (
    dispatch,
    getState
) => {
    const seleccionados = selectedList(entidad, getState());
    const noSeleccionados = [];
    
    const api = getListApi(entidad,getState())

    getList(entidad, getState()).map(
        (item) =>
            !seleccionados.includes(item.id) && noSeleccionados.push(item.id)
    );

    // accion para el reducer
    dispatch({ type: LIST_UPDATING, payload: { entidad } });

    // Obtengo el token
    const token = getToken(getState());

    // Creo el objeto de actualizacion
    const parche = {
        seleccionados: seleccionados,
        noSeleccionados: noSeleccionados,
        news: news ? (Array.isArray(news) ? news : [news]) : [],
        updSel: updSel,
        updUnSel: updUnSel,
    };

    // accion para el servidor
    fetch(`${config.servidor}:${config.puerto}/api/${api}`, {
        method: 'POST',
        body: JSON.stringify(parche),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        if ( data.code !== undefined) {
            dispatch({
                type: LIST_FAILED,
                payload: { error: data, entidad },
            });
        } else {            
            dispatch({ type: LIST_SAVED, payload: { data, entidad } });
            action && action();
        }        
    })
    .catch((error) => {
            dispatch({ type: LIST_FAILED, payload: { error:{code: 503, message: 'El servicio no esta disponible'}, entidad } });
    });
};

export const filterList = (entidad, campos, data, op, type, reset, nested) => (
    dispatch,
    getState
) => {
    
    dispatch({
        type: LIST_FILTERED,
        payload: { entidad, campos, data, op, type, reset, nested },
    });    
};

export const clearList = (entidad) => ({
    type: LIST_CLEAR,
    payload: { entidad },
});

export const clearFilter = (entidad, filter) => (dispatch, getState) => {
    if (Object.keys(getFiltersList(entidad,getState())).includes(filter))
        dispatch ({
            type: LIST_CLEAR_FILTER,
            payload: { entidad,filter },
        });
}

export const unloadList = (entidad) => (dispatch, getState) => {
    // Aqui segun las politicas de descarga del componente hago el dispatch o no
    dispatch({ type: LIST_UNLOAD, payload: { entidad } });
};

export const setVisiblesList = (entidad, data) => ({
    type: LIST_SETVISIBLES,
    payload: { data, entidad },
});

export const setPageList = (entidad, data) => ({
    type: LIST_SETPAGE,
    payload: { data, entidad },
});

export const firstPageList = (entidad) => ({
    type: LIST_FIRST_PAGE,
    payload: { entidad },
});

export const previousPageList = (entidad) => ({
    type: LIST_PREVIOUS_PAGE,
    payload: { entidad },
});

export const nextPageList = (entidad) => ({
    type: LIST_NEXT_PAGE,
    payload: { entidad },
});

export const lastPageList = (entidad) => ({
    type: LIST_LAST_PAGE,
    payload: { entidad },
});

export const selectAllList = (entidad, data, multiple, action) => (dispatch,getState) => {
    dispatch({type: LIST_SELECT_ALL, payload: { entidad, data }})
    action && action();
};

export const selectOneList = (entidad, data, multiple, action) => (dispatch,getState) => {
    dispatch({type: LIST_SELECT_ONE, payload: { entidad, data, multiple }})
    action && action();
};

export const selectSomeList = (entidad, data, action) => (dispatch,getState) => {
    dispatch({type: LIST_SELECT_SOME, payload: { entidad, data }})
    action && action();
};    

export const reorderList = (entidad, id, start, end) => (
    dispatch,
    getState
) => {
    if (start===end) return;
    
    const idDestino=getList(entidad, getState())[end].id;        
    
    // Reordeno la lista a nivel de cliente
    dispatch({ type: LIST_REORDER, payload: { entidad, id, idDestino } });    
    
    // Actualizo el orden en el servidor    
    dispatch({ type: LIST_UPDATING, payload: { entidad } });
    
    // Obtengo el token
    const token = getToken(getState());
    const api = getListApi(entidad,getState())

    // Creo los parametros de la reordenacion
    const params={
        start:id,
        end:idDestino,
        asc: (end<start)
    }    

    // Hago el Post sin actualizar la lista con los datos devueltos
    fetch(
        `${config.servidor}:${config.puerto}/api/${api}/reorder`,
        {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(
        (response) => {            
            return response.json();
        }
    ).then(
        (data) => {                        
            if (data.code !== undefined) {                
                dispatch({ type: LIST_FAILED, payload: { error: data, entidad } })
            }
            else {
                console.log('ok')                     
                dispatch({ type: LIST_SAVED, payload: { data:null, entidad } })
            }
        }
    ).catch(
        (error) => {            
            dispatch({ type: LIST_FAILED, payload: { error:{code: 503, message: 'El servicio no esta disponible'}, entidad } })
        }
    );
};
