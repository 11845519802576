import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card, CardActions, CardContent,
  Avatar,
  Typography,
  Divider,
  Fab,
  CircularProgress
} from '@material-ui/core';

import { getInitials,getAvatar } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  avatar: {
    marginLeft: 'auto',
    height: 90,
    width: 90,
    flexShrink: 0,
    flexGrow: 0,    
    fontSize: 30
  },
  progress: {
    height: 200,
    width: 200,
    color: theme.palette.warning.main,
  },
  aceptar: {
    color: theme.palette.white,
    backgroundColor: theme.palette.success.dark,
    margin: theme.spacing(1),
  },
  cancelar: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  editar:{    
    margin: theme.spacing(1),
  },
  iconoExtendido:{
    marginRight: theme.spacing(1),
  }
}));

const AvatarPerfil = props => {
  const { className, usuario,  ...rest } = props;
  const classes = useStyles();

  return (
    <Card raised={true} {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h2">
              {usuario.entity.nombre}
            </Typography>
            <Typography color="textSecondary" variant="h6">
              {usuario.entity.empleado ? `Empleado (${usuario.entity.empleado.puesto})` : 'Invitado'}
            </Typography>
          </div>          
          <Avatar className={classes.avatar} src={getAvatar(usuario.entity.id,usuario.entity.avatar)}>
            { getInitials(usuario.entity.nombre===null ? null : usuario.entity.nombre)}                          
            {!usuario.isReady ? <CircularProgress className={classes.progress} disableShrink={true}/> : null}            
          </Avatar>
        </div>        
      </CardContent>
      {/*
      <Divider />
      <CardActions>
        <div className={classes.actions}>
          <Toolbar entidad={usuario} {...rest}/>
        </div>
      </CardActions>*/}
    </Card>
  );
};

export default AvatarPerfil;
