import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    IconButton,
    Tooltip,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';

import * as icons from 'icons'

const useStyles = makeStyles(theme => ({        
}));

const Head = props => {
    const { columns, add, onChange, dfault, height } = props;    

    return (
        <TableHead>
            <TableRow height={height}>                
                {columns.map(
                    (column, index) =>
                        column.column !== '' && (
                            <TableCell 
                                width= {column.width} 
                                key={index} 
                                align={column.align}
                                style={{padding:'0px', paddingRight:'4px'}}
                            >
                                {column.title} 
                            </TableCell>
                        )
                )}

                {(add || onChange) && (
                    <TableCell style={{padding:'0px'}} align={'center'}>
                        <Tooltip title={'Añadir'}>
                            <IconButton                                    
                                size='small'
                                onClick={onChange
                                    ? () => {                                        
                                        onChange(dfault ? dfault() : {})
                                    }
                                    : add
                                }
                            >
                                <icons.AddIcon/>
                            </IconButton>                                    
                        </Tooltip>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};

export default Head;
