import React from 'react';
//import clsx from 'clsx';
import moment from 'moment'
import 'moment/locale/es'
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.warning.main,
    height: 35,
    width: 35,
    fontSize: 10,
  },
  progress: {
    color: theme.palette.warning.main,
  }
}));

const UltimaPicada = (props) => {  
  const { picada, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={classes.root}>
      <CardContent>
        <Grid container direction='column' spacing={2} >
          <Grid item>
            <Typography color="textSecondary" variant="h6">
              Último evento {
                (!picada.isReady || !picada.entity) ? '' :
                  moment(picada.entity.fecha).format('dddd DD [de] MMMM [de] YYYY [a las] HH:mm:ss')
              }
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction='row' alignItems='center' spacing={1}>
              <Grid item>                
                  {
                    !picada.isReady ?
                      <CircularProgress className={classes.progress} disableShrink={true}/> :                        
                      <Avatar className={classes.avatar}>
                        {picada.hasErrors ? 'X' : picada.entity.idEvento}
                      </Avatar>                       
                  }                
              </Grid>
              <Grid item xs>
                <Typography color="textSecondary" variant="h6">
                  {
                    (!picada.isReady || picada.isRefreshing) ? 'Cargando ...' :
                      (picada.hasErrors ? 'No se han encontrado eventos anteriores':
                        picada.entity.evento.descripcion)                      
                  }
                </Typography>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );   
};

export default UltimaPicada;
