import React,{useState} from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import * as icons from 'icons';
import * as hlprs from 'helpers';
import moment from 'moment'


import List from 'features/List';

import { Combo, DataGrid, Text, Switch } from 'components';

const ComboEmpresas = List(Combo, 'options', 'lstEmpresas');

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Contratos = (props) => {
    const { values, editing, adding, handleChange,refresh } = props;
    const classes = useStyles();

    const columns=[        
        {
            column: 'empresa',        
            title: 'Empresa',
            align:'left',
            width: '69%',        
            component:{
                Container: ComboEmpresas,
                props:{
                    api:"empresas",
                    name:"empresa",
                    nameKey:"idEmpresa",                    
                    refresh: refresh,
                    Icon: icons.CompanyIcon,                                        
                    editing:editing,
                    propertyValue:"id",
                    getOptionLabel:(option) =>
                        `${option.nombre}`
                }, 
                propValue:'value',
                eventChange:'onChange'                
            }
        },
        {            
            column: 'fechaInicio',
            title: 'Desde',
            align:'left',
            width: '15%',            
            component:{
                Container: Text,
                props:{                    
                    name:"fechaInicio",                                                            
                    type:"date",                    
                    editing:editing
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
        {            
            column: 'fechaFin',
            title: 'Hasta',
            align:'left',
            width: '15%',            
            component:{
                Container: Text,
                props:{                    
                    name:"fechaFin",                                                            
                    type:"date",                    
                    editing:editing
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
        {            
            column: 'activo',
            title: 'Activo',
            align:'center',            
            width: '1%',
            component:{
                Container: Switch,
                props:{
                    name:"activo",                    
                    editing: false,
                    size:"small"                    
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
    ]

    return (        
        <Grid container spacing={1}>                
            <Grid item xs>
                <DataGrid
                    //rowHeight='30px'
                    //headHeight='300px'                    
                    name='contratos'                        
                    list={values.contratos ? values.contratos : []}
                    columns={columns}
                    editing={editing}
                    onChange= {handleChange}                    
                    dfault={() =>{                
                        return {
                            fechaInicio:hlprs.toDay(),            
                            //activo: false
                        }
                    }}
                    maxHeight={456}
                />            
            </Grid>            
        </Grid>
    );
};

export default Contratos;










