import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import * as icons from 'icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    //paddingTop: 50,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    marginBottom: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 600
  }
}));

const NotFound = (props) => {
  const { history }=props
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <IconButton onClick={() => history.goBack()}>
              <icons.BackIcon/>
          </IconButton>
          
          <div className={classes.content}>
            <Typography variant="h1">
              Vaya. No hemos encontrado lo que has pedido
            </Typography>            
            <img
              alt="En desarrollo"
              className={classes.image}
              src="/recursos/imagenes/imagenes/not_found.jpg"
            />
            <Typography variant="h5">
              La funcionalidad que buscas todavía no esta instalada o está en ello.
              Por favor vuelve ha intentarlo dentro de un tiempo y disculpa las molestias.
            </Typography> 
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
