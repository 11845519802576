import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import {    
    Badge,
    Card,
    CardHeader,
    CardContent,    
    Divider,
    Typography,
    IconButton,
    Tooltip,    
} from '@material-ui/core';

import { DataGrid } from 'components'

import * as icons from 'icons';

import { getAvatar, sumaLista, horasExtra, getInitials, getValueFormatted } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {        
        marginBottom: theme.spacing(1),
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        height: '100%',
        paddingRight: theme.spacing(2),        
        '& > *': {
            marginLeft: theme.spacing(2),
        },
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    }
}));

const getColumns=(type,select) => {
    const columns=[]

    switch(type){
        case 'diary':
            columns.push(
                {
                    column: 'desde',
                    title: 'Inicio',
                    align:'left',
                    type: 'datetime'
                },
                {
                    column: 'hasta',
                    title: 'Hasta',
                    align:'left',
                    type: 'datetime',
                }
            )
            break;        

        case 'weekly':
            columns.push(
                {
                    column: 'desde',
                    title: 'Dia',
                    align:'left',
                    type: 'day',
                    onClick: select,
                }
            )
            break;

        case 'monthly':
            columns.push(
                {
                    column: 'desde',
                    title: 'Semana',
                    align:'left',
                    type: 'week',
                    onClick: select,
                }
            )
            break;
        
        case 'yearly':
            columns.push(
                {
                    column: 'desde',
                    title: 'Mes',
                    align:'left',
                    type: 'month',
                    onClick: select,
                }
            )
            break;
        
        default:
    }

    if (type==='list' || type==='diary')
        columns.push(
            {
                column: ['eventoInicial','descripcion'],
                title: 'Evento Inicial',
                align:'left',
                type: 'text',
            },    
            {
                column: ['eventoFinal','descripcion'],
                title: 'Evento Final',
                align:'left',
                type: 'text',
            }
        )

    columns.push(
        {
            column: 'tiempo',
            title: 'Total',
            align:'right',
            type: 'timeCounter',
        },
        {
            column: 'pausa',
            title: 'Pausa',
            align:'right',
            type: 'timeCounter',
        },
        {
            column: 'activo',
            title: 'Activo',
            align:'right',
            type: 'timeCounter',
        },
        {
            column: 'remunerado',
            title: 'Remunerado',
            align:'right',
            type: 'timeCounter',
        }
    )

    return columns
}

const getExtras=(item,calendario,vacaciones,remunerado) =>{
    const extras= remunerado-(calendario-vacaciones)
    item.extras=extras<=0 ? 0 : extras
    return extras<=0 ? 0 : extras 
}

const Item = (props) => {
    const { type, item, detail, adjust, select, active, ...rest } = props;
    const classes = useStyles();
    const itemJson=JSON.stringify(item);

    const [collapse, setCollapse] = useState(true);
    const [resume, setResume] = useState({});
    
    //const [openDialog, setOpenDialog] = useState([false,false]);    
    
    useEffect(() =>{             
        // Calculo el resumen
        setResume(sumaLista(item.trabajado,['tiempo','activo','pausa','remunerado'],'number'))
        
    },[itemJson])
        
    if (!item) return null
        
    return (
        <Card raised={true} className={classes.root}>
            <CardHeader
                title={<Typography variant='h6'>{item.nombre}</Typography>}
                subheader={
                    <div className={classes.wrapper}>
                        <IconButton 
                                size='small'
                                onClick={() => setCollapse(!collapse)}
                        >                        
                            {collapse ? <icons.ExpandMoreIcon/> : <icons.ExpandLessIcon/>}
                        </IconButton>                    
                        <Typography variant='caption'>{item.periodo}</Typography>
                    </div>
                }
                action={
                    <div>
                        <div className={classes.actions}>
                            <Tooltip title={'Tiempo Total'}>
                                <Typography variant='h6' >
                                    {getValueFormatted('timeCounter',resume.tiempo)} <icons.TimerIcon fontSize='small'/>
                                </Typography>
                            </Tooltip>
                            <Tooltip title={'Tiempo en Pausa'}>
                                <Typography variant='h6' style={{ color: 'darkred' }}>
                                    {getValueFormatted('timeCounter',resume.pausa)} <icons.PauseIcon fontSize='small'/>
                                </Typography>
                            </Tooltip>
                            <Tooltip title={'Tiempo Trabajado'} style={{ color: 'darkgreen' }}>
                                <Typography variant='h6' >
                                    {getValueFormatted('timeCounter',resume.activo)} <icons.WorkingIcon fontSize='small'/>
                                </Typography>
                            </Tooltip>
                            <Tooltip title={'Tiempo Remunerado'} style={{ color: 'blue' }}>
                                <Typography variant='h6' >
                                    {getValueFormatted('timeCounter',resume.remunerado)} <icons.MoneyIcon fontSize='small'/>
                                </Typography>
                            </Tooltip>
                        </div>
                        <div className={classes.actions}>
                            <Tooltip title={'Calendario Laboral'} style={{ color: 'darkgreen' }}>
                                <Typography variant='h6' >
                                    {resume.remunerado && getValueFormatted('timeCounter',item.calendario)} <icons.LaboralIcon fontSize='small'/>
                                </Typography>
                            </Tooltip>
                            <Tooltip title={'Vacaciones'} style={{ color: 'darkred' }}>
                                <Typography variant='h6' >
                                    {resume.remunerado && getValueFormatted('timeCounter',item.vacaciones)} <icons.HolidaysIcon fontSize='small'/>
                                </Typography>
                            </Tooltip>
                            <Tooltip title={'Horas Extra'} style={{ color: 'darkorange' }}>
                                <Typography variant='h6' >
                                    {resume.remunerado && 
                                        getValueFormatted('timeCounter',getExtras(item,item.calendario,item.vacaciones,resume.remunerado))
                                    } <icons.ExtraIcon fontSize='small'/>
                                </Typography>
                            </Tooltip>
                        </div>
                        
                    </div>                                        
                }
                avatar={
                    <Tooltip title={'Ajustar Registro'}>
                        <Badge color='secondary' variant="dot" invisible={!active}>
                            <IconButton                                    
                                size='small'
                                onClick={() => adjust(item)}
                            >
                                <icons.AdjustIcon/>                                                          
                            </IconButton>                                    
                        </Badge>
                    </Tooltip>                    
                }
            />
            {!collapse && <Divider />}
            {!collapse && (
                <CardContent>
                    <DataGrid                        
                        list={item.trabajado}
                        columns={getColumns(type,select)}                        
                    />
                </CardContent>
            )}            
        </Card>
    );
};

export default Item;
