import { combineReducers } from 'redux';
import listReducer, * as listSelectors from 'features/List/reducer';
import entityReducer, * as entitySelectors from 'features/Entity/reducer';
import authReducer, * as authSelectors from 'features/Auth/reducer';

export default combineReducers({    
    list: listReducer,
    entity: entityReducer,
    auth: authReducer    
});

// List
export const getList = (entidad, state) => listSelectors.get(entidad, state.list);
export const getListApi = (entidad, state) => listSelectors.getApi(entidad, state.list);
export const getListParameters = (entidad, state) => listSelectors.getParameters(entidad, state.list);
export const getPagedList = (entidad, state) => listSelectors.getPaged(entidad, state.list);
export const selectedList = (entidad, state) => listSelectors.getSelected(entidad, state.list);
export const getFiltersList = (entidad, state) => listSelectors.getFilters(entidad, state.list);
export const pageList = (entidad, state) => listSelectors.page(entidad, state.list);
export const visiblesList = (entidad, state) => listSelectors.visibles(entidad, state.list);
export const isListInit = (entidad, state) => listSelectors.isInit(entidad, state.list);
export const isListReady = (entidad, state) => listSelectors.isReady(entidad, state.list);
export const isListUpdating = (entidad, state) => listSelectors.isUpdating(entidad, state.list);
export const isListRefreshing = (entidad, state) => listSelectors.isRefreshing(entidad, state.list);
export const hasListErrors = (entidad, state) => listSelectors.hasErrors(entidad, state.list);

// Entity
export const getEntity = (entidad, state) => entitySelectors.get(entidad, state.entity);
export const getEntityInitial = (entidad, state) => entitySelectors.getInitial(entidad, state.entity);
export const getEntityApi = (entidad, state) => entitySelectors.getApi(entidad, state.entity);
export const changesEntity = (entidad, state) => entitySelectors.changes(entidad, state.entity);
export const isEntityInit = (entidad, state) => entitySelectors.isInit(entidad, state.entity);
export const isEntityReady = (entidad, state) => entitySelectors.isReady(entidad, state.entity);
export const isEntityEditable = (entidad, state) => entitySelectors.isEditable(entidad, state.entity);
export const isEntityUpdating = (entidad, state) => entitySelectors.isUpdating(entidad, state.entity);
export const isEntityRefreshing = (entidad, state) => entitySelectors.isRefreshing(entidad, state.entity);
export const hasEntityErrors = (entidad, state) => entitySelectors.hasErrors(entidad, state.entity);

// Autenticacion
export const getToken = (state) => authSelectors.get(state.auth);
export const getAuthUser = (state) => authSelectors.getUser(state.auth);
export const getAuthPermisos = (state) => authSelectors.getPermisos(state.auth);
export const getAuthInicio = (state) => authSelectors.getInicio(state.auth);
export const isAuthExpired = (state) => authSelectors.isExpired(state.auth);
export const isAuthInit = (state) => authSelectors.isInit(state.auth);
export const isAuthReady = (state) => authSelectors.isReady(state.auth);
export const isAuthLogged = (state) => authSelectors.isLogged(state.auth);
export const hasAuthErrors = (state) => authSelectors.hasErrors(state.auth);


