import React from 'react';
import moment from 'moment'

import { makeStyles } from '@material-ui/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import InputMask from 'react-input-mask';

import * as icons from 'icons';
import * as enums from 'enums';
import * as hlprs from 'helpers';

import List from 'features/List';

import { Combo, Text } from 'components';

import DetailSocio from 'views/Socios/Detalle'

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom:'15px',        
    },
}));

const ComboSocios = List(Combo, 'options', 'socios');

const Detalle = (props) => {
    const { auth, values, editing, adding, handleChange,refresh } = props;
    const classes = useStyles();

    return (
        <form autoComplete="on" noValidate>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Text                        
                        name="poligono"
                        label="Polígono"
                        helperText="Codigo catastral poligono"
                        value={values.poligono}
                        type="number"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text                        
                        name="parcela"
                        label="Parcela"
                        helperText="Codigo catastral parcela"
                        value={values.parcela}
                        type="number"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text                        
                        name="recinto"
                        label="Recinto"
                        helperText="Codigo catastral recinto"
                        value={values.recinto}
                        type="number"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <icons.LocationIcon/>
                </Grid>
                <Grid item xs={2}>
                    <Text                        
                        name="superficie"
                        label="Superficie"
                        helperText="Superficie en hectareas"
                        value={values.superficie}
                        type="number"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>                
            </Grid>            
            <Grid container spacing={1}>
                <Grid item xs>
                    <ComboSocios
                        api="socios"
                        name="socios"
                        nameKey="idSocio"
                        label="Socio"
                        Icon={icons.PartnerIcon}
                        helperText="Seleccione un Socio"
                        value={values.socio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) => option.nombre}                        
                        relacionado={{
                            Detail: DetailSocio,
                            resume:['nombre'],                            
                            dfault:{
                                nombre:values.nombre
                            }                       
                        }}
                        refresh={refresh}                        
                    />
                </Grid> 
            </Grid>            
            <Grid container spacing={1}>
            <Grid item xs={2}>
                    <Text                        
                        name="provincia"
                        label="Provincia"
                        helperText="Código Provincia"
                        value={values.provincia}
                        type="number"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text                        
                        name="municipio"
                        label="Municipio"
                        helperText="Codigo Municipio"
                        value={values.municipio}
                        type="number"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text                        
                        name="agregado"
                        label="Agregado"
                        helperText="Codigo agregado"
                        value={values.agregado}
                        type="number"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text                        
                        name="zona"
                        label="Zona"
                        helperText="Codigo zona"
                        value={values.zona}
                        type="number"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Text                        
                        name="catastro"
                        label="Catastro"
                        helperText="Código Catastral"
                        value={values.catastro}
                        type="text"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>            
                <Grid item xs>
                    <Text                        
                        name="resumen"
                        label="Resumen"
                        helperText="Descrpcion de la parcela"
                        value={values.resumen}
                        type="text"
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>            
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text                        
                        name="detalles"
                        label="Detalles"
                        helperText="Información detallada de la parcela"
                        value={values.detalles}
                        type="text"
                        multiline
                        rows={8}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>  
        </form>
    );
};

export default Detalle;










        
            