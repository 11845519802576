import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid, Typography , Divider} from '@material-ui/core';

import * as icons from 'icons';
import * as enums from 'enums';
//import * as hlprs from 'helpers';

import List from 'features/List';

import { Text, Tabs, Select, Switch } from 'components';

import Estado from './Tabs/Estado';
//import Explotacion from './Tabs/Explotacion'
//import Documentacion from './Tabs/Documentacion'

import DetailUsuario from 'views/Usuarios/Detalle';

//const ComboHorarios = List(Combo, 'options', 'lstHorarios');

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Detalle = (props) => {
    const { auth, values, editing, adding, handleChange, refresh } = props;
    const classes = useStyles();

    return (
        <form autoComplete='on' noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        name='codigo'
                        label='Código'
                        helperText='Identificador del socio'
                        value={values.codigo}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        name='sector'
                        label='Sector'
                        helperText='Sector en el que trabaja'
                        value={values.sector}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.sector}
                    />
                </Grid>
                <Grid item xs>
                    <Select
                        name='tipo'
                        label='Tipo'
                        helperText='Tipo de dedicación'
                        value={values.tipo}
                        onChange={handleChange}
                        editing={editing}
                        options={enums.tipoSocio}
                    />
                </Grid>
                <Grid item xs>
                    <Switch
                        value={values.personafisica}
                        onChange={handleChange}
                        name='personafisica'
                        label='Persona Física'
                        editing={editing}
                        color='DarkBlue'
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <Text
                        name='nombre'
                        label='Nombre'
                        helperText='Nombre del socio'
                        value={values.nombre}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Text
                        name='nif'
                        label='NIF'
                        helperText='NIF del Socio'
                        value={values.nif}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} xs>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <Typography variant='caption'>
                                Dirección Postal
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Text
                                name='direccion'
                                label='Dirección'
                                helperText='Calle, numero, piso, ...'
                                value={values.direccion}
                                onChange={handleChange}
                                editing={editing}
                                multiline
                                rows={2}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Text
                                        name='cp'
                                        label='C.P.'
                                        helperText='Código Postal'
                                        value={values.cp}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        name='poblacion'
                                        label='Pblación'
                                        helperText='Población del socio'
                                        value={values.poblacion}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Text
                                        name='provincia'
                                        label='Provincia'
                                        helperText='Provincia del socio'
                                        value={values.provincia}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Text
                                        name='pais'
                                        label='Pais'
                                        helperText='Nacionalidad del socio'
                                        value={values.pais}
                                        onChange={handleChange}
                                        editing={editing}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <Typography variant='caption'>
                                Contacto
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs>
                            <Text
                                name='telefono'
                                label='Teléfono'
                                helperText='Telefono del socio'
                                value={values.telefono}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item xs>
                            <Text
                                name='email'
                                label='email'
                                helperText='Correo electronico del socio'
                                value={values.email}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid containerspacing={1}>
                <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <Text
                                name="direccion"
                                label="Dirección"
                                helperText="Calle, numero, piso, ..."
                                value={values.direccion}
                                onChange={handleChange}
                                editing={editing}
                                multiline
                                rows={3}
                            />
                        </Grid>
                        <Grid item>
                            <Text
                                name="cp"
                                label="C.P."
                                helperText="Código Postal"
                                value={values.cp}
                                onChange={handleChange}
                                editing={editing}
                            />
                            <Text
                                name="poblacion"
                                label="Pblación"
                                helperText="Población del socio"
                                value={values.poblacion}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                        <Grid item>
                            <Text
                                name="provincia"
                                label="Provincia"
                                helperText="Provincia del socio"
                                value={values.provincia}
                                onChange={handleChange}
                                editing={editing}
                            />
                            <Text
                                name="pais"
                                label="Pais"
                                helperText="Nacionalidad del socio"
                                value={values.pais}
                                onChange={handleChange}
                                editing={editing}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>                        
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <Text
                                name="telefono"
                                label="Teléfono"
                                helperText="Telefono del socio"
                                value={values.telefono}
                                onChange={handleChange}
                                editing={editing}
                            />   
                        </Grid>
                        <Grid item>
                            <Text
                                name="email"
                                label="email"
                                helperText="Correo electronico del socio"
                                value={values.email}
                                onChange={handleChange}
                                editing={editing}
                            />   
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}

            <Tabs
                tabs={[
                    {
                        label: 'Estado',
                        icon: icons.LaboralIcon,
                        component: <Estado {...props} />,
                        disabled: false,
                    },                        
                    {
                        label: 'Explotacion',
                        icon: icons.ParcelIcon,
                        component: null,
                        disabled:false
                    },
                    {
                        label: 'Expedientes',
                        icon: icons.ProceedingsIcon,
                        component: null,
                        disabled:false
                    },
                    {
                        label: 'Documentacion',
                        icon: icons.DocumentIcon,
                        component: null,
                        disabled:false
                    },
                ]}
            />
        </form>
    );
};

export default Detalle;
