import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Typography,IconButton } from '@material-ui/core';
import {DataGrid} from 'components'
import * as icons from 'icons';

const useStyles = makeStyles((theme) => ({
    wrapper: {    
        display: 'flex',    
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&>*': {
            marginRight: theme.spacing(2),
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,        
        boxShadow: theme.shadows[20],
        padding: theme.spacing(2, 4, 3),
    },
}));

const PaperComponent = (props) => {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }

const SelectModal = (props) => {    
    const { selector, open, close}=props
    const classes = useStyles('red');
    
    const selectItem = (item) =>{
        close()
        selector.action(item.key)
    }

    if (!open) return(null)    
    
    return ( 
        <Dialog                        
            open={open}            
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullWidth={true}
            maxWidth='md'
        > 
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <div className={classes.wrapper}>
                    {close ? (
                        <IconButton size='small' onClick={close}>
                            <icons.CloseIcon />
                        </IconButton>
                    ) : null}
                    <Typography variant='h4'>
                        {selector.title}
                    </Typography>                    
                </div>    
            </DialogTitle>           
            <DialogContent className={classes.paper}>
                <DataGrid                        
                    list={selector.list}
                    columns={selector.columns}
                    select={selectItem}                        
                />
            </DialogContent>        
      </Dialog>
    );
};

export default SelectModal;

