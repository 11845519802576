import React from 'react';

import { Crud } from 'components/Crud';
import Detalle from './Detalle';
import List from 'features/List';
import Auth from 'features/Auth';

const columns = proyectos => [    
    {
        column: '',
        title: '',
        type: 'text',
        titleFilter: 'Buscar',
        filter: (valor) =>
            proyectos.filter(
                [
                    'id',
                    'codigo',
                    'resumen',
                    'prioridad',                    
                    'detalles',                    
                    ['expediente','codigo'],
                    ['expediente','resumen'],
                    ['socio','nombre'],
                    ['equipo','nombre']
                ],
                valor,
                '',
                'text'
            )
    },
    {
        column: 'codigo',
        title: 'Codigo',
        type: 'text'
    },
    {
        column: 'fecha',
        title: 'Fecha',
        type: 'date',
        titleFilter: 'Fecha (desde)',
        filter: (valor) =>
            proyectos.filter(['fecha'], valor, '>=', 'date')
    },
    {
        column: '',
        title: '',
        type: 'Date',
        titleFilter: 'Fecha (hasta)',
        filter: (valor) =>
            proyectos.filter(['fecha'], valor, '<=', 'date')
    },
    {
        column: 'resumen',
        title: 'Resumen',
        type: 'text',
    },        
    {
        column: '',
        title: '',
        type: 'text',
        titleFilter: 'Estado',
        filter: (valor) =>
            proyectos.filter(['estado'], valor, '', 'text')
    },    
    {
        column: '',
        title: '',
        type: 'text',
        titleFilter: 'Validacion',
        filter: (valor) =>
            proyectos.filter(['validacion'], valor, '', 'text')
    },
];

const CrudContainer = List(Auth(Crud,'auth'), 'entities', 'tareas');

const Tareas = props => {
    const { ...rest } = props;

    return (
        <CrudContainer
            title="Incidencias/Tareas"
            columns={columns}
            id="id"
            resume={['codigo']}
            api="tareas/"
            Detalle={Detalle}
            {...rest}
        />
    );
};

export default Tareas;
