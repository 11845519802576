import React,{useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import { Avatar, Chip, Typography } from '@material-ui/core';
import * as hlprs from 'helpers';

import { Detail, Element } from 'components/Crud';

import Auth from 'features/Auth'
import Entity from 'features/Entity'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginRight: '4px'
        //padding:'4px',
        //display: 'block',
        //marginTop:'13px',
        //width: '100%',
        //height: '100%',        
        /*
        '& > *': {            
            marginBottom: '4px',
        },*/
    },
    hlpText:{
        marginLeft:'8px'
    }
}));                   

const Tag = props => {
    const classes = useStyles();
    const {
        id,
        avatar,
        hidden,
        helperText,        
        value,
        relacionado,
        refresh,
        ...rest
    } = props;   
    
    const [detail, setDetail] = useState(null);

    const openDetail=() =>{                
        const key=Date.now()

        setDetail({
            key: key,
            id: id,
            title: relacionado.title ? relacionado.title : helperText,
            resume: relacionado.resume,
            api: relacionado.api+'/' + id,
            container: Entity(Auth(Element,'auth'), 'entity', key),
            element: relacionado.Detail,
        })        
    }

    const editDetail = (key, cancel, id, isEditable, entity) => {        
        if (!isEditable) {
            if (!cancel) {                
                // Refresco el elemento al que pertenezco y su padre
                refresh && refresh(false)

                // Cierro el dialogo
                setDetail(null)
            }
        }
    };

    if (hidden) return null
    
    return ([
        <div className={classes.wrapper}>
            <Chip
                {...rest}                
                label={value}
                avatar={avatar &&
                    <Avatar src={avatar}>
                        {hlprs.getInitials(value)}
                    </Avatar>
                }
                onClick={relacionado && openDetail}
            />
            <br/>
            {
                helperText &&
                <Typography className={classes.hlpText} variant="caption">
                    {helperText}
                </Typography>
            }            
        </div>,
        <Detail 
            item={detail}
            open={detail !== null}
            close={() => setDetail(null)}
            edit={editDetail}
            readOnly={relacionado && relacionado.readOnly}            
        />
    ]);
};

export default Tag;
