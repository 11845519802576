import uuid from 'uuid/v1';

export default [
  {
    id: '2015-25',
    ref: '2015-25',    
    customer: {
      name: 'Jose Soriano Rodenas'
    },
    createdAt: '2015-11-10',
    status: 'resuelto'
  },
  {
    id: '2021-19',
    ref: '2021-19',    
    customer: {
      name: 'Paco Bravo Lozano'
    },
    createdAt: '2021-08-10',
    status: 'pendiente'
  },
  
];
