import React from 'react';

import { Crud } from 'components/Crud';
import Detalle from './Detalle';
import List from 'features/List';
import Auth from 'features/Auth';

const columns = socios => [    
    {
        column: '',
        title: '',
        type: 'text',
        titleFilter: 'Buscar',
        filter: (valor) =>
            socios.filter(
                [
                    'id',
                    'nombre',
                    'codigo',
                    'nif',
                    'sector'
                ],
                valor,
                '',
                'text'
            )
    },
    {
        column: 'codigo',
        title: 'Codigo',
        type: 'text'
    },
    {
        column: 'nombre',
        title: 'Nombre',
        type: 'text'
    },
    {
        column: 'nif',
        title: 'NIF',
        type: 'text'
    },    
    {
        column: 'telefono',
        title: 'Telefono',
        type: 'text'
    },
    {
        column: 'email',
        title: 'email',
        type: 'text'
    }
];

const CrudContainer = List(Auth(Crud,'auth'), 'entities', 'socios');

const Socios = props => {
    const { ...rest } = props;

    return (
        <CrudContainer
            title="Socios"
            columns={columns}
            id="id"
            resume={['nombre']}
            api="socios/"
            Detalle={Detalle}
            {...rest}
        />
    );
};

export default Socios;
