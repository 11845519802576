import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { Link } from 'react-router-dom';

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  IconButton
} from '@material-ui/core';

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className, users, ...rest } = props;  
  const classes = useStyles();
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={users.allSelected}
                      indeterminate={!users.allSelected && users.itemsSelected !== 0}
                      color="primary"                      
                      onChange={event=>users.selectAll(event.target.checked)}
                    />
                  </TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Correo Electrónico</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Edad</TableCell>
                  <TableCell>Fecha de Nacimiento</TableCell>                  
                </TableRow>
              </TableHead>
              <TableBody>
                {users.listPaged.map(user => (
                  <TableRow                    
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={users.selected.indexOf(user.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={users.selected.indexOf(user.id) !== -1}
                        color="primary"
                        onChange={event=>users.select(user.id,true)}
                        
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          component={Link} to={'/account/' + user.id}
                          className={classes.avatar}
                          src={user.avatarUrl}
                        >
                          {getInitials(user.nombre===null ? '' : user.nombre)}
                        </Avatar>
                        <Typography variant="body1">{user.nombre}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.provider}
                    </TableCell>
                    <TableCell>{user.edad}</TableCell>
                    <TableCell>{user.fnacimiento}</TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={users.list.length}
          onChangePage={(event,page) => users.setPage(page)}
          onChangeRowsPerPage={event => users.setVisibles(event.target.value)}
          page={users.page}
          rowsPerPage={users.visibles}
          rowsPerPageOptions={[2, 5, 10, 25]}
          labelRowsPerPage="Filas por Página"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string
};
export default UsersTable;
