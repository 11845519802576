import React,{useState} from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid,Divider } from '@material-ui/core';

import * as icons from 'icons';
import { getAvatar} from 'helpers';

import List from 'features/List';
import { Button,Combo, Tag, Text } from 'components';

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const ComboTareas = List(Combo, 'options', 'tareas');

const Detalle = (props) => {
    const { values, setValues, save } = props;
    const classes = useStyles();

    const [changes,setChanges]=useState(!values.id ? values : {})
    
    const handleChange = (chngs,event) => {
        if (true) {
            let newValues = values;
            let newChanges = changes;

            chngs.map(change => {
                if (change.value !==null && typeof(change.value)==='object') {                                    
                    if (Array.isArray(change.value.value)){
                        newValues = {
                            ...newValues,
                            [change.field]: change.value.value,
                        };
                        newChanges = {
                            ...newChanges,
                            [change.field]: change.value.value,
                        };
                    }
                    else {                    
                        newValues = {
                            ...newValues,
                            [change.value.nameKey]: change.value.value==='' ? null : change.value.value,
                            [change.value.name]: change.value.selected,
                        };
                        newChanges = {
                            ...newChanges,
                            [change.value.nameKey]: change.value.value==='' ? null : change.value.value,
                            [change.value.name]: change.value.selected,
                        };
                    }
                } else {                
                    newValues = {
                        ...newValues,
                        [change.field]: change.value==='' ? null : change.value,
                    };
                    newChanges = {
                        ...newChanges,
                        [change.field]: change.value==='' ? null : change.value,
                    };
                }
            })
            
            setValues(newValues);            
            setChanges(newChanges);            
        }
    };

    const formOk=() =>{
        let ok = (Object.keys(changes).length !== 0)

        ok=(ok && values.fecha!==null)

        ok=(ok && values.idEvento!==null)

        return ok
    }

    return (
        <form autoComplete="on" noValidate>
            <Grid container spacing={1}>
                <Grid item xs>
                    <div className={classes.wrapper}>                        
                        <Tag
                            //variant="outlined"
                            color="secondary"                            
                            value={values.usuario && values.usuario.nombre}
                            helperText="Realizador de la Tarea"
                            avatar={
                                values.usuario &&
                                getAvatar(
                                    values.idUsuario,
                                    values.usuario.avatar
                                )
                            }
                        />
                        <Button                        
                            toolTipText="Guardar los cambios"
                            disabled={!formOk()}                        
                            onClick={event => save(values.id,changes)}
                            counter={Object.keys(changes).length}
                            //busy={updating}
                            icon={<icons.SaveIcon/>}
                            float={true}
                        />
                    </div>
                </Grid>         
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs='12'>
                    <Divider/>
                </Grid>
                <Grid item xs='12'>
                    
                </Grid>
            </Grid>
            <Grid container spacing={1}>                
                <Grid item xs="4">
                    <Text
                        required
                        name="fechaInicial"
                        label="Inicio"
                        helperText="Tarea activa desde"
                        value={moment(values.fechaInicial).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                        type="datetime-local"
                        onChange={handleChange}
                        editing={true}
                    />
                </Grid>
                <Grid item xs="4">
                    <Text
                        required
                        name="fechaFinal"
                        label="Fin"
                        helperText="Tarea activa hasta"
                        value={moment(values.fechaFinal).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                        type="datetime-local"
                        onChange={handleChange}
                        editing={true}
                    />
                </Grid>
                <Grid item xs="12">
                    <ComboTareas
                        required
                        api={"tareas/usuario?user="+values.idUsuario}
                        name="tarea"
                        nameKey="idTarea"
                        label="Tarea"
                        Icon={icons.TaskIcon}
                        helperText="Seleccione una Tarea"
                        value={values.tarea}
                        onChange={handleChange}
                        editing={true}
                        propertyValue="idTarea"
                        getOptionLabel={(option) =>{
                            if (option.tarea)                        
                                return `[${option.tarea.codigo}]-${option.tarea.resumen}`
                            return `[${option.codigo}]-${option.resumen}`
                        }}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
