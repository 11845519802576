import React, { useState, useEffect } from 'react';

import List from 'features/List';

import { DataGrid, Modal } from 'components'

import * as helpers from 'helpers';

import Detalle from './Detalle'

const columns=[
    {
        column: 'fechaInicial',
        title: 'Inicio',
        align:'left',
        type: 'datetime'
    },
    {
        column: 'fechaFinal',
        title: 'Fin',
        align:'left',
        type: 'datetime'
    },
    {
        column: ['tarea','codigo'],        
        title: 'Tarea',
        align:'left',                
    },
    {
        column: ['tarea','resumen'],
        title: 'Resumen',
        align:'left',        
    },
]

const TiemposTareas = (props) => {
    const { tiemposTareas, user, range } = props;    
    
    const [openModal, setOpenModal] = useState(false);    
    const [row, setRow] = useState({});    
    
    useEffect(() => {                
        tiemposTareas.load(`tiempostarea/usuario?user=${user.id}&${range}`)        

        return () =>{
            tiemposTareas.unload()            
        }

    }, []);

    useEffect(() => {                
        tiemposTareas.refresh(false,`tiempostarea/usuario?user=${user.id}&${range}`)         

    }, [user.id,range]);
    
    const editRow=(fila) =>{        
        setRow(fila)
        setOpenModal(true)
    }

    const addRow=() =>{                
        const fila={                        
            idUsuario: user.id,
            usuario:{
                nombre: user.nombre
            },            
        }
        
        editRow(fila)        
    }

    const updateRow=(id,changes) =>{                   
        if (id)
            tiemposTareas.select(id,false,
                () => tiemposTareas.update(undefined,changes,undefined)
            )
        else
            tiemposTareas.update(changes,undefined,undefined)
        
        // Cierro el modal
        setOpenModal(false)
    }

    const deleteRow=(fila) =>{        
        tiemposTareas.delete(fila)
    }

    return ([
        <DataGrid                        
            list={tiemposTareas.list}
            columns={columns}
            edit={editRow}
            add={addRow}                        
            erase={deleteRow}
            maxHeight={456}
        />,        
        <Modal
            title='Editar'
            open={openModal}
            close={() => setOpenModal(false)}
        >   
            <Detalle 
                //auth={auth}
                values={row}
                setValues={setRow}
                save={updateRow}
            />                
        </Modal>        
    ]);
};

export const TiemposTareasContainer = 
    List(TiemposTareas,'tiemposTareas','tiemposTareas')

export default TiemposTareasContainer;
