import * as hlp from 'helpers'
import { 
    LIST_INIT,
    LIST_SUCCESS,
    LIST_FAILED,
    LIST_CLEAR,
    LIST_REFRESH,
    LIST_REFRESHING,
    LIST_UNLOAD,
    LIST_UPDATING,
    LIST_SAVED,
    LIST_DELETED,    
    LIST_FILTERED,
    LIST_CLEAR_FILTER,
    LIST_REORDER,
    LIST_SETVISIBLES,
    LIST_SETPAGE,
    LIST_FIRST_PAGE,
    LIST_PREVIOUS_PAGE,
    LIST_NEXT_PAGE,
    LIST_LAST_PAGE,
    LIST_SELECT_ONE,
    LIST_SELECT_SOME,
    LIST_SELECT_ALL
 } from 'constants.js';

const initList = (state, action) => {
    const {entidad, api} = action.payload;    
    const newApi=api //api.split('?')[0]
    const newParameters=api.split('?')[1] ? api.split('?')[1] :''   

    return {...state,
        [entidad]:
        {
            api: newApi,
            parameters:newParameters,
            isInit:true,
            isReady: false,
            isRefreshing: false,
            isUpdating: false,
            errors: null,
            page: 0,
            visibles: 10,
            original:[],
            list: [],
            paginada: [],
            filters:{},
            selected:[]
        }
    };
};

const successList = (state, action) => {
    const {entidad,data} = action.payload;

    return {...state,
        [entidad]: 
        {
            ...state[entidad],
            isInit:false,
            isReady:true,
            isRefreshing: false,
            isUpdating: false,            
            errors: null,
            page: 0,
            visibles: 10, 
            original:data,           
            list:data,
            paginada:data.slice(0,10),
            filters:{},
            selected:[]
        }
    };
};

const failedList = (state, action) => {
    const {entidad,error} = action.payload;    

    return {
        ...state,
        [entidad]:
        {
            ...state[entidad],
            isInit:false,
            isReady:true,
            isRefreshing: false,
            isUpdating: false,
            errors: error
        }
    };
}

const setVisibles = (state, action) => {
    const {entidad,data} = action.payload;    
    
    const total=state[entidad].list.length;
    const visibles=data;    
    const maxPage= total % visibles===0 ? (total/visibles)-1 : Math.trunc(total / visibles)
    const page=state[entidad].page>maxPage ? maxPage : state[entidad].page
    const paginada=state[entidad].list.slice(page*visibles,(page*visibles)+visibles)
    
    return {
        ...state,
        [entidad]:
        {
            ...state[entidad],
            visibles:visibles,
            page:page,
            paginada:paginada,
        }
    };
}

const setPage = (state, action) => {
    const {entidad , data} = action.payload;    
    const visibles = state[entidad].visibles
    const page = data
    const paginada=state[entidad].list.slice(page*visibles,(page*visibles)+visibles)
    
    return {...state,
        [entidad]:
        {...state[entidad],
            page: page,
            paginada: paginada,
        }
    };
}

const firstPage = (state, action) => {
    const {entidad} = action.payload;
    const visibles = state[entidad].visibles  
    const page = 0
    const paginada=state[entidad].list.slice(page*visibles,(page*visibles)+visibles)  

    return {...state,
        [entidad]:
        {...state[entidad],
            page: page,
            paginada: paginada
        }
    };
}

const previousPage = (state, action) => {
    const {entidad} = action.payload;
    const newPage = state[entidad].page-1;   
    const visibles = state[entidad].visibles    
    const page = newPage<0 ? 0 : newPage
    const paginada=state[entidad].list.slice(page*visibles,(page*visibles)+visibles)

    return {...state,
        [entidad]:
        {...state[entidad],
            page: page,
            paginada: paginada
        }
    };
}

const nextPage = (state, action) => {
    const {entidad} = action.payload;
    const total=state[entidad].list.length;
    const visibles=state[entidad].visibles;
    const newPage=state[entidad].page+1;
    const maxPage= total % visibles===0 ? (total/visibles)-1 : Math.trunc(total / visibles)
    const page = newPage>maxPage ? maxPage : newPage
    const paginada=state[entidad].list.slice(page*visibles,(page*visibles)+visibles)  

    return {...state,
        [entidad]:
        {...state[entidad],
            page: page,
            paginada: paginada
        }
    };
}

const lastPage = (state, action) => {
    const {entidad} = action.payload;
    const total=state[entidad].list.length;
    const visibles=state[entidad].visibles;    
    const maxPage= total % visibles===0 ? (total/visibles)-1 : Math.trunc(total / visibles)   
    const page = maxPage
    const paginada=state[entidad].list.slice(page*visibles,(page*visibles)+visibles)  

    return {...state,
        [entidad]:
        {...state[entidad],
            page: maxPage,
            paginada: paginada
        }
    };
}

const refreshingList = (state, action) => {    
    const {entidad} = action.payload;

    return {...state,
        [entidad]: 
        {...state[entidad],            
            isReady:true,            
            isRefreshing: true,
            errors: null
        }
    };
};

const refreshList = (state, action) => {
    const {entidad, data, api, inicializa} = action.payload;
    
    if(!state[entidad]) return state;   

    const newApi = api === undefined ? state[entidad].api : api //api.split('?')[0]
    const newParameters=api === undefined ? state[entidad].parameter 
                        : (api.split('?')[1] ? api.split('?')[1] :'')
    const original = state[entidad].original;
    const list = state[entidad].list;
    let newSelected=state[entidad].selected;
    const newFilters=inicializa ? {} : state[entidad].filters;
    let newOriginal=[]
    let newList=[]        
    
    if (Array.isArray(data)){
        newOriginal=data
        newSelected=[]

        // Aplicamos los filtros
        newList=filtra(newOriginal,newFilters)
    }
    else {
        newOriginal = original.map(listItem => listItem.id === data.id ? {...listItem, ...data} : listItem);    
        newList = list.map(listItem => listItem.id === data.id ? {...listItem, ...data} : listItem);
    }
        
    const paginada= newList.slice(state[entidad].page*state[entidad].visibles,(state[entidad].page*state[entidad].visibles)+state[entidad].visibles)    
    
    return {
        ...state,
        [entidad]:
        {
            ...state[entidad],
            api: newApi,
            parameters:newParameters,
            isRefreshing: false,
            original:newOriginal,
            list: newList,
            paginada: paginada,
            selected: newSelected,
            filters: newFilters
        }
    };
};

const unloadList = (state, action) => {
    const {entidad} = action.payload;

    const newState={...state};
    
    Reflect.deleteProperty(newState,entidad);

    return newState;
};

const selectAll = (state, action) => {
    const {entidad, data} = action.payload;
    let newSelected=[];
    
    if(!state[entidad]) return state;

    if (data) newSelected = state[entidad].list.map(item => item.id);
    
    return {...state,
        [entidad]:
        {
            ...state[entidad],
            selected: newSelected
        }
    };
};

const selectOne = (state, action) => {
    const {entidad, data, multiple} = action.payload;
    let newSelected=[];    
    
    if(!state[entidad]) return state;

    if (multiple) newSelected=state[entidad].selected;
    
    const index=newSelected.indexOf(data);    
        
    if (index===-1)
        newSelected=newSelected.concat(data);        
    else    
        newSelected.splice(index,1);    

    return {...state,
        [entidad]:
        {
            ...state[entidad],
            selected: newSelected
        }
    };
};

const selectSome = (state, action) => {
    const {entidad, data} = action.payload;
    const newSelected=data;
    
    if(!state[entidad]) return state;

    return {...state,
        [entidad]:
        {
            ...state[entidad],
            selected: newSelected
        }
    };
};

const clearList = (state, action) => {
    const {entidad} = action.payload;

    return {...state,
        [entidad]:
        {...state[entidad],
            isReady: true,
            errors: null,            
        }
    };
};

const updatingList = (state, action) => {    
    const {entidad} = action.payload;

    return {...state,
        [entidad]: 
        {...state[entidad],            
            isReady:true,
            isUpdating: true,
            errors: null
        }
    };
};

const savedList = (state, action) => {
    const {entidad, data} = action.payload;    

    if (data===null){        
        return {...state,
            [entidad]: 
            {...state[entidad],            
                isReady:true,
                isUpdating: false,            
                errors: null
            }
        };
    }
    else{
        // Aplicamos los filtros
        const newList=filtra(data,state[entidad].filters)
        const paginada= newList.slice(state[entidad].page*state[entidad].visibles,(state[entidad].page*state[entidad].visibles)+state[entidad].visibles)   

        // Hay que tener en cuenta si esta filtrada la lista
        return {...state,
            [entidad]: 
            {...state[entidad],            
                isReady:true,
                isUpdating: false,            
                errors: null,            
                original:data,           
                list:newList,
                paginada:paginada
            }
        };
    }
};

const deletedList = (state, action) => {
    const {entidad, seleccionados} = action.payload;
    //const seleccionados= state[entidad].selected
    
    const newOriginal=state[entidad].original.filter(item=>(seleccionados.indexOf(item.id)===-1));
    const newList=state[entidad].list.filter(item=>(seleccionados.indexOf(item.id)===-1));
    const paginada= newList.slice(state[entidad].page*state[entidad].visibles,(state[entidad].page*state[entidad].visibles)+state[entidad].visibles)
            
    return {...state,
        [entidad]: 
        {...state[entidad],            
            isReady:true,
            isUpdating: false,
            original:newOriginal,
            list:newList,
            paginada:paginada,
            selected:[],
            errors: null
        }
    };
};

const clearFilter = (state, action) => {    
    const {entidad, filter} = action.payload;        
    
    let newFiltro={...state[entidad].filters};    
    
    Reflect.deleteProperty(newFiltro,filter);            

    const newList=filtra(state[entidad].original,newFiltro)
    const paginada= newList.slice(state[entidad].page*state[entidad].visibles,(state[entidad].page*state[entidad].visibles)+state[entidad].visibles)    
        
    return {...state,
        [entidad]: 
        {...state[entidad],            
            isReady:true,
            filters: newFiltro,            
            list: newList,
            paginada:paginada,
            selected:[],            
            errors: null
        }
    };
    
};

const filteredList = (state, action) => {
    const {entidad, campos,  data, op, type, reset, nested} = action.payload;
    
    const nombreFiltro=campos+op;    
    let newFiltro=reset ? {} : {...state[entidad].filters};        
    
    if (data==='')
    {
        Reflect.deleteProperty(newFiltro,nombreFiltro);        
    }
    else
    {   
        const filtro=campos.map(item => {return {campo:item, valor:data, op:op, tipo:type, nested:nested} })
        newFiltro={...newFiltro,[nombreFiltro]:filtro};
    }

    const newList=filtra(state[entidad].original,newFiltro)
    const paginada= newList.slice(state[entidad].page*state[entidad].visibles,(state[entidad].page*state[entidad].visibles)+state[entidad].visibles)
    
    return {...state,
        [entidad]: 
        {...state[entidad],            
            isReady:true,
            filters: newFiltro,            
            list: newList,
            paginada:paginada,
            selected:[],            
            errors: null
        }
    };
};

const filtra=(lista,filtro,nested) =>{
    const lst=hlp.clone(lista).filter(item=>
        {            
            let mostrar=true;
            const nestedFields=[]
            
            for (const filtroAnd in filtro)
            {   
                let valor=false;                

                filtro[filtroAnd].forEach(filtroOr => {
                    let campo=filtroOr.campo

                    nested && !filtroOr.nested && (campo='')
                    
                    if (Array.isArray(campo)){                        
                        if (filtroOr.nested){ 
                            if (!nested) 
                                !nestedFields.includes(filtroOr.campo[0]) && nestedFields.push(filtroOr.campo[0])
                            else
                                campo=filtroOr.campo[1]
                        }
                    }

                    if (campo!=='')
                        valor= valor || 
                            hlp.compare(hlp.getValue(item,campo)
                                ,filtroOr.valor,filtroOr.op,filtroOr.tipo
                            )
                    else valor= valor || true
                })                        
                
                mostrar=mostrar && valor;
            }
            
            // Filtramos los anidados si los hay            
            mostrar && nestedFields.forEach(n => {                
                item[n]=filtra(item[n],filtro,true)
            })

            return mostrar;
        }
    )

    return lst;
}

const reorderList = (state, action) => {
    const {entidad, id, idDestino} = action.payload;    

    const newOriginal=Array.from(state[entidad].original)

    const start=newOriginal.findIndex(item => item.id===id)
    const end=newOriginal.findIndex(item => item.id===idDestino)    
    
    const [removed]=newOriginal.splice(start,1);        
    newOriginal.splice(end,0,removed);
    
    const newList=filtra(newOriginal,state[entidad].filters);
    const newPaginada= newList.slice(state[entidad].page*state[entidad].visibles,(state[entidad].page*state[entidad].visibles)+state[entidad].visibles)
    
    return {...state,
        [entidad]: 
        {...state[entidad],                        
            list: newList,
            paginada:newPaginada,
            original:newOriginal            
        }
    };
};

// Mapeo las acciones con su implementacion
const actions = {
    [LIST_INIT]: initList,
    [LIST_SUCCESS]: successList,
    [LIST_FAILED]: failedList,
    [LIST_CLEAR]: clearList,
    [LIST_REFRESHING]: refreshingList,
    [LIST_REFRESH]: refreshList,
    [LIST_UNLOAD]: unloadList,
    [LIST_UPDATING]: updatingList,
    [LIST_SAVED]: savedList,
    [LIST_DELETED]: deletedList,
    [LIST_FILTERED]: filteredList,
    [LIST_CLEAR_FILTER]: clearFilter,
    [LIST_REORDER]: reorderList,
    [LIST_SELECT_ONE]: selectOne,
    [LIST_SELECT_SOME]: selectSome,
    [LIST_SELECT_ALL]: selectAll,
    [LIST_SETVISIBLES]:setVisibles,
    [LIST_SETPAGE]:setPage,
    [LIST_FIRST_PAGE]:firstPage,
    [LIST_PREVIOUS_PAGE]:previousPage,
    [LIST_NEXT_PAGE]:nextPage,
    [LIST_LAST_PAGE]:lastPage
};

// Defino el estado inicial
const initialState = {};

// El reducer recibe el state, en caso de no existir utiliza el initialState y la accion correspondiente
const reducer = (state = initialState, action) => {    
    // Si esixte una acccion devuelvo la accion y el estado
    if(actions[action.type]) {
        return actions[action.type](state, action);
    }
    
    // Si no devuelvo solamente el estado
    return state;
};

export default reducer;

// Exporto los selectores que permiten acceder a una parte concreta del estado
export const get = (entidad, state) => state[entidad] ? state[entidad].list : [];
export const getApi = (entidad, state) => state[entidad] ? state[entidad].api : '';
export const getParameters = (entidad, state) => state[entidad] ? state[entidad].parameters : '';
export const getPaged = (entidad, state) => state[entidad] ? state[entidad].paginada : [];
export const getSelected = (entidad, state) => state[entidad] ? state[entidad].selected : [];
export const getFilters = (entidad, state) => state[entidad] ? state[entidad].filters : {};
export const page = (entidad, state) => state[entidad] ? state[entidad].page : 0;
export const visibles = (entidad, state) => state[entidad] ? state[entidad].visibles : 10;
export const isInit = (entidad, state) => state[entidad] &&  state[entidad].isInit;
export const isReady = (entidad, state) => state[entidad] &&  state[entidad].isReady;
export const isUpdating = (entidad, state) => state[entidad] &&  state[entidad].isUpdating;
export const isRefreshing = (entidad, state) => state[entidad] &&  state[entidad].isRefreshing;
export const hasErrors = (entidad, state) => state[entidad] && state[entidad].errors;

