import React from 'react';

import {
    DashboardIcon,
    ProceedingsIcon,
    TimeControlIcon,
    TimeRecordIcon,
    BoardIcon,
    TaskIcon,
    UserIcon,
    EmployeeIcon,
    PartnerIcon,
    ParcelIcon,
    ReportIcon,    
} from 'icons';

const development = {
    // Direccion IP Backend    
    //servidor: window.location.protocol+'//'+window.location.hostname,
    servidor:'https://independientes.azurewebsites.net',

    // Puerto Backend
    puerto: '443',

    // Imagenes
    rutaImagenes: '/recursos/imagenes',
    rutaReports: '/recursos/reports',
    imagenPortada: 'portada.png',
    logoCliente: 'logo-cliente.png',

    // Perfiles
    rutaPerfiles: '/recursos/perfiles',

    // Datos de la aplicacion
    aplicacion: {
        titulo: 'iopas',
        descripcion: 'Portal de Socios y gestión de incidencias del cambio climatico',
        copyright: 'itacasistemas',
        urlCopyRight: 'https://itacasistemas.com',
        año: 2021,
        cliente: 'Asaja Jumilla (rev. 1.0.1)'
    },

    // Entidades
    entidades: [
        {
            entidad: 'Dashboard',
            title: 'Cuadro de Mandos',
            href: '/dashboard',
            icon: <DashboardIcon />
        },
        /*{
            entidad: 'RegistroHorario',
            title: 'Registro Horario',
            href: '/registrohorario',
            icon: <TimeRecordIcon />
        },
        {
            entidad: 'ControlPresencia',
            title: 'Control Presencia',
            href: '/controlpresencia',
            icon: <TimeControlIcon />
        },*/
        {
            entidad: 'Expedientes',
            title: 'Expedientes',
            href: '/expedientes',
            icon: <ProceedingsIcon/>
        },
        {
            entidad: 'Tareas',
            title: 'Incidencias / Tareas',
            href: '/tareas',
            icon: <TaskIcon />
        },        
        {
            entidad: 'Tableros',
            title: 'Control Incidencias',
            href: '/tablero',
            icon: <BoardIcon />
        },
        {
            entidad: 'Informes',
            title: 'Informes',
            href: '/informes',
            icon: <ReportIcon />
        },            
        
    ],

    // Entidades de Sistema
    entSistema: [
        {
            entidad:'Usuarios',
            title: 'Usuarios',
            href: '/usuarios',
            icon: <UserIcon />
        },
        {
            entidad: 'Empleados',
            title: 'Empleados',
            href: '/empleados',
            icon: <EmployeeIcon/>
        },
        {
            entidad: 'Socios',
            title: 'Socios',
            href: '/socios',
            icon: <PartnerIcon/>
        },
        {
            entidad:'Parcelas',
            title: 'Explotaciones',
            href: '/explotaciones',
            icon: <ParcelIcon/>
        },
    ]
};

export default development;
