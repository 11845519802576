import React from 'react';
import { getInitials } from 'helpers';
import { makeStyles } from '@material-ui/styles';

import {
    SearchIcon,
    LockedIcon,
} from 'icons'

import {
    Avatar,
    Checkbox,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    InputAdornment,
    TextField,
    Typography
} from '@material-ui/core';

import * as helpers from 'helpers'

const useStyles = makeStyles(theme => ({
    root: {},
    avatar: {
        backgroundColor: theme.palette.primary.main
    }
}));

const Filter = (props) => {
    const { filter, index, disabled } = props;
    
    const filtra = (event) =>{         
        const valor=event.target.value;         
             
        helpers.timeBox(() => filter.filter(valor), 450);
    }

    if (filter.type.toLowerCase() === 'checkbox')
        return (
            <Grid key={index} item xs>
                <Checkbox
                    disabled={disabled}
                    color="primary"
                    onChange={filtra}>
                    {filter.titleFilter}
                </Checkbox>
            </Grid>
        );
    

    return (
        <Grid key={index} item xs>
            <TextField
                type={filter.type}
                margin="dense"
                fullWidth
                label={filter.titleFilter}
                variant="outlined"
                onChange={filtra}
                InputProps={{
                    readOnly: disabled,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </Grid>
    );
};

const Toolbar = props => {
    const { className, title, disabled, filters, menu, selector} = props;

    const classes = useStyles();    
    
    return (
        <Card raised={true}>
            <CardHeader
                title={
                    <Typography variant="h4">
                        {disabled && (
                            <LockedIcon color="error" fontSize="inherit" />
                        )}
                        {` ${title}`}
                    </Typography>
                }
                subheader={
                    disabled
                        ? 'Bloqueado'
                        : filters.length !== 0 &&
                          'Utilice las opciones de filtro para buscar por cada campo'
                }
                action={!disabled && menu}
                avatar={
                    <Avatar className={classes.avatar}>
                        {getInitials(title)}
                    </Avatar>
                }
            />
            {(filters.length !== 0 || selector) && (
                <div>
                    <Divider />
                    <CardContent>
                        <Grid container >
                            {selector  && ( 
                                 <Grid container spacing={1} xs>                                    
                                    {selector}
                                </Grid> 
                            )}
                            
                            {(filters.length !== 0)  && (
                                <Grid container spacing={1} xs>
                                    {filters.map(
                                        (filter, index) =>
                                            filter.filter &&
                                            <Filter
                                                filter={filter}
                                                index={index}
                                                disabled={disabled}
                                            />
                                    )}
                                </Grid>
                            )}
                            
                        </Grid>
                    </CardContent>
                </div>
            )}
        </Card>
    );
};

export default Toolbar;

