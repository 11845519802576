import React,{useEffect} from 'react';
import {contadorTemporal} from 'helpers'
import moment from 'moment'
import 'moment/locale/es'
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow
} from '@material-ui/core';

import IcoCronometro from '@material-ui/icons/TimerTwoTone';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.warning.main,
    height: 35,
    width: 35,
    fontSize: 10,
    marginRight: theme.spacing(1)
  },
  avatarFinal: {
    backgroundColor: theme.palette.warning.main,
    height: 35,
    width: 35,
    fontSize: 10,
    marginRight: theme.spacing(1)
  },
  progress: {
    color: theme.palette.warning.main,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  cancelar: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.dark
  }

}));

const suma=(lista,tipo) =>{
  let total=0
  
  lista.map(
    registro => { 
      (tipo==='p' && !registro.trabajo) && (total=total + registro.tiempo);
      (tipo==='t' && registro.trabajo) && (total=total + registro.tiempo);
      (tipo===undefined) && (total=total + registro.tiempo);
    } 
  );

  return total
}

const RegistroDiario = (props) => {
  const { diario , ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={classes.root}>      
      <CardContent>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="button">
                  {!diario.isReady ? <CircularProgress className={classes.progress} disableShrink={true}/> : 'Registro de Hoy'}
                </Typography>                            
              </TableCell>
              <TableCell align='right'>
                <Typography variant="h6">
                  Inicio
                </Typography>
              </TableCell>              
              <TableCell align='right'>
                <Typography variant="h6">
                  Fin
                </Typography>                            
              </TableCell>
              <TableCell align='right'>
                <Typography variant="h6">
                  Duración
                </Typography>                            
              </TableCell>                            
            </TableRow>
          </TableHead>
          
          <TableBody>
            {diario.list.map(evento => (
              <TableRow
                className={classes.tableRow}
                
                key={evento.id}                  
              > 
              <TableCell>
                  <div className={classes.nameContainer}>
                    <Avatar className={classes.avatar}>
                      {evento.idEventoInicial}
                    </Avatar>
                    <Typography color="textSecondary" variant="h6">{evento.eventoInicial.descripcion}</Typography>
                  </div>
                </TableCell>
                <TableCell align='right'>                    
                    <Typography color="textSecondary" variant="h6">{moment(evento.fechaInicial).format('HH:mm:ss')}</Typography>                    
                </TableCell>                 
                <TableCell align='right'>                    
                    <Typography color="textSecondary" variant="h6">{moment(evento.fechaFinal).format('HH:mm:ss')}</Typography>                    
                </TableCell>                 
                
                <TableCell align='right'>                    
                    <Typography color="textSecondary" variant="h6">{contadorTemporal(evento.tiempo)}</Typography>                    
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          
          <TableFooter>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='right'>
                <Typography color='error' variant="button">
                  Pausas
                </Typography>                              
              </TableCell>
              <TableCell align='right'>              
                <Typography color='primary' variant="button">
                  Trabajado
                </Typography>                            
              </TableCell>
              <TableCell align='right'>
                <Typography variant="button">
                  Total
                </Typography>                                                          
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='right'>
                <IcoCronometro fontSize='large' color='primary'/>                
              </TableCell>
              <TableCell align='right'>
                <Typography color='error' variant="button">
                  {contadorTemporal(suma(diario.list,'p'))}
                </Typography>                            
              </TableCell>
              <TableCell align='right'>              
                <Typography color='primary' variant="button">
                  {contadorTemporal(suma(diario.list,'t'))}
                </Typography>                            
              </TableCell>
              <TableCell align='right'>              
                <Typography variant="button">
                  {contadorTemporal(suma(diario.list))}
                </Typography>                            
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>        
      </CardContent>
    </Card>    
  )
}

export default RegistroDiario;
