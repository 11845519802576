import {
    AUTH_INIT,
    AUTH_SUCCESS,
    AUTH_FAILED,
    AUTH_LOGOUT,
    AUTH_SETLINK,
    AUTH_CLEAR,
} from 'constants.js';

import moment from 'moment';

const initAuth = (state, action) => {    
    return {
        ...state, 
        isInit: true,
        isReady: false,
        isLogged:false,
        inicio: null,        
        token:null,
        exp:null,
        user:null,
        permisos:null,
        errors: null
    };
};

const successAuth = (state, action) => {
    const inicio= action.permisos.some(item => 
        item.idEntidad==='Dashboard' && 
        item.idPermiso==='Acceso'
    )? '/registrohorario' : '/dashboard'
    
    return {
        ...state,
        isInit: false,
        isReady:true,
        isLogged:true,
        errors: null,
        inicio: inicio,
        token: action.payload,
        exp: action.sub.exp,
        user: action.sub.sub,
        permisos: action.permisos
    };
};

const failedAuth = (state, action) => {           
    return {
        ...state,
        isInit: false,
        isReady:false,
        isLogged:false,
        inicio: null,
        token:null,
        exp:null,
        user:null,
        permisos:null,
        errors: action.payload
    };
}

const logout = (state, action) => {    
    return {...state,
        isInit: false,
        isReady:true,
        isLogged:false,
        inicio: null,
        token:null,
        exp:null,
        user:null,    
        permisos:null,    
        errors: null
    };
};

const setLink = (state, action) => {    
    return {...state,
        redirectTo: action.link
    };
};

const clearAuth = (state, action) => {
    return {...state,
        isReady: true,
        errors: null,                    
    };
};

const actions = {
    [AUTH_INIT]: initAuth,
    [AUTH_SUCCESS]: successAuth,
    [AUTH_FAILED]: failedAuth,
    [AUTH_LOGOUT]: logout,
    [AUTH_SETLINK]: setLink,
    [AUTH_CLEAR]: clearAuth,
};

const initialState = {};

const reducer = (state = initialState, action) => {
    if(actions[action.type]) {
        return actions[action.type](state, action);
    }
    
    return state;
};

export default reducer;

export const get = (state) => state.token;
export const getUser= (state) => state.user;
export const getPermisos= (state) => state.permisos;
export const getInicio= (state) => state.inicio;
export const isExpired = (state) => moment().unix()>state.exp;
export const isInit = (state) => state.isInit;
export const isReady = (state) => state.isReady;
export const isLogged = (state) => state.isLogged;
export const hasErrors = (state) => state.errors;