import * as iconSelector from 'icons';

export const estados = [
    {
        value:null,
        label:'Sin definir',
        description:'No se ha definido un estado para la tarea',
        icon: iconSelector.NotDefinedIcon,
        color:'Black'
    },
    {
        value:'Pendiente',
        label:'Pendiente',
        description:'Incidencias pendientes de comprobar',
        icon: iconSelector.PendingIcon,
        color: 'Blue'         
    },
    {
        value:'Por Hacer',
        label:'A Comprobar',
        description:'Incidencias asignadas y listas para su comprobacion',
        icon: iconSelector.TodoIcon,
        color:'DarkRed'
    },
    {
        value:'En Curso',
        label:'En Curso',
        description:'Incidencias asignadas que estan siendo comprobadas',
        icon: iconSelector.DoIcon,
        color:'DarkOrange'
    },
    {
        value:'Finalizada',
        label:'Comprobada',
        description:'Incidencias comprobadas a la espera de validación',
        icon: iconSelector.DoneIcon,
        color:'Green'
    },                             
]

export const validaciones = [
    {
        value:null,
        label:'Sin definir',
        icon: iconSelector.NotDefinedIcon,
        color:'Black'
    },
    {
        value:'Por Validar',
        label:'Por Validar',
        description:'Incidencias pendientes de validar',
        icon: iconSelector.ToVerifiedIcon,
        color: 'DarkOrange'         
    },
    {
        value:'Validada',
        label:'Validada',
        description:'Incidencias comprobadas y validadas',
        icon: iconSelector.VerifiedIcon,
        color:'Green'
    },
    {
        value:'Rechazada',
        label:'Rechazada',
        description:'Incidencias comprobadas y rechazadas',
        icon: iconSelector.RejectIcon,
        color:'DarkRed'
    },                             
]

export const prioridades = [    
    {
        value:'Alta',
        label:'Alta',
        description:'Prioridad Alta',
        icon: iconSelector.PriorityHighIcon,
        color: 'DarkRed'         
    },
    {
        value:'Media',
        label:'Media',
        description:'Prioridad Media',
        icon: iconSelector.PriorityNormalIcon,
        color:'DarkOrange'
    },
    {
        value:'Baja',
        label:'Baja',
        description:'Prioridad baja',
        icon: iconSelector.PriorityDownIcon,
        color:'Green'
    },                             
]

export const archivo = [    
    {
        value:'Archivado',
        label:'Archivado',
        description:'Elemento Archivado',
        icon: iconSelector.ArchiveIcon,
        color: 'Black'         
    },
    {
        value:'Disponible',
        label:'Disponible',
        description:'Elemento no Archivado',
        icon: iconSelector.UnarchiveIcon,
        color:'Green'
    }                             
]

export const sector = [
    {
        value:null,
        label:'Sin definir',
        description:'No se ha definido un sector',
        icon: iconSelector.NotDefinedIcon,
        color:'Black'
    },
    {
        value:'Agricultura',
        label:'Agricultura',
        description:'Sector Agricola',
        icon: iconSelector.ParcelIcon,
        color: 'Green'         
    },
    {
        value:'Ganaderia',
        label:'Ganadería',
        description:'Sector Ganadero',
        icon: iconSelector.AnimalIcon,
        color:'LightSalmon'
    },
    {
        value:'Ambos',
        label:'Ambos',
        description:'Sector Agricola y Ganadero',
        icon: iconSelector.AllIcon,
        color:'Blue'
    },
                                 
]

export const tipoSocio = [
    {
        value:null,
        label:'Sin definir',
        description:'No se ha definido un tipo',
        icon: iconSelector.NotDefinedIcon,
        color:'Black'
    },
    {
        value:'Aficionado',
        label:'Aficionado',
        description:'Socio Normal',
        icon: iconSelector.AmateurIcon,
        color: 'Orange'         
    },
    {
        value:'Profesional',
        label:'Profesional',
        description:'Socio considerado como profesional',
        icon: iconSelector.ProfesionalIcon,
        color:'Blue'
    },
    {
        value:'Joven',
        label:'Joven',
        description:'Incorporacion de Joven',
        icon: iconSelector.NewIcon,
        color:'Green'
    },
                                 
]

export const estadosSocios = [
    {
        value:null,
        label:'Sin definir',
        description:'No se ha definido un estado',
        icon: iconSelector.NotDefinedIcon,
        color:'Black'
    },
    {
        value:'Activo',
        label:'Activo',
        description:'Socio Activo',
        icon: iconSelector.DoIcon,
        color:'Green'
    },
    {
        value:'Baja',
        label:'Baja',
        description:'Socio de Baja',
        icon: iconSelector.DoneIcon,
        color:'DarkRed'
    },                             
]

