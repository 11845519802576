import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import config from 'settings/environment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems:'center',
    width: '100%',
    //height: '100%',
    //padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div style={{padding: '15px'}}>
      <div>
        <Typography variant="body1">
          &copy;{' '}
          <Link
            component="a"          
            href={config.aplicacion.urlCopyRight}
            target="_blank"
          >
            {config.aplicacion.copyright}
          </Link>
          . {config.aplicacion.año}
        </Typography>
        <Typography variant="caption">
          {`"${config.aplicacion.titulo}" ${config.aplicacion.descripcion} diseñado para ${config.aplicacion.cliente}`}
        </Typography>
      </div>
      {/* <div className={classes.root}>      
        <img
          alt="Logo Info"
          src={`${config.rutaImagenes}/logos/Logo_Info.jpg`}
          height="8%"
          width="8%"
        />

        <img
          alt="Logo Crecemos"
          src={`${config.rutaImagenes}/logos/Logo_Info_crecemos.jpg`}
          height="8%"
          width="8%"
        />

        <img
          alt="Logo Feder"
          src={`${config.rutaImagenes}/logos/Logo_Feder.jpg`}
          height="8%"
          width="8%"
        />
      </div> */}
  </div>
    
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
