import React from 'react';
//import { makeStyles } from '@material-ui/styles';
import {
    Typography,
    Divider,
    Grid,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';

import {RolIcon} from 'icons';

/* const useStyles = makeStyles(theme => ({
    root: {},
})); */

const Detalle = props => {
    const { values, handleChange } = props;
    //const classes = useStyles();
    
    return (
        <form autoComplete="off" noValidate>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        helperText="Especifica el nombre del usuario"
                        label="Nombre"
                        margin="dense"
                        name="nombre"
                        onChange={handleChange}
                        required
                        value={values.nombre}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        helperText="Dinos con que hace Login el usuario"
                        label="Login"
                        margin="dense"
                        name="login"
                        onChange={handleChange}
                        value={values.login}
                        required
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        helperText="Danos un correo electronico valido"
                        label="Correo Electronico"
                        margin="dense"
                        name="email"
                        onChange={handleChange}
                        required
                        value={values.email}
                        variant="outlined"
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <TextField
                        fullWidth
                        label="Tipo"
                        margin="dense"
                        variant="outlined"
                        value={
                            values.empleado ? 'Empleado' : 'Invitado'
                        }
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </Grid>
                <Grid hidden={!values.empleado} item md={3} xs={6}>
                    <TextField
                        fullWidth
                        label="Puesto"
                        margin="dense"
                        variant="outlined"
                        value={
                            values.empleado
                                ? values.empleado.puesto
                                : null
                        }
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>                
                <Grid item md={6} xs={12}>
                    <Typography variant="h5">Roles</Typography>
                    <List>
                        {values.roles.map(
                            rol =>
                                rol.global && (
                                    <ListItem>
                                        <ListItemIcon>
                                            <RolIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={rol.id}
                                            secondary={rol.descripcion}
                                        />
                                    </ListItem>
                                )
                        )}
                    </List>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography variant="h5">Roles de Equipos</Typography>
                    <List>
                        {values.roles.map(
                            rol =>
                                !rol.global && (
                                    <ListItem>
                                        <ListItemIcon>
                                            <RolIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={rol.id}
                                            secondary={
                                                <div>
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary">
                                                        {`Equipo ${rol.equipo.nombre}`}
                                                    </Typography>
                                                    {rol.descripcion}
                                                </div>
                                            }
                                        />
                                    </ListItem>
                                )
                        )}
                    </List>
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
