import React,{useState} from 'react';

import moment from 'moment'
import es from 'moment/locale/es'

import { makeStyles } from '@material-ui/styles';
import {Alert,StatusBullet} from 'components'
import {
  Fab,
  Card,  
  CardContent,
  CardActions,
  Typography,  
  CircularProgress,  
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import AceptarIcon from '@material-ui/icons/ExitToApp';
import ItemPila from 'components/Pila/ItemPila';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.warning.main,
    height: 15,
    width: 15,
    fontSize: 10,
    marginRight: theme.spacing(1)
  },
  avatarFinal: {
    backgroundColor: theme.palette.warning.main,
    height: 35,
    width: 35,
    fontSize: 10,
    marginRight: theme.spacing(1)
  },
  progress: {
    color: theme.palette.warning.main,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  aceptar: {
    color: theme.palette.white,
    backgroundColor: theme.palette.success.dark
  },
  cancelar: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.dark
  },
  iconoExtendido:{
    marginRight: theme.spacing(1),
  }

}));

const activarTareas=(tareas,id,todas) =>{    
  todas 
    ? 
      tareas.selectAll(!tareas.allSelected)
    : 
      tareas.select(id,false,
        () => tareas.update(undefined,{
          activa:true,
          fechaDesactivacion:null,
          fechaActivacion:moment()
        },{
          activa:false,
          fechaDesactivacion:moment()
        }))
}

const SelectorTareas = (props) => {
  const { auth, tareas, ...rest } = props;
  const classes = useStyles();
  
  const[openDialog,setOpenDialog]=useState(null);
  
  return(
    <Card {...rest} className={classes.root}>
      <CardContent>        
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>              
              <TableCell>
                <Typography variant="button">
                  {tareas && (!tareas.isReady ? <CircularProgress className={classes.progress} disableShrink={true}/> : 'Seleccione una tarea')}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography variant="h6">
                  Activa
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>          
          <TableBody>
            {tareas && tareas.list.map(tarea => {              
              return (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={tarea.id}
                  selected={tareas.selected.indexOf(tarea.id) !== -1}                  
                >                   
                  <TableCell>                                       
                    <ItemPila
                      item={tarea.tarea}
                    />
                  </TableCell>
                  <TableCell 
                    align='center'
                    onClick={event => !tarea.activa && setOpenDialog(tarea)}
                  >                    
                      <StatusBullet 
                        color={tarea.activa ? 'success' : 'danger'}                        
                      />                                          
                  </TableCell>
                  
                </TableRow>
            )})}
          </TableBody>          
        </Table>
        {openDialog!==null &&
          <Alert                        
            open={openDialog}
            title={'¿Esta seguro de poner a ['+ openDialog.tarea.codigo +'] como TAREA ACTIVA?'}
            text= {`Si "Acepta" todos los tiempo registrados se imputaran a ella, a partir de este momento`}
            cancel={() => setOpenDialog(null)}
            accept={() => {                          
              activarTareas(tareas,openDialog.id,false)                        
              setOpenDialog(null)                          
            }}
          />
        }
      </CardContent>

      {auth.inicio==='/registrohorario' ?
        <CardActions>        
          <div className={classes.actions}>          
            <Fab className={classes.cancelar}
              size='medium'                
              variant='extended'  
              onClick={event => auth.doLogout()}                
            >
              <AceptarIcon className={classes.iconoExtendido}/>
              Terminar
            </Fab>         
          </div>        
      </CardActions>
      :null
      }
    </Card>
  )
}

export default SelectorTareas;
