import React, { useState, useEffect } from 'react';

import List from 'features/List';
import Entity from 'features/Entity'
import Auth from 'features/Auth';

import {Grid} from '@material-ui/core';

import { Error} from 'components'

import * as helpers from 'helpers';
import * as icons from 'icons';

import Detalle from './Detalle'


const Comentarios = (props) => {
    const { nueva, comentarios, auth, api } = props;    
        
    useEffect(() => {                
        comentarios.load(`comentarios/${api}`)
        //nueva.load('controlpresencia/new')         

        return () =>{
            comentarios.unload()
            //nueva.unload()            
        }

    }, []);
    
    if (!comentarios.isReady)
        return ('Cargando')

    return ([        
        <div>
            {comentarios.list.map( c =>
                <Grid container spacing={1} xs>
                    <Grid item xs>
                        <Detalle
                            comentario={c}
                        />
                    </Grid>
                </Grid>
            )}
        </div>,
        <Error
            entities={[comentarios]}                
        />        
    ]);
};

export const ComentariosContainer = 
    Entity(List(Auth(Comentarios,'auth'),'comentarios','comentarios')
    ,'nuevo','nuevoComentario')

export default ComentariosContainer;