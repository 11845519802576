import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducer';
import thunk from 'redux-thunk';
//import { refreshList } from 'features/List/actions';
//import { refreshEntity } from 'features/Entity/actions';
//import { now } from 'moment';

export default function configureStore() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;        
    
    const store =  createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(thunk)
        )
    );        
    
    //cnx.on('update', (action) => store.dispact(action));

    /*
    setInterval(() => {
        //store.dispatch(refreshEntity('users/4', {provider: 'Paquito'+now()}));
        store.dispatch(refreshList('users', {id: 3, name: 'Juanjo '+now()}));        
    }, 5000);
    */
        
    return store;
}
