import React from 'react';

import { makeStyles } from '@material-ui/styles';

import * as icons from 'icons';
import * as hlprs from 'helpers';

import { Grid } from '@material-ui/core';
import { Documents } from 'components';


const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Documentos = (props) => {
    const { values, editing, handleChange } = props;
    const classes = useStyles();
    console.log(values)
    return (        
        <Grid container spacing={1} xs>                
            <Grid item xs>
                <Documents
                    name='documentacion'
                    nameKey='id'
                    value={values.documentacion}
                    editing={editing}
                    onChange={handleChange}
                    dfault={{
                        //fecha: hlprs.now(),
                        resumen: 'Imagen 1',
                        link: '/recursos/documents/p2.jpg'
                    }}
                />                            
            </Grid>            
        </Grid>
    );
};

export default Documentos;










