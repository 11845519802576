import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {  
  const { className, user, ...rest } = props;
  
  const classes = useStyles();
  
  //¿ ESTA SACANDO UNA COPIA DE USER.ENTITY O COGE LA REFERENCIA?
  //const [values, setValues] = useState(user.entity);
  
  /*
  // DE ESTA MANERA CUNADO CAMBIA USER.ENTITY SE LO ASIGNO A VALUES
  useEffect(()=>
    {
      console.log('Es un ciclo');
      setValues(values =>({...values,...user.entity}))
    },[user.entity]);  
  */
  
  //const [cambios, setCambios] = useState({});
  
  const handleChange = event => {
    /*
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })*/

    user.update({[event.target.name]: event.target.value})
    /*
    setCambios({
      ...cambios,
      [event.target.name]: event.target.value
    })*/    
  };

  const states = [
    {
      value: 'cieza',
      label: 'Cieza'
    },
    {
      value: 'jumilla',
      label: 'Jumilla'
    },
    {
      value: 'yecla',
      label: 'Yecla'
    }
  ];

  const roles = [
    {
      value: 'socio',
      label: 'Socio'
    },
    {
      value: 'tecnico',
      label: 'Técnico'
    },
    {
      value: 'invitado',
      label: 'Invitado'
    }
  ];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="(*) Información requerida"
          title="Perfil de Usuario"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Especifica el nombre"
                label="Nombre"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={user.entity.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Especifica los apellidos"
                label="Apellidos"
                margin="dense"
                name="provider"
                onChange={handleChange}
                value={user.entity.provider}
                required                
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Correo Electronico"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={user.entity.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Teléfono"
                margin="dense"
                name="phone"
                onChange={handleChange}
                type="number"                
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Población"
                margin="dense"
                name="state"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {states.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Provincia"
                margin="dense"
                name="country"
                onChange={handleChange}
                required                
                variant="outlined"
              />
            </Grid>


            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="Rol de Usuario"
                margin="dense"
                name="rol"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {roles.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={9}
              xs={12}
            >
              <TextField
                fullWidth
                label="Formación"
                margin="dense"
                name="puesto"
                onChange={handleChange}                                
                variant="outlined"
              />
            </Grid>          
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
         <Button
            color="primary"
            variant="contained"
            onClick={() => 
              {
                user.edit(!user.isEditable);
              }
            }
          >
            Editar
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => 
              {
                user.undo();
              }
            }
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => 
              {                
                user.save();                
              }
            }
          >
            Guarda Detalles
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => 
              {
                user.delete();
              }
            }
          >
            Borrar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
