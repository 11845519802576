import React from 'react';

import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css';

const Calendar = (props) => {
    const { selectedDays,onSelectDay,disabled } = props;

    const handleDayClick = (day, { selected }) => {
        const days = selectedDays.concat();
        
        if (selected) {
          const selectedIndex = days.findIndex(selectedDay =>
            DateUtils.isSameDay(selectedDay, day)
          );
          days.splice(selectedIndex, 1);
        } else {
          days.push(day);
        }
        
        onSelectDay(days);
      }

    return (                
        <div style={{fontFamily: "roboto"}}> 
             <DayPicker
                disabled
                selectedDays={selectedDays}
                onDayClick={!disabled && handleDayClick}                
                initialMonth={new Date('2021-01-01')}
                pagedNavigation={true} 
                localeUtils={MomentLocaleUtils} 
                locale='es' 
                numberOfMonths={12}
            />    
        </div>        
    );
};

export default Calendar;