import React from 'react';
import { Switch, Redirect,Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Perfil as PerfilView,
  Dashboard as DashboardView,
  RegistroHorario as RegistroHorarioView,
  ControlPresencia as ControlPresenciaView,
  Tablero as TableroView,
  Expedientes as ExpedientesView,
  Informes as InformesView,
  Notificaciones as NotificacionesView,
  Tareas as TareasView,
  Usuarios as UsuariosView,
  Empleados as EmpleadosView,
  Socios as SociosView,
  Parcelas as ParcelasView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Report as ReportView,
  Pruebas as PruebasView

  //Account as AccountView,
  //UserList as UserListView,
  //Typography as TypographyView,
  //Icons as IconsView,  
  //Settings as SettingsView,
  //SignUp as SignUpView,  
} from './views';

export const PublicRoutes = () => {  
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/sign-in"
      />      

      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
    </Switch>
  );
};

export const Routes = props => {  
  const { auth } = props

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={auth.inicio}
      />
      <Redirect
        exact
        from="/sign-in"
        to='/'
      />
      <RouteWithLayout
        component={PerfilView}
        exact
        layout={MainLayout}
        path="/perfil/:id?"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={RegistroHorarioView}
        exact
        layout={MainLayout}
        path="/registrohorario"
      />
      <RouteWithLayout
        component={ControlPresenciaView}
        exact
        layout={MainLayout}
        path="/controlpresencia"
      />
      <RouteWithLayout
        component={TableroView}
        exact
        layout={MainLayout}
        path="/tablero"
      />
      <RouteWithLayout
        component={NotificacionesView}
        exact
        layout={MainLayout}
        path="/notificaciones"
      />       
      <RouteWithLayout
        component={ExpedientesView}
        exact
        layout={MainLayout}
        path="/expedientes"
      />
      <RouteWithLayout
        component={InformesView}
        exact
        layout={MainLayout}
        path="/informes"
      />
      <RouteWithLayout
        component={TareasView}
        exact
        layout={MainLayout}
        path="/tareas"
      />
      <RouteWithLayout
        component={UsuariosView}
        exact
        layout={MainLayout}
        path="/usuarios"
      />
      <RouteWithLayout
        component={EmpleadosView}
        exact
        layout={MainLayout}
        path="/empleados"
      />
      <RouteWithLayout
        component={SociosView}
        exact
        layout={MainLayout}
        path="/socios"
      />
      <RouteWithLayout
        component={ParcelasView}
        exact
        layout={MainLayout}
        path="/explotaciones"
      />
      <RouteWithLayout
        component={PruebasView}
        exact
        layout={MainLayout}
        path="/pruebas"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Route
        component={ReportView}        
        exact
        path="/report/:name"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
