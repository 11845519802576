import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuItem, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(1),
        },
    }
}));                   

const Select = props => {
    const classes = useStyles();
    const {                
        editing,
        options,
        onChange,
        ...rest
    } = props;    

    return (
        <TextField            
            {...rest}
            select
            fullWidth
            margin="dense"
            variant="outlined"
            onChange={event => onChange(
                [{
                    field: event.target.name,
                    value: event.target.value
            }])}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                readOnly: !editing,
            }}
        >
            {options.map(item => {
                const Icon=item.icon

                return(
                    <MenuItem value={item.value}>
                        <div 
                            className={classes.wrapper}
                            style={{color:item.color}} 
                        >
                            <Icon fontSize="small"/>
                            <Typography color="inherit" variant="h6">
                                {item.label}
                            </Typography>
                        </div>
                    </MenuItem>
                )            
            })}
        </TextField>
    );
};

export default Select;
