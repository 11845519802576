import React, { useState, useEffect } from 'react';
//import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import { BackIcon } from 'icons';
import Auth from 'features/Auth';
import config from 'settings/environment';

const schema = {
    email: {
        presence: { allowEmpty: true, message: 'is required' },
        email: true,
        length: {
            maximum: 64,
        },
    },
    password: {
        presence: { allowEmpty: true, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
    pin: {
        presence: { allowEmpty: true, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white', //theme.palette.background.default,
        height: '100%',
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems:'center',
        width: '100%',
        height: '100%',
        //padding: theme.spacing(4)
      },
    wrapperLogos: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems:'center',
        width: '100%',
        height: '100%',
        //padding: theme.spacing(4)
      },
    grid: {
        height: '100%',
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${config.rutaImagenes}/imagenes/${config.imagenPortada})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px',
    },
    quoteText: {
        textAlign: 'center',
        color: theme.palette.white,
        fontWeight: 600,
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white,
    },
    bio: {
        color: theme.palette.white,
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent: 'center',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        marginTop: theme.spacing(3),
    },
    socialButtons: {
        marginTop: theme.spacing(3),
    },
    socialIcon: {
        marginRight: theme.spacing(1),
    },
    sugestion: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    signInButton: {
        margin: theme.spacing(2, 0),
    },
}));

const SignIn = (props) => {
    const { history, auth } = props;

    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    useEffect(() => {
        auth.doInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState((formState) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleBack = () => {
        history.goBack();
    };

    const handleChange = (event) => {
        event.persist();

        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const hasError = (field) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    const login = (auth) => {
        auth.doLogin(
            formState.values['email'],
            formState.values['password'],
            formState.values['pin']
        );
    };

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.quoteContainer} item lg={5}>
                    <div className={classes.quote}>
                        <div>
                            <Typography
                                className={classes.quoteText}
                                variant='h1'>
                                {`"${config.aplicacion.titulo}" ${config.aplicacion.descripcion}`}
                            </Typography>

                            <Typography
                                className={classes.quoteText}
                                variant='h4'>
                                {`diseñado para ${config.aplicacion.cliente}`}
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.content} item lg={7} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentHeader}>
                            <IconButton onClick={handleBack}>
                                
                            </IconButton>
                        </div>
                        <div className={classes.contentBody}>
                            <form className={classes.form}>
                                <div className={classes.wrapperLogos}>
                                <img
                                    alt='Logo'
                                    src={`${config.rutaImagenes}/logos/logo.png`}
                                    height='30%'
                                    width='30%'
                                />
                                <img
                                    alt='Logo Cliente'
                                    src={`${config.rutaImagenes}/logos/${config.logoCliente}`}
                                    height='40%'
                                    width='40%'
                                />
                                </div>
                                <Typography
                                    className={classes.title}
                                    variant='h2'>
                                    Inicia Sesión
                                </Typography>
                                <Typography color='textSecondary' gutterBottom>
                                    Introduce los datos de inicio proporcionados
                                </Typography>

                                <TextField
                                    className={classes.textField}
                                    error={hasError('email')}
                                    fullWidth
                                    helperText={
                                        hasError('email')
                                            ? formState.errors.email[0]
                                            : null
                                    }
                                    label='Usuario'
                                    name='email'
                                    onChange={handleChange}
                                    type='text'
                                    value={formState.values.email || ''}
                                    variant='outlined'
                                />
                                <TextField
                                    className={classes.textField}
                                    error={hasError('password')}
                                    fullWidth
                                    helperText={
                                        hasError('password')
                                            ? formState.errors.password[0]
                                            : null
                                    }
                                    label='Contraseña'
                                    name='password'
                                    onChange={handleChange}
                                    type='password'
                                    value={formState.values.password || ''}
                                    variant='outlined'
                                />
                                
                                {/*disabled={!formState.isValid}*/}
                                <Button
                                    className={classes.signInButton}
                                    color='primary'
                                    fullWidth
                                    size='large'
                                    variant='contained'
                                    type='default'
                                    onClick={() => login(auth)}>
                                    Confirma
                                </Button>
                            </form>                            
                        </div>
                        <div className={classes.wrapper}>
                            <a target="_blank" href={`https://www.adcnordeste.es/`}>
                                <img
                                    alt='Logo ADC NOrdeste'
                                    src={`${config.rutaImagenes}/logos/adcnordeste.png`}
                                    height='200'
                                    width='150'
                                />
                            </a>

                            <img
                                alt='Logo Leader'
                                src={`${config.rutaImagenes}/logos/leader.bmp`}
                                height='200'
                                width='150'
                            />                                
                        </div>
                    </div>
                    <div className={classes.wrapper}>
                        <a target="_blank" href={`https://www.carm.es/web/pagina?IDCONTENIDO=1&IDTIPO=180`}>
                            <img
                                alt='Logo Region de Murcia'
                                src={`${config.rutaImagenes}/logos/region-murcia.jpg`}
                                height='200'
                                width='400'
                            />
                        </a>
                        <a target="_blank" href={`https://www.mapa.gob.es/es/`}>
                            <img
                                alt='Logo Ministerio'
                                src={`${config.rutaImagenes}/logos/mapa.png`}
                                height='125'
                                width='400'
                            />
                        </a>
                        <a target="_blank" href="https://ec.europa.eu/info/food-farming-fisheries/key-policies/common-agricultural-policy/rural-development_es">
                            <img
                                alt='Logo UE'
                                src={`${config.rutaImagenes}/logos/feader.jpg`}
                                height='250'
                                width='275'
                            />
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

SignIn.propTypes = {
    history: PropTypes.object,
};

export const SignInContainer = Auth(SignIn, 'auth');

export default SignInContainer;
