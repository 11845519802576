import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    MoreIcon,
    LockedIcon,
 } from 'icons';

import {
    Badge,
    Checkbox,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({ 
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',        
    },       
}));

const Head = props => {
    const { disabled, columns, entities } = props;
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.actions} >
                    <Badge
                        color="error"
                        badgeContent={entities.itemsSelected}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}>
                        {!disabled ? <MoreIcon size='small'/> : <LockedIcon size='small' color="error" />}
                    </Badge>
                </TableCell>

                <TableCell padding="checkbox">
                    <Checkbox
                        checked={entities.allSelected}
                        indeterminate={
                            !entities.allSelected && entities.itemsSelected !== 0
                        }
                        color="primary"
                        onChange={event =>
                            entities.selectAll(event.target.checked)
                        }
                    />
                </TableCell>
                {columns.map(
                    (column, index) =>
                        column.column !== '' && (
                            <TableCell key={index}>{column.title}</TableCell>
                        )
                )}
            </TableRow>
        </TableHead>
    );
};

export default Head;
