import React,{useState} from 'react';
import Selector from 'components/Selector';
import * as icons from 'icons';
import * as helpers from 'helpers';
import moment from 'moment'

const Pruebas = props => {
    const setCheck =(check,value) =>{  
        const newChecks = selector.checks.map(c => {
            if (c.name===check)
                return {...c , value:value}
            else
                return c
        })
        
        setSelector(
            {...selector,
                checks:newChecks,                
            }
        )               
    }

    const setOption = (option,range) =>{         
        setSelector(
            {...selector,
                option:option,
                range:range,
            }
        )
    }
    
    const [selector,setSelector]=useState({
        type:'date',                
        option:0,
        range:[
            moment().format('yyyy-MM-DD'),
            moment().format('yyyy-MM-DD')
        ],        
        options:[            
            {label:"Diario", value:"diary", icon: icons.DiaryIcon, period: 'days',contentLabel:'Inicio',contentFormat:'datetime-local',helperText:'Eventos por día'},
            {label:"Semanal", value:"weekly", icon: icons.WeeklyIcon, period: 'weeks',contentLabel:'Dia',contentFormat:'day',helperText:'Eventos por día de la semana'},
            {label:"Mensual", value:"monthly", icon: icons.MonthlyIcon, period: 'months',contentLabel:'Semana',contentFormat:'week',helperText:'Eventos por semanas del mes'},
            {label:"Anual", value:"yearly", icon:icons.YearlyIcon, period: 'years',contentLabel:'Mes',contentFormat:'month',helperText:'Eventos por meses del año'},        
        ],
        checks:[            
            {label:"Laborables", name:'workable', value:false, helperText:'Muestra solo los dias laborables'}
        ]
    })    

    return (        
        <Selector
            options={selector.options}
            checks={selector.checks}
            //type={selector.type}
            setOption={setOption}
            setCheck={setCheck}
            option={selector.option}
            range={selector.range}
        />
    );
};

export default Pruebas;
