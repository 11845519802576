import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    LinearProgress,
    Divider,
    Tooltip,
    IconButton,
} from '@material-ui/core';

import { CloseIcon } from 'icons';

import Toolbar from 'components/Crud/Edition';
import { Alert, Error } from 'components';
import * as hlprs from 'helpers'


const useStyles = makeStyles((theme) => ({
    root: {        
        width: '100%',
        height: '100%',
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-star',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
}));

const getTitle=(item,entity )=> {    
    if (entity.isNew) return 'Nuevo';
    if (entity.isEditable) return 'Editando';

    const prps=item.resume
    let title=''    

    if (!Array.isArray(prps)) {        
        title=entity.entity[prps]
    }else{        
        for (var k = 0; k <= prps.length-1; k++){     
            if (title !== '') title = title + '-';            
            title=title+hlprs.getValue(entity.entity,prps[k]);
        }                
    }

    title = (title === undefined) ? item.title : (title === null) ? '' : title
    
    return title;
}

const Element = (props) => {    
    const { auth, entity, item, close, edit, readOnly, refreshIndex } = props;
    
    const classes = useStyles();
    const Detail = item.element;

    const [cancel, setCancel] = useState(false);
    const [values, setValues] = useState({});
    const [changes, setChanges] = useState({});
    const [op, setOp] = useState(-1);

    const[openDialog,setOpenDialog]=useState(false);

    useEffect(() => {        
        if (item.api !== undefined) {
            entity.load(item.api);

            return entity.unload;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {        
        if (entity.isReady) {
            if (!item.dfault){                
                setValues(hlprs.clone(entity.entity));
                setChanges(hlprs.clone(entity.changes));
            }
            else {                
                const newEntity={
                    ...hlprs.clone(entity.entity),
                    ...item.dfault,
                }

                const newChanges={
                    ...hlprs.clone(entity.changes),
                    ...item.dfault,
                }

                entity.update(newChanges)

                setValues(newEntity);
                setChanges(newChanges);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.isReady]);

    useEffect(() => {
        if (entity.isReady) {
            edit && edit(item.key, cancel, entity.entity.id, entity.isEditable, entity.entity);
            setCancel(false);
            if (!entity.isEditable) {                  
                setValues(hlprs.clone(entity.entity));
                setChanges({});
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.isEditable]);

    useEffect(() => {
        if (entity.isReady) {            
            if (!entity.isRefreshing) {                
                setValues(hlprs.clone(entity.entity));                
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.isRefreshing]);

    const refresh=(onlyIndex) =>{        
        entity.refresh(undefined,undefined,onlyIndex)
        refreshIndex && refreshIndex()
    }
    
    const handleChange = (chngs,detail,key) => {
        if (entity.isEditable) {
            let newValues = values;
            let newChanges = changes;            

            if (detail && (key<0 || key===undefined)){
                if (key===undefined){                    
                    // Añado una nueva linea
                    const newDetail=[]
                    
                    if (newValues[detail] !==undefined && newValues[detail] !==null)
                        newValues[detail].forEach((listItem,index) => 
                            newDetail.push(listItem)
                        )
                    
                    newDetail.push(chngs)

                    newValues[detail]=newDetail
                                
                    newChanges = {
                        ...newChanges,
                        [detail]: newDetail,
                    };       
                }
                else{                
                    // Baja de linea
                    const newDetail=[]
                    
                    newValues[detail].forEach((listItem,index) => 
                        (index !== (Math.abs(key)-1)) && newDetail.push(listItem)
                    )                           
                    
                    newValues[detail]=newDetail
                    
                    newChanges = {
                        ...newChanges,
                        [detail]: newDetail,
                    }
                }
            }
            else{                
                chngs.map(change => {
                    if (change.value !==null && typeof(change.value)==='object') {                        
                        if (Array.isArray(change.value.value)){
                            // Combo Multiple
                            if (detail){
                                const newDetail=newValues[detail].map((listItem,index) => 
                                    (index !== key) ? listItem : 
                                    {
                                        ...listItem, 
                                        [change.field]: change.value.value,
                                    }
                                )                           
                                
                                newValues[detail]=newDetail
                                
                                newChanges = {
                                    ...newChanges,
                                    [detail]: newDetail,
                                };
                            }
                            else{
                                newValues = {
                                    ...newValues,
                                    [change.field]: change.value.value,
                                };
                                newChanges = {
                                    ...newChanges,
                                    [change.field]: change.value.value,
                                };  
                            }                      
                        }
                        else {
                            // Combo Simple                    
                            if (detail){                            
                                const newDetail=newValues[detail].map((listItem,index) => 
                                    (index !== key) ? listItem : 
                                    {
                                        ...listItem, 
                                        [change.value.nameKey]: change.value.value==='' ? null : change.value.value,
                                        [change.value.name]: change.value.selected,
                                    }
                                )

                                newValues[detail]=newDetail
                                
                                newChanges = {
                                    ...newChanges,
                                    [detail]: newDetail,
                                };
                            }
                            else{
                                newValues = {
                                    ...newValues,
                                    [change.value.nameKey]: change.value.value==='' ? null : change.value.value,
                                    [change.value.name]: change.value.selected,
                                };
                                newChanges = {
                                    ...newChanges,
                                    [change.value.nameKey]: change.value.value==='' ? null : change.value.value,
                                    [change.value.name]: change.value.selected,
                                };
                            }
                            
                        }
                    } else {
                        // Control simple
                        if (detail){
                            const newDetail=newValues[detail].map((listItem,index) => 
                                (index !== key) ? listItem : 
                                {
                                    ...listItem, 
                                    [change.field]: change.value==='' ? null : change.value,
                                }
                            )                       
                            
                            newValues[detail]=newDetail
                            
                            newChanges = {
                                ...newChanges,
                                [detail]: newDetail,
                            };
                        }
                        else{                
                            newValues = {
                                ...newValues,
                                [change.field]: change.value==='' ? null : change.value,
                            };
                            newChanges = {
                                ...newChanges,
                                [change.field]: change.value==='' ? null : change.value,
                            };
                        }
                    }
                })
            }
                        
            clearTimeout(op);            
            setValues(newValues);            
            setChanges(newChanges);
            setOp(setTimeout(() => entity.update(newChanges), 400));                        
        }
    };

    const saveEntity = () => {
        entity.save();
    };

    const cancelEntity = () => {
        if (item.api)
            if (item.api.toLowerCase().indexOf('/new') !== -1) {
                close && close(item.key);
            } else {
                setCancel(true);
                entity.undo();
            }
        else {
            setCancel(true);
            entity.undo();
        }
    };

    const editEntity = () => {
        entity.edit(!entity.isEditable);
    };

    if (!entity.isReady || Object.keys(values).length === 0) 
        return (
            <div style={{
                width:'100%'
              }}>                  
                <LinearProgress/>
              </div>
        )

    return (
        <Card className={classes.root} raised={true}>
            <Error
                entities={[entity]}
                cancel={
                    entity.isEditable
                        ? undefined
                        : () => close && close(item.key)
                }
            />

            <CardHeader
                title={<Typography variant="h4">{getTitle(item,entity)}</Typography>}
                subheader={'(*) Información requerida.'}
                action={!readOnly &&
                    (<div className={classes.wrapper}>
                        <Typography variant="caption">{`Ref. ${item.id}`}</Typography>
                        <Toolbar
                            editing={entity.isEditable}
                            updating={entity.isUpdating}
                            edit={editEntity}
                            save={saveEntity}
                            cancel={cancelEntity}
                            changes={Object.keys(entity.changes).length}
                        />
                    </div>)
                }
                avatar={
                    close && (
                        <Tooltip title={'Cerrar ' + item.title}>
                            <IconButton
                                //disabled={entity.isEditable}
                                onClick={() =>
                                    entity.isEditable ? setOpenDialog(true) : close(item.key)
                                }>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
            />
            <Divider />
            <CardContent>
                <Detail
                    auth={auth}
                    values={values}
                    initial={entity.initial}
                    editing={entity.isEditable && !entity.isUpdating}
                    adding={entity.isNew}
                    handleChange={handleChange}
                    refresh={refresh}
                />
            </CardContent>
            <Alert
                open={openDialog}
                title='¿Hay cambios pendientes de aceptar. Esta seguro de cerrar?'
                text= {`Si "Acepta" perdera todos los cambios realizados desde la ultima vez que los guardo. Tenga en cuenta que esta acción es irreversible.`}
                cancel={() => setOpenDialog(false)}
                accept={() => {
                    setOpenDialog(false)
                    close(item.key)
                }}
            />
        </Card>        
    );
};

export default Element;
