import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import List from 'features/List';
import * as icons from 'icons';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
    },
}));

const readed = (id, list) => {
    list.select(id, true,
        () =>list.update(undefined,{ estado: 'Leida' })
    );
};

const Notificaciones = (props) => {
    const { notificaciones } = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h1'>
                        {notificaciones &&
                            (notificaciones.list.length === 0
                                ? 'No hay ninguna Notificación'
                                : 'Notificaciones Disponibles')}
                    </Typography>
                    <Divider />
                </Grid>

                {notificaciones &&
                    notificaciones.list.map((notificacion) => (
                        <Grid item xs={4}>
                            <Card
                                style={{ marginBottom: '15px' }}
                                raised={true}>
                                <CardHeader
                                    title={
                                        <Typography
                                            //style={{ color: state.color }}
                                            variant='h4'>
                                            {notificacion.razon}
                                        </Typography>
                                    }
                                    subheader={
                                        'Notificador [' +
                                        notificacion.notificador.nombre +
                                        ']'
                                    }
                                    action={
                                        <Typography variant='caption'>
                                            {moment(notificacion.fecha).format(
                                                'DD/MM/YYYY HH:mm:SS'
                                            )}
                                        </Typography>
                                    }
                                    avatar={
                                        <Tooltip title={'Marcar como leido'}>
                                            <IconButton
                                                //style={{ color: state.color }}
                                                size='small'
                                                onClick={() =>
                                                    readed(
                                                        notificacion.id,
                                                        notificaciones
                                                    )
                                                }>
                                                <icons.CloseIcon fontSize='large' />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                                <Divider />

                                <CardContent>
                                    <Typography variant='body1'>
                                        {notificacion.detalles}
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <div className={classes.actions}>
                                        <Typography variant='caption'>
                                            Tarea [{notificacion.tarea.id}]-[{notificacion.tarea.resumen}]
                                        </Typography>
                                        <Typography variant='caption'>
                                             Estado [{notificacion.tarea.estado}]
                                        </Typography>
                                        <Typography variant='caption'>
                                             Validación [{notificacion.tarea.validacion}]
                                        </Typography>
                                    </div>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
};

export const NotificacionesContainer = List(
    Notificaciones,
    'notificaciones',
    'notificaciones/pending'
);

export default NotificacionesContainer;
