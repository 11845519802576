import React,{useState} from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid, Hidden } from '@material-ui/core';

import * as icons from 'icons';
import * as hlprs from 'helpers';
import moment from 'moment'


import List from 'features/List';

import { Combo, DataGrid, Text, Switch } from 'components';

const ComboTipos = List(Combo, 'options', 'tiposincidenciaempleado');

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Incidencias = (props) => {
    const { values, editing, adding, handleChange,refresh } = props;
    const classes = useStyles();

    const columns=[
        {
            column: 'tipo',        
            title: 'Tipo',
            align:'left',
            width: '30%',         
            component:{
                Container: ComboTipos,
                props:{
                    api:"tiposincidenciaempleado",
                    name:"tipo",
                    nameKey:"idTipo",                    
                    refresh: refresh,
                    Icon: icons.TypesIcon,                                        
                    editing:editing,
                    propertyValue:"id",
                    getOptionLabel:(option) =>
                        `${option.descripcion}`
                }, 
                propValue:'value',
                eventChange:'onChange'                
            }
        },
        {
            column: 'resumen',
            title: 'Resumen',
            align:'left',
            width: '35%',            
            component:{
                Container: Text,
                props:{                    
                    name:"resumen",                                                                                
                    editing:editing
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },              
        {
            column: 'desde',
            title: 'Desde',
            type:"datetime-local",
            align:'left',  
            width: '5%',                      
            component:{
                Container: Text,
                props:{                    
                    name:"desde",                                                            
                    type:"datetime-local",                                        
                },
                parameters:{                    
                    editing: row => (row.idPresencia!==null && row.idPresencia!==undefined) ? false : editing,
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
        {
            column: 'hasta',
            title: 'Hasta',
            type:"datetime-local",
            align:'left',
            width: '5%',                        
            component:{
                Container: Text,
                props:{                    
                    name:"hasta",                                                            
                    type:"datetime-local",                                        
                },                
                parameters:{                    
                    editing: row => (row.idPresencia!==null && row.idPresencia!==undefined) ? false : editing,
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
        {
            column: 'tiempo',
            title: 'Duracion',            
            align:'center',
            width: '15%',                        
            component:{
                Container: Text,
                props:{                    
                    name:"tiempo",                                                            
                    type:"contadortemporal",
                    editing:editing
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },
        {
            column: 'remunerado',
            title: 'Remunerado',
            align:'center',
            width: '5%',                        
            component:{
                Container: Switch,
                props:{
                    name:"remunerado",
                    size:"small",                    
                    editing:editing,                    
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        },                        
        {
            column: 'justificado',
            title: 'Verificado',
            align:'center',
            width: '5%',                        
            component:{
                Container: Switch,
                props:{
                    name:"justificado",
                    size:"small",                    
                    editing:editing,                    
                },
                parameters:{                    
                    hidden: row => (row.idPresencia!==null && row.idPresencia!==undefined) ? false : true,
                },
                propValue:'value',
                eventChange:'onChange' 
            }
        }
    ]

    return (        
        <Grid container spacing={1}>                
            <Grid item xs>
                <DataGrid
                    name='incidencias'                        
                    list={values.incidencias ? values.incidencias : []}
                    columns={columns}
                    editing={editing}
                    lockRow={row => (row.idPresencia!==null && row.idPresencia!==undefined)}
                    onChange= {handleChange}                    
                    dfault={() =>{                
                        return {
                            desde:hlprs.now(),                                        
                            justificado: false,
                            remunerado:false,
                            trabajo:false
                        }
                    }}
                    maxHeight={456}
                />            
            </Grid>            
        </Grid>
    );
};

export default Incidencias;










