import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button,Checkbox } from '@material-ui/core';

import { SearchInput } from 'components';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const UsersToolbar = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer}>
          {users.itemsSelected}
        </span>
       
        <Button
          color="primary"
          variant="contained"
        >
          Nuevo Socio
        </Button>

        <Button
          color="warning"
          variant="contained"
          disabled={users.itemsSelected===0}
          onClick={event=>users.delete()}
        >
          Eliminar Socios
        </Button>
      </div>
      <div className={classes.row}>           
        <SearchInput          
          className={classes.searchInput}
          placeholder="Nombre"
          onChange={event=>users.filter(['name','email'],event.target.value,'',event.target.type)}          
        />
        <SearchInput          
          className={classes.searchInput}
          type="date"
          placeholder="Fecha Nacimiento"
          onChange={event=>users.filter(['fnacimiento'],event.target.value,'>=',event.target.type)}          
        />        
        <SearchInput          
          className={classes.searchInput}
          type="number"
          placeholder="Edad Inicial"
          onChange={event=>users.filter(['edad'],event.target.value,'>=',event.target.type)}          
        />
        <SearchInput
          className={classes.searchInput}
          type="number"
          placeholder="Edad Final"
          onChange={event=>users.filter(['edad'],event.target.value,'<=',event.target.type)}          
        />
        <Checkbox          
          color="primary"                                          
          onChange={event=> users.filter(['adulto'],event.target.checked===true?[true]:'','in',event.target.type)}          
        >Adulto</Checkbox>
        <Checkbox          
          color="primary"                                          
          onChange={event=> users.filter(['adulto'],event.target.checked===true?[true,false]:'','in',event.target.type)}                    
        >Menor</Checkbox>

      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
