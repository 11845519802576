import React from 'react';

import { Crud } from 'components/Crud';
import Detalle from './Detalle';
import List from 'features/List';
import Auth from 'features/Auth';

const columns = expedientes => [
    {
        column: 'codigo',
        title: 'Codigo',
        type: 'text',
        titleFilter: 'Buscar',
        filter: (valor) =>
            expedientes.filter(
                ['codigo', 'resumen', 'detalles'],
                valor,
                '',
                'text'
            )
    },
    {
        column: ['socio','nombre'],
        title: 'Socio',
        type: 'text',
        titleFilter: 'Socio',
        filter: (valor) =>
            expedientes.filter([['socio','nombre']],
                valor,
                '',
                'text'
            )
    },
    {
        column: 'fecha',
        title: 'Fecha',
        type: 'date',
        titleFilter: 'Fecha (desde)',
        filter: (valor) =>
            expedientes.filter(['fecha'], valor, '>=', 'date')
    },
    {
        column: '',
        title: '',
        type: 'Date',
        titleFilter: 'Fecha (hasta)',
        filter: (valor) =>
            expedientes.filter(['fecha'], valor, '<=', 'date')
    },
    {
        column: 'resumen',
        title: 'Resumen',
        type: 'text'
    }
];

const CrudContainer = List(Auth(Crud,'auth'), 'entities', 'expedientes');

const Expedientes = props => {
    const { ...rest } = props;

    return (
        <CrudContainer
            title="Expedientes"
            columns={columns}
            id="id"
            resume="resumen"
            api="expedientes/"
            Detalle={Detalle}
            {...rest}
        />
    );
};

export default Expedientes;
