import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';

import {
    Grid,
    TextField,
} from '@material-ui/core';

import {
    Text,
    Button,
    Combo
} from 'components'

import List from 'features/List';

import DetailSocio from 'views/Socios/Detalle'
import DetailParcela from 'views/Parcelas/Detalle'

import * as icons from 'icons'
import { print,listReports } from 'reports'


/* const useStyles = makeStyles(theme => ({
    root: {
    }
})); */

const printReport=(report,values)=>{    
    const optReport={        
        params:{
            proyecto: values.id
        }        
    }

    print(report,optReport)
}

const ComboSocios = List(Combo, 'options', 'socios');
const ComboParcelas = List(Combo, 'options', 'parcelas');

const Detalle = props => {
    const { values, editing, handleChange,refresh } = props;
    //const classes = useStyles();
    
    return (
        <form autoComplete="off" noValidate>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <ComboSocios
                        api="socios"
                        name="socios"
                        nameKey="idSocio"
                        label="Socio"
                        Icon={icons.PartnerIcon}
                        helperText="Seleccione un Socio"
                        value={values.socio}
                        onChange={handleChange}
                        editing={editing}
                        propertyValue="id"
                        getOptionLabel={(option) => option.nombre}                        
                        relacionado={{
                            Detail: DetailSocio,
                            resume:['nombre'],                            
                            dfault:{
                                nombre:values.nombre
                            }                       
                        }}
                        refresh={refresh}                        
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                        toolTipText='Imprimir seguimiento'
                        disabled={false}                                                
                        icon={<icons.PrintIcon/>}
                        selector={listReports('tiemposProyectos',(report) => printReport(report,values))}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Text
                        name="codigo"
                        label="Código"
                        helperText="Código asignado al expedinte"
                        value={values.codigo}
                        onChange={handleChange}
                        editing={editing}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Text
                        name="fecha"
                        label="Fecha"
                        helperText="Fecha de alta"                        
                        value={values.fecha}
                        type="date"
                        onChange={handleChange}  
                        editing={editing}                      
                    />
                </Grid>
                <Grid item xs={12}>
                    <ComboParcelas
                        api="parcelas"
                        multiple
                        name="parcelas"                                 
                        label="Parcelas"
                        Icon={icons.ParcelIcon}
                        helperText="Parcelas afectadas"
                        value={values.parcelas ? values.parcelas.map(item=>item):[]}
                        onChange={handleChange}
                        editing={editing}
                        //filtro={estableceFiltro("parcelas")}
                        propertyValue="id"
                        getOptionLabel={(option) => `${option.poligono}-${option.parcela}-${option.recinto}`}
                        getItem={item => ({
                            id:item.id,                                    
                            poligono:item.poligono,
                            parcela:item.parcela,
                            recinto:item.recinto,
                        })}
                        relacionado={{
                            Detail: DetailParcela,                                    
                            resume:['resumen'],
                            readOnly:true                                    
                        }}
                        refresh={refresh}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Text
                        name="resumen"
                        label="Resumen"
                        helperText="Resumen o titulo"
                        value={values.resumen}
                        onChange={handleChange} 
                        editing={editing}                       
                    />
                </Grid>
                <Grid item xs={12}>
                    <Text
                        name="detalles"
                        label="Detalles"
                        helperText="Descripción detallada"
                        value={values.detalles}
                        onChange={handleChange}
                        editing={editing}
                        multiline
                        rows={18}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Detalle;
