import React from 'react';

import { Crud } from 'components/Crud';
import Detalle from './Detalle';
import List from 'features/List';
import Auth from 'features/Auth';

const columns = informes => [
    {
        column: 'fecha',
        title: 'Fecha',
        type: 'date',
        titleFilter: 'Fecha (desde)',
        filter: (valor) =>
        informes.filter(['fecha'], valor, '>=', 'date')
    },
    {
        column: '',
        title: '',
        type: 'Date',
        titleFilter: 'Fecha (hasta)',
        filter: (valor) =>
        informes.filter(['fecha'], valor, '<=', 'date')
    },
    {
        column: 'resumen',
        title: 'Resumen',
        type: 'text'
    },
    {
        column: ['empleado','nombre'],
        title: 'Empleado',
        type: 'text',
        titleFilter: 'Empleado',
        filter: (valor) =>
        informes.filter([['empleado','nombre']],
                valor,
                '',
                'text'
            )
    },
];

const CrudContainer = List(Auth(Crud,'auth'), 'entities', 'informes');

const Informes = props => {
    const { ...rest } = props;

    return (
        <CrudContainer
            title="Informes"
            columns={columns}
            id="id"
            resume="resumen"
            api="informes/"
            Detalle={Detalle}
            {...rest}
        />
    );
};

export default Informes;
