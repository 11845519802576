import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';

import Head from './Head';
import Row from './Row';

import {
    Card,
    CardActions,
    CardContent,
    Table,
    TableContainer,
    TableBody,
    Typography,
    TablePagination
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 4
    },
    container: {
        //maxWidth: 800,
        //maxHeight: 500
    },
    actions: {
        justifyContent: 'flex-start'
    }
}));

const Index = props => {
    const { className, disabled, view, entities, columns, ...rest } = props;
    const classes = useStyles();

    return (
        <Card className={clsx(classes.root, className)} raised={true}>
            <CardContent className={classes.content}>
                {/* <PerfectScrollbar> */}
                <TableContainer className={classes.container}>
                    <Table stickyHeader size="small">
                        <Head
                            disabled={disabled}
                            entities={entities}
                            columns={columns}
                        />
                        <TableBody>
                            {entities.listPaged.map((item, index) => (
                                <Row
                                    key={index}
                                    view={view}
                                    disabled={disabled}
                                    columns={columns}
                                    entities={entities}
                                    item={item}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* </PerfectScrollbar> */}
            </CardContent>
            <CardActions className={classes.actions}>
                <TablePagination
                    component="div"
                    count={entities.list.length}
                    onChangePage={(event, page) => entities.setPage(page)}
                    onChangeRowsPerPage={event =>
                        entities.setVisibles(event.target.value)
                    }
                    page={entities.page}
                    rowsPerPage={entities.visibles}
                    rowsPerPageOptions={[2, 5, 10, 25]}
                    labelRowsPerPage={
                        <Typography variant="h6">Filas por Página</Typography>
                    }
                    labelDisplayedRows={({ from, to, count, page }) => (
                        <Typography variant="h6">{`Elementos del ${from} al ${to} de un total de  ${count}`}</Typography>
                    )}
                />
            </CardActions>
        </Card>
    );
};

export default Index;
