import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar as Avt, Tooltip } from '@material-ui/core';
import { getInitials } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: 30,
      height: 30,
    },
    large: {
      width: 60,
      height: 60,
    },
    extra: {        
        width: 90,
        height: 90,
      },
  }));

const Avatar = props => {  
    const classes = useStyles();  
    const {
        image,
        hidden,        
        value,
        size,
        ...rest
    } = props;    

    if (hidden) return null
    
    return (
        <Tooltip title={value}>
            <Avt 
                {...rest} 
                src={image}
                className={classes[size]}
            >
                {getInitials(value)}
            </Avt>
        </Tooltip>
    );
};

export default Avatar;
