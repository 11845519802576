import React,{useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Badge,
    CircularProgress,
    Fab,
    IconButton,
    Tooltip
} from '@material-ui/core';
import {SelectModal} from 'components'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    wrapper: {
        position: 'relative'
    },
    icProgreso: {
        position: 'absolute',
        top: 7,
        left: 7,
        zIndex: 1,
        size: 33
    },
    fbProgreso: {
        position: 'absolute',
        top: 7,
        left: 7,
        zIndex: 1
    },
    acccion: {
        margin: theme.spacing(1)
    }
}));

const ButtonBase = props => {
    const { toolTipText, float, ...rest } = props;

    if (float)
        return (
            <Tooltip title={toolTipText}>
                <Fab {...rest} />
            </Tooltip>
        );

    return (
        <Tooltip title={toolTipText}>
            <IconButton {...rest} />
        </Tooltip>
    );
};

const Document = props => {
    const {
        className,
        hidden,
        toolTipText,
        disabled,
        counter,
        onClick,
        icon,
        busy,
        float,
        selector,        
        ...rest
    } = props;

    const classes = useStyles();

    const [openSelect, setOpenSelect] = useState(false);

    const useSelector = () =>{
        if (selector.list.length===0)
            selector.action(selector.list[0].key)            
        else
            setOpenSelect(true)
    }

    if (hidden) return null;

    return (
        <div className={classes.wrapper}>
            <ButtonBase
                {...rest}
                className={classes.acccion}
                float={float}
                toolTipText={toolTipText}
                size="small"
                onClick={!selector ? onClick : useSelector }
                disabled={disabled}>
                <Badge 
                    color={Number.isInteger(counter) ? "error" : "default"}                    
                    badgeContent={counter}
                >
                    {icon}
                </Badge>
            </ButtonBase>            

            {busy && (
                <CircularProgress
                    disableShrink
                    className={float ? classes.fbProgreso : classes.icProgreso}
                    size={float ? 43 : 33}
                />
            )}
            <SelectModal
                selector={selector}
                open={openSelect}
                close={() => setOpenSelect(false)}                
            />
        </div>        
    );
};

export default Document;
