import React from 'react';

import { Crud } from 'components/Crud';
import Detalle from './Detalle';
import List from 'features/List';
import Auth from 'features/Auth';
import * as icon from 'icons';

const columns = usuarios => [    
    {
        column: '',
        title: '',
        type: 'text',
        titleFilter: 'Buscar',
        filter: (valor) =>
            usuarios.filter(
                [
                    'id',
                    'nombre',
                    'login',
                    'email',
                    ['empleado','puesto']
                ],
                valor,
                '',
                'text'
            )
    },
    {
        column: 'nombre',
        title: 'Nombre',
        type: 'text'
    },    
    {
        column: ['roles','id'],
        title: 'Roles',
        type: 'text',
        titleFilter: 'Roles',
        icon:icon.RolIcon,
        filter: (valor) =>{
            usuarios.filter(
                [   
                    ['roles','id']
                ],valor,'','text'
            )
        }    
    },
    {
        column: ['roles',['equipo','nombre']],
        title: 'Equipos',
        type: 'text',
        titleFilter: 'Equipos',
        icon:icon.TeamIcon,
        filter: (valor) =>{
            usuarios.filter(
                [   
                    ['roles','idEquipo'],
                    ['roles',['equipo','nombre']]
                ],valor,'','text'
            )
        }    
    },
    {
        column: 'email',
        title: 'eMail',
        type: 'text'
    },
];

const CrudContainer = List(Auth(Crud,'auth'), 'entities', 'usuarios');

const Usuarios = props => {
    const { ...rest } = props;

    return (
        <CrudContainer
            title="Usuarios"
            columns={columns}
            id="id"
            resume={['nombre']}
            api="usuarios/"
            Detalle={Detalle}
            {...rest}
        />
    );
};

export default Usuarios;
